.glenbard_page{
    background-color: white;
}
.book-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .book-table th, .book-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  .book-table th:nth-child(1),
.book-table td:nth-child(1) {
  width: 400px; /* Adjust width as needed */
}
  
  .book-table th {
    text-align: left;
    background-color: white;
  }
  .notebook-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .notebook-table th, .notebook-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .notebook-table th {
    text-align: left;
    background-color: white;
  }
  .notebook-table th:nth-child(1),
  .notebook-table td:nth-child(1) {
    width: 400px; /* Adjust width as needed */
  }
  
  .stationary-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .stationary-table th, .stationary-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .stationary-table th {
    text-align: left;
    background-color: white;
  }
  .stationary-table th:nth-child(1),
  .stationary-table td:nth-child(1) {
    width: 50px; /* Adjust width as needed */
  }
  .stationary-table th:nth-child(2),
  .stationary-table td:nth-child(2) {
    width: 340px; /* Adjust width as needed */
  }
  .stationary-table th:nth-child(3),
  .stationary-table td:nth-child(3) {
    width: 170px; /* Adjust width as needed */
  }
  .stationary-table th:nth-child(4),
  .stationary-table td:nth-child(4) {
    width: 200px; /* Adjust width as needed */
  }
  .stationary-table th:nth-child(5),
  .stationary-table td:nth-child(5) {
    width: 200px; /* Adjust width as needed */
  }
  .glenbard_heading_section
  {
    display: flex;
    flex-direction: row;
    padding: 10px;
    height: 200px;
  }
  .glenbard_logo_section
  {
    padding: 10px;
    height: 100%;
    object-fit: contain;
  }
  .glenbard_logo_section img
  {
    padding: 10px;
    height: 100%;
    object-fit: contain;
  }
  .glenbard_school_details
  {
    padding: 10px;
  }
  .glenbard_school_name
  {
    padding-top: 10px;
    font-size: 32px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 600;
  }
  @media (max-width: 991px) {
    .glenbard_school_name {
      font-size: 20px;
    }
  }
  .glenbard_school_name_subheading
  {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  .glenbard_details_section{
    display: flex;
    flex-direction: row;
    height: auto;



  }
  @media (max-width: 991px) {
    .glenbard_details_section {
      flex-direction: column;
    }
  }
  .glenbard_table_section
  {
    width: 70%;
    padding: 20px 20px 20px 30px;
  }
  @media (max-width: 991px) {
    .glenbard_table_section {
      width: 100%;
      padding: 10px;
    }
  }
  .table_heading{
    padding: 5px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
    font-weight: 500;

  }

  .school_second_page_div-48 {
    display: flex;
    flex-direction: column;
    fill: #fff;
    stroke-width: 1px;
    stroke: var(--gray-300, #dee2e7);
    filter: drop-shadow(0px 4px 10px rgba(56, 56, 56, 0.1));
    overflow: hidden;
    height: auto;
    position: relative;
    display: flex;
   
    margin-top:70px;
    flex-grow: 1;
    width: 100%;
    padding: 24px 16px;
    background:white;
  }
  @media (max-width: 991px) {
    .school_second_page_div-48 {
      margin-top:10px;
    }
  }
  .school_second_page_img-11 {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .school_second_page_div-49 {
    position: relative;
    align-self: stretch;
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    margin-top: 10px;
  }
  .school_second_page_div-50 {
    color: var(--gray-600, #505050);
    font-feature-settings: "clig" off, "liga" off;
    letter-spacing: -0.2px;
    font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .school_second_page_div-51 {
    color: var(--gray-600, #505050);
    text-align: right;
    font-feature-settings: "clig" off, "liga" off;
    letter-spacing: -0.2px;
    align-self: start;
    white-space: nowrap;
    font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .school_second_page_div-51 {
      white-space: initial;
    }
  }
  .school_second_page_div-52 {
    position: relative;
    align-self: stretch;
    display: flex;
    margin-top: 13px;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
  }
  .school_second_page_div-53 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
  }
  .school_second_page_div-54 {
    color: var(--gray-600, #505050);
    font-feature-settings: "clig" off, "liga" off;
    letter-spacing: -0.2px;
    align-self: stretch;
    white-space: nowrap;
    font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .school_second_page_div-54 {
      white-space: initial;
    }
  }
  .school_second_page_div-55 {
    color: var(--gray-600, #505050);
    font-feature-settings: "clig" off, "liga" off;
    letter-spacing: -0.2px;
    align-self: stretch;
    margin-top: 6px;
    white-space: nowrap;
    font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .school_second_page_div-55 {
      white-space: initial;
    }
  }
  .school_second_page_div-56 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
  }
  .school_second_page_div-57 {
    color: #fa3434;
    text-align: right;
    font-feature-settings: "clig" off, "liga" off;
    letter-spacing: -0.2px;
    align-self: stretch;
    white-space: nowrap;
    font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .school_second_page_div-57 {
      white-space: initial;
    }
  }
  .school_second_page_div-58 {
    color: #00b517;
    text-align: right;
    font-feature-settings: "clig" off, "liga" off;
    letter-spacing: -0.2px;
    align-self: stretch;
    margin-top: 7px;
    white-space: nowrap;
    font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .school_second_page_div-58 {
      white-space: initial;
    }
  }
  .school_second_page_div-59 {
    position: relative;
    background-color: #e4e4e4;
    align-self: stretch;
    display: flex;
    margin-top: 22px;
    width: 100%;
    height: 1px;
    flex-direction: column;
  }
  .school_second_page_div-60 {
    position: relative;
    align-self: stretch;
    display: flex;
    margin-top: 22px;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
  }
  .school_second_page_div-61 {
    color: var(--dark, #1c1c1c);
    font-feature-settings: "clig" off, "liga" off;
    align-self: start;
    margin-top: 4px;
    font: 600 16px Inter, sans-serif;
  }
  .school_second_page_div-62 {
    color: var(--dark, #1c1c1c);
    text-align: right;
    font-feature-settings: "clig" off, "liga" off;
    letter-spacing: -0.2px;
    align-self: start;
    white-space: nowrap;
    font: 600 20px/140% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .school_second_page_div-62 {
      white-space: initial;
    }
  }
  .school_second_page_div-63 {
    position: relative;
    color: var(--white, #fff);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    align-self: stretch;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: var(--green, #00b517);
    margin-top: 27px;
    width: 100%;
    padding: 16px 35px 16px 36px;
    font: 500 18px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .school_second_page_div-63 {
      white-space: initial;
      padding: 20px 20px;
    }
  }
  .glenbard_final_details
  {
    width: 30%;
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .glenbard_final_details{
      width: 100%;
      margin-top: 10px;
    }
  }

  .glenbard_student_details
  {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 25px;
    font-weight: 700;

  }
  .glenbard_input_section
  {
    display: flex;
    margin-top: 5px;
    padding: 4px;
    flex-direction: column;
    
  }
  .glenbard_input {
    width: 100%;
    height: 30px;
    border-radius: 0.5rem;
  }