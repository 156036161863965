.div {
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
}
.div-2 {
  align-self: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-2 {
    max-width: 100%;
  }
}
.img {
  aspect-ratio: 16.74;
  object-fit: cover;
  object-position: center;
  width: 100%;
  fill: #fff;
  overflow: hidden;
  align-self: stretch;
}
@media (max-width: 991px) {
  .img {
    max-width: 100%;
  }
}
.div-3 {
  align-self: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-3 {
    max-width: 100%;
  }
}
.div-4 {
  display: flex;
  flex-direction: column;
  fill: #fff;
  overflow: hidden;
  align-self: stretch;
  position: relative;
  display: flex;
  min-height: 56px;
  width: 100%;
  padding: 0 20px;
}
@media (max-width: 991px) {
  .div-4 {
    max-width: 100%;
  }
}
.img-2 {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.div-5 {
  position: relative;
  background-color: #e0e0e0;
  align-self: stretch;
  min-height: 1px;
  margin-right: -20px;
  width: 100%;
}
@media (max-width: 991px) {
  .div-5 {
    max-width: 100%;
  }
}
.div-6 {
  position: relative;
  background-color: #e0e0e0;
  align-self: stretch;
  min-height: 1px;
  width: 100%;
  margin: 54px -20px 0 0;
}
@media (max-width: 991px) {
  .div-6 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.div-7 {
  align-self: start;
  display: flex;
  width: 409px;
  max-width: 100%;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
  margin: 22px 0 0 133px;
  padding: 0 20px;
}
@media (max-width: 991px) {
  .div-7 {
    margin-left: 10px;
    justify-content: center;
  }
}
.div-8 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: center;
  margin: auto 0;
}
.img-3 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 20px;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
  align-self: center;
}
.div-9 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 150%;
  letter-spacing: -0.2px;

  align-self: center;
}
@media (max-width: 640px) {
  .div-9 {
    margin-top: 11px;
  }
}
.img-4 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 20px;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
  align-self: center;
}
.div-10 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: center;
  margin: auto 0;
  text-align: center;

}
.img-5 {

  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 20px;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
  align-self: center;
}
.div-11 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  text-align: center;
  align-self: center;
  margin: auto 0;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-11 {
    text-wrap: initial;
  }
}
.div-12 {
  border-radius: 6px;
  border: 1px solid var(--gray-300, #dee2e7);
  background-color: #fff;
  align-self: center;
  display: flex;
  margin-top: 22px;
  width: 100%;
  max-width: 1180px;
  flex-direction: column;
  padding: 20px 20px 44px 19px;
}
@media (max-width: 991px) {
  .div-12 {
    max-width: 100%;
  }
}
.div-13 {
  align-self: stretch;
}
@media (max-width: 991px) {
  .div-13 {
    max-width: 100%;
    margin-right: 2px;
  }
}
.div-14 {
  gap: 20px;
  display: flex;
}
@media (max-width: 991px) {
  .div-14 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}
.column {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 34%;
  margin-left: 0px;
}
@media (max-width: 991px) {
  .column {
    width: 100%;
  }
}
.div-15 {
  display: flex;
  flex-direction: column;
  width: 382px;
}
@media (max-width: 991px) {
  .div-15 {
    margin-top: 20px;
  }
}
.img-6 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 100%;
  overflow: hidden;
  align-self: stretch;
}
@media (max-width: 991px) {
  .img-6 {
    margin-right: 2px;
  }
}
@media (max-width: 640px) {
  .img-6 {
    max-width: 337px;
  }
}
.img-7 {
  aspect-ratio: 6.7;
  object-fit: cover;
  object-position: center;
  width: 100%;
  overflow: hidden;
  align-self: stretch;
  margin-top: 20px;
  flex-grow: 1;
}
@media (max-width: 640px) {
  .img-7 {
    max-width: 347px;
  }
}
.column-2 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 66%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-2 {
    width: 100%;
  }
}
.div-16 {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-16 {
    max-width: 100%;
    margin-top: 20px;
  }
}
.div-17 {
  align-self: start;
  width: 737px;
  max-width: 100%;
}
.div-18 {
  gap: 20px;
  display: flex;
}
@media (max-width: 991px) {
  .div-18 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}
.column-3 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 61%;
  margin-left: 0px;
}
@media (max-width: 991px) {
  .column-3 {
    width: 100%;
  }
}
.div-19 {
  display: flex;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-19 {
    max-width: 100%;
    margin-top: 29px;
  }
}
.div-20 {
  align-self: start;
  display: flex;
  width: 90px;
  max-width: 100%;
  align-items: flex-start;
  gap: 2px;
}
.img-8 {

  object-position: center;
  width: 30px;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
  color: #00b517;
}
.div-21 {
  color: var(--base-color-green, #00b517);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: center;
  text-wrap: nowrap;
  margin: auto 0;
}
@media (max-width: 991px) {
  .div-21 {
    text-wrap: initial;
  }
}
.div-22 {
  color: var(--dark, #1c1c1c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.2px;
  align-self: start;
  width: 384px;
  max-width: 100%;
}
.div-23 {
  align-self: start;
  display: flex;
  margin-top: 12px;
  width: 363px;
  max-width: 100%;
  align-items: flex-start;
  gap: 7px;
}
@media (max-width: 991px) {
  .div-23 {
    justify-content: center;
  }
}
.img-9 {
  aspect-ratio: 5.33;
  object-fit: cover;
  object-position: center;
  width: 80px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
  color: #ff9017;
}
.div-24 {
  color: var(--base-color-orange, #ff9017);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: center;
  margin: auto 0;
}
.img-10 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 6px;
  overflow: hidden;
  border-radius: 50%;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}
.img-11 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 20px;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
}
.div-25 {
  color: var(--base-color-gray-500, #787a80);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: center;
  margin: auto 0;
}
.img-12 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 6px;
  overflow: hidden;
  border-radius: 50%;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}
.img-13 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 20px;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
}
.div-26 {
  color: var(--base-color-gray-500, #787a80);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: center;
  text-wrap: nowrap;
  margin: auto 0;
}
@media (max-width: 991px) {
  .div-26 {
    text-wrap: initial;
  }
}
.img-14 {
  aspect-ratio: 5.97;
  object-fit: cover;
  object-position: center;
  width: 100%;
  fill: #fff0df;
  overflow: hidden;
  align-self: start;
  display: flex;
  flex-direction: row;
  background-color: #FFF0DF;
  justify-content: space-between;
  margin-top: 11px;
}
@media (max-width: 991px) {
  .img-14 {
    max-width: 100%;
  }
}
.max_amount_container{
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 33%;
  margin-top: 5px;
  margin-bottom: 5px;

  border-right: 1px solid #ccc; /* Vertical line */
  padding-right: 10px;

}
.max_price{
  align-items: center;
  text-align: left;
  font-style: arial;
  font-weight: 500;
  font-size: 18px;
  color: red;
}
.max_price_quantity
{
  align-items: center;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}
.avg_amount_container{
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 33%;
  margin-top: 5px;
  margin-bottom: 5px;
  border-right: 1px solid #ccc; /* Vertical line */
  padding-right: 10px;

}
.avg_price{
  align-items: center;
  text-align: left;
  font-style: arial;
  font-weight: 500;
  font-size: 18px;

  
}
.avg_price_quantity
{
  align-items: center;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}
.min_amount_container{
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 33%;
  margin-top: 5px;
  margin-bottom: 5px;

  padding-right: 10px;

}
.min_price{
  align-items: center;
  text-align: left;
  font-style: arial;
  font-weight: 500;
  font-size: 18px;
  
}
.min_price_quantity
{
  align-items: center;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}
.div-27 {
  align-self: start;
  display: flex;
  margin-top: 24px;
  width: 220px;
  max-width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}
.div-28 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: start;
}
.div-29 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: start;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-29 {
    text-wrap: initial;
  }
}
.div-30 {
  background-color: #e0e0e0;
  align-self: start;
  margin-top: 16px;
  width: 430px;
  height: 1px;
}
@media (max-width: 991px) {
  .div-30 {
    max-width: 100%;
  }
}
.div-31 {
  align-self: start;
  display: flex;
  margin-top: 19px;
  width: 252px;
  max-width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}
.div-32 {
  align-self: start;
  display: flex;
  flex-direction: column;
}
.div-33 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: start;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-33 {
    text-wrap: initial;
  }
}
.div-34 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: start;
  margin-top: 20px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-34 {
    text-wrap: initial;
  }
}
.div-35 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: start;
  margin-top: 23px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-35 {
    text-wrap: initial;
  }
}
.div-36 {
  align-self: start;
  display: flex;
  flex-direction: column;
}
.div-37 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: start;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-37 {
    text-wrap: initial;
  }
}
.div-38 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: start;
  margin-top: 23px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-38 {
    text-wrap: initial;
  }
}
.div-39 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: start;
  margin-top: 23px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-39 {
    text-wrap: initial;
  }
}
.column-4 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 60%;
  
}
@media (max-width: 991px) {
  .column-4 {
    width: 100%;
  }
}
.div-40 {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 280px;
}
@media (max-width: 991px) {
  .div-40 {
    margin-top: 27px;
  }
}
.div-41 {
  
  border-radius: 6px;
  border: 1px solid var(--gray-300, #dee2e7);
  box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
  background-color: var(--base-color-white, #fff);
  align-self: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px 16px;
}
.div-42 {

  align-self: start;
  display: flex;
  width: 206px;
  max-width: 100%;
  align-items: flex-start;
  gap: 11px;
}
.div-43 {
  color: rgba(76, 167, 167, 0.6);

  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 85.714%;
  letter-spacing: -0.2px;
  align-self: stretch;
  text-wrap: nowrap;
  border-radius: 4px;
  background-color: #c6f3f1;
  width: 48px;
  max-width: 100%;
  padding: 18px 18px 14px 14px;
}
@media (max-width: 991px) {
  .div-43 {
    text-wrap: initial;
  }
}
.div-44 {
  align-self: center;
  display: flex;
  flex-direction: column;

  margin: auto 0;
}
.div-45 {
  color: var(--dark, #1c1c1c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;

  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-45 {
    text-wrap: initial;
  }
}
.div-46 {
  color: var(--dark, #1c1c1c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-46 {
    text-wrap: initial;
  }
}
.div-47 {
  background-color: #e0e0e0;
  align-self: start;
  margin-top: 19px;
  width: 100%;
  
  height: 1px;
}
.div-48 {
  align-self: start;
  display: flex;
  margin-top: 17px;
  width: 200px;
  max-width: 100%;
  align-items: flex-start;
  gap: 17px;
}
.img-15 {

  object-fit: cover;
  object-position: center;
  width: 21px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
}
.div-49 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  text-wrap: nowrap;
  width: 100%;

}
@media (max-width: 991px) {
  .div-49 {
    text-wrap: initial;
  }
}
.div-50 {
  align-self: start;
  display: flex;
  margin-top: 13px;
  width: 200px;
  max-width: 100%;
  align-items: flex-start;
  gap: 18px;
}
.img-16 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 20px;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
}
.div-51 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: center;
  text-wrap: nowrap;
  margin: auto 0;
}
@media (max-width: 991px) {
  .div-51 {
    text-wrap: initial;
  }
}
.div-52 {
  align-self: start;
  display: flex;
  margin-top: 12px;
  width: 200px;
  max-width: 100%;
  align-items: flex-start;
  gap: 18px;
}
.img-17 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 20px;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
}
.div-53 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-53 {
    text-wrap: initial;
  }
}
.div-54 {
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #127fff 0%, #0067ff 100%);

  border-radius: 6px;

  align-self: start;
  display: flex;
  margin-top: 30px;
  width: 100%;
  flex-direction: column;
  padding: 11px 20px;
}
.div-55 {
  color: var(--white, #fff);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  align-self: center;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-55 {
    text-wrap: initial;
  }
}
.div-56 {
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--gray-300, #dee2e7);
  background-color: var(--white, #fff);
  align-self: start;
  display: flex;
  margin-top: 8px;
  width: 100%;
  flex-direction: column;
  padding: 11px 20px;
}
.div-57 {
  color: var(--primary, #0d6efd);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  align-self: center;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-57 {
    text-wrap: initial;
  }
}
.div-58 {
  align-self: center;
  display: flex;
  margin-top: 23px;
  width: 200px;
  max-width: 100%;
  align-items: flex-start;
  gap: 8px;
}
.img-18 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 24px;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
}
.div-59 {
  color: var(--base-color-blue, #0d6efd);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.1px;
  align-self: center;
  text-wrap: nowrap;
  margin: auto 0;
}
@media (max-width: 991px) {
  .div-59 {
    text-wrap: initial;
  }
}
.div-60 {
  background-color: #e0e0e0;
  align-self: start;
  width: 430px;
  height: 1px;
}
@media (max-width: 991px) {
  .div-60 {
    max-width: 100%;
  }
}
.div-61 {
  align-self: start;
  display: flex;
  margin-top: 19px;
  width: 327px;
  max-width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}
.div-62 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.div-63 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: start;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-63 {
    text-wrap: initial;
  }
}
.div-64 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: start;
  margin-top: 23px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-64 {
    margin-top: 40px;
    text-wrap: initial;
  }
}
.div-65 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: start;
  margin-top: 23px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-65 {
    text-wrap: initial;
  }
}
.div-66 {
  align-self: stretch;
  margin-left:0;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}
.div-67 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.div-68 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: start;
  margin-top: 0;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-68 {
    text-wrap: initial;
  }
}
.detail_page_div{
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  text-align: center;
  align-items: center;
  height: 80px;
  margin: 0 auto;
  font-size: 25px;
  font-weight: 510;


}
.div-69 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: start;
  margin-top: 25px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-69 {
    text-wrap: initial;
  }
}
.div-70 {
  background-color: #e0e0e0;
  align-self: start;
  margin-top: 16px;
  width: 430px;
  height: 1px;
}
@media (max-width: 991px) {
  .div-70 {
    max-width: 100%;
  }
}
.div-71 {
  align-self: center;
  margin-top: 20px;
  width: 100%;
  max-width: 1180px;
  
}
@media (max-width: 991px) {
  .div-71 {
    max-width: 100%;
  }
}
.div-72 {
  gap:10px;
  display: flex;

  max-width: 1180px;
  width: 100%;
}
@media (max-width: 991px) {
  .div-72 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
   
  }
}
.column-5 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 76%;
  margin-left: 0px;
}
@media (max-width: 991px) {
  .column-5 {
    width: 100%;
  }
}
.div-73 {
  border-radius: 6px;
  border: 1px solid var(--gray-300, #dee2e7);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  fill: #fff;
  stroke-width: 1px;
  stroke: var(--gray-300, #dee2e7);
  filter: drop-shadow(0px 1px 3px rgba(56, 56, 56, 0.1));
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: 618px;
  max-width: 100%;
  flex-grow: 1;
  padding-bottom: 31px;
}
@media (max-width: 991px) {
  .div-73 {
    max-width: 100%;
    margin-top: 20px;
  }
}
.img-19 {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.div-74 {
  position: relative;
  align-items: flex-start;
  border-bottom: 1px solid var(--gray-300, #dee2e7);
  align-self: start;
  display: flex;
  width: 880px;
  max-width: 100%;
  flex-direction: column;
  padding: 0 20px 0 8px;
}
.div-75 {
  align-self: start;
  display: flex;
  width: 478px;
  max-width: 100%;
  align-items: flex-start;
  gap: 0px;
}
@media (max-width: 991px) {
  .div-75 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.div-76 {
  color: var(--primary, #0d6efd);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  align-self: stretch;
  text-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid var(--primary, #0d6efd);
  padding: 17px;
}
@media (max-width: 991px) {
  .div-76 {
    text-wrap: initial;
  }
}
.div-77 {
  color: var(--gray-500, #8b96a5);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  align-self: stretch;
  text-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--gray-300, #dee2e7);
  padding: 18px 20px;
}
@media (max-width: 991px) {
  .div-77 {
    text-wrap: initial;
    padding-left: 2px;
  }
}
.div-78 {
  color: var(--gray-500, #8b96a5);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  align-self: stretch;
  text-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--gray-300, #dee2e7);
  padding: 17px 19px;
}
@media (max-width: 991px) {
  .div-78 {
    text-wrap: initial;
  }
}
.div-79 {
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--gray-300, #dee2e7);
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 18px 20px;
}
.div-80 {
  color: var(--gray-500, #8b96a5);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  align-self: center;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-80 {
    text-wrap: initial;
  }
}
.div-81 {
  position: relative;
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  width: 798px;
  max-width: 100%;
  margin: 16px 0 0 20px;
}
.div-82 {
  position: relative;
  align-items: flex-start;
  border: 1px solid #e0e7e9;
  align-self: start;
  display: flex;
  width: 567px;
  max-width: 100%;
  flex-direction: column;
  margin: 26px 0 0 19px;
}
.div-83 {
  align-self: stretch;
  display: flex;
  
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-83 {
    max-width: 100%;
  }
}
.div-84 {
  align-self: start;
  display: flex;
  width: 100%;
  max-width: 100%;
  align-items: flex-start;
  gap: 0px;
}
@media (max-width: 991px) {
  .div-84 {
    flex-wrap: wrap;
  }
}
.div-85 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: stretch;
  text-wrap: nowrap;
  background-color: var(--gray-200, #eff2f4);
  width: 204px;
  max-width: 100%;
  padding: 12px 20px 12px 10px;
}
@media (max-width: 991px) {
  .div-85 {
    text-wrap: initial;
  }
}
.div-86 {
  background-color: #fff;
  align-self: stretch;
  display: flex;
  width: 322px;
  max-width: 100%;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: auto;
  padding: 0 -41px 0 -204px;
}
.div-87 {
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-87 {
    max-width: 100%;
  }
}
.div-88 {
  background-color: #e0e7e9;
  align-self: stretch;
  z-index: 1;
  width: 100%;
  height: 1px;
}
@media (max-width: 991px) {
  .div-88 {
    max-width: 100%;
  }
}
.div-89 {
  align-self: center;
  display: flex;
  margin-left: -37px;
  width: 85px;
  max-width: 100%;
  align-items: flex-start;
  gap: 9px;
}
.div-90 {
  background-color: #e0e7e9;
  align-self: stretch;
  width: 1px;
  height: 36px;
}
.div-91 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: center;
  text-wrap: nowrap;
  margin: auto 0;
}
@media (max-width: 991px) {
  .div-91 {
    text-wrap: initial;
  }
}
.div-92 {
  align-self: stretch;
  display: flex;

  flex-direction: column;
}
@media (max-width: 991px) {
  .div-92 {
    max-width: 100%;
  }
}
.div-93 {
  align-self: start;
  display: flex;

  width: 100%;
  align-items: flex-start;
  gap: 0px;
}
@media (max-width: 991px) {
  .div-93 {
    flex-wrap: wrap;
  }
}
.div-94 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: stretch;
  text-wrap: nowrap;
  background-color: var(--gray-200, #eff2f4);
  width: 204px;
  max-width: 100%;
  padding: 11px 20px 11px 10px;
}
@media (max-width: 991px) {
  .div-94 {
    text-wrap: initial;
  }
}
.div-95 {
  background-color: #fff;
  align-self: stretch;
  display: flex;
  width: 322px;
  max-width: 100%;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: auto;
  padding: 0 -41px 0 -204px;
}
.div-96 {
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-96 {
    max-width: 100%;
  }
}
.div-97 {
  background-color: #e0e7e9;
  align-self: stretch;
  z-index: 1;
  width: 100%;
  height: 1px;
}
@media (max-width: 991px) {
  .div-97 {
    max-width: 100%;
  }
}
.div-98 {
  align-self: center;
  text-align: center;
  display: flex;
  margin-left: -29px;
  width: 200px;
  max-width: 100%;
  align-items: flex-start;
  gap: 9px;
}
.div-99 {
  background-color: #e0e7e9;
  align-self: stretch;
  width: 1px;
  height: 36px;
}
.div-100 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: center;
  text-wrap: nowrap;
  margin: auto 0;
}
@media (max-width: 991px) {
  .div-100 {
    text-wrap: initial;
  }
}
.div-101 {
  align-self: stretch;
  display: flex;

  flex-direction: column;
}
@media (max-width: 991px) {
  .div-101 {
    max-width: 100%;
  }
}
.div-102 {
  align-self: start;
  display: flex;

  max-width: 100%;
  align-items: flex-start;
  gap: 0px;
}
@media (max-width: 991px) {
  .div-102 {
    flex-wrap: wrap;
  }
}
.div-103 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: stretch;
  text-wrap: nowrap;
  background-color: var(--gray-200, #eff2f4);
  width: 204px;
  max-width: 100%;
  padding: 12px 20px 12px 10px;
}
@media (max-width: 991px) {
  .div-103 {
    text-wrap: initial;
  }
}
.div-104 {
  background-color: #fff;
  align-self: stretch;
  display: flex;
  width: 322px;
  max-width: 100%;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: auto;
  padding: 0 -41px 0 -204px;
}
.div-105 {
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-105 {
    max-width: 100%;
  }
}
.div-106 {
  background-color: #e0e7e9;
  align-self: stretch;
  z-index: 1;
  width: 100%;
  height: 1px;
}
@media (max-width: 991px) {
  .div-106 {
    max-width: 100%;
  }
}
.div-107 {
  align-self: center;
  display: flex;
  margin-left: -18px;
  width: 123px;
  max-width: 100%;
  align-items: flex-start;
  gap: 9px;
}
.div-108 {
  background-color: #e0e7e9;
  align-self: stretch;
  width: 1px;
  height: 36px;
}
.div-109 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: center;
  text-wrap: nowrap;
  margin: auto 0;
}
@media (max-width: 991px) {
  .div-109 {
    text-wrap: initial;
  }
}
.div-110 {
  align-self: stretch;
  display: flex;
  padding-right: 20px;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-110 {
    max-width: 100%;
  }
}
.div-111 {
  align-self: start;
  display: flex;
  width: 526px;
  max-width: 100%;
  align-items: flex-start;
  gap: 0px;
}
@media (max-width: 991px) {
  .div-111 {
    flex-wrap: wrap;
  }
}
.div-112 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: stretch;
  text-wrap: nowrap;
  background-color: var(--gray-200, #eff2f4);
  width: 204px;
  max-width: 100%;
  padding: 12px 20px 12px 10px;
}
@media (max-width: 991px) {
  .div-112 {
    text-wrap: initial;
  }
}
.div-113 {
  background-color: #fff;
  align-self: stretch;
  display: flex;
  width: 322px;
  max-width: 100%;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: auto;
  padding: 0 -41px 0 -204px;
}
.div-114 {
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-114 {
    max-width: 100%;
  }
}
.div-115 {
  background-color: #e0e7e9;
  align-self: stretch;
  z-index: 1;
  width: 100%;
  height: 1px;
}
@media (max-width: 991px) {
  .div-115 {
    max-width: 100%;
  }
}
.div-116 {
  align-self: center;
  display: flex;
  margin-left: 16px;
  width: 191px;
  max-width: 100%;
  align-items: flex-start;
  gap: 9px;
}
.div-117 {
  background-color: #e0e7e9;
  align-self: stretch;
  width: 1px;
  height: 36px;
}
.div-118 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: center;
  text-wrap: nowrap;
  margin: auto 0;
}
@media (max-width: 991px) {
  .div-118 {
    text-wrap: initial;
  }
}
.div-119 {
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  padding-right: 20px;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-119 {
    max-width: 100%;
  }
}
.div-120 {
  align-self: start;
  display: flex;
  width: 526px;
  max-width: 100%;
  align-items: flex-start;
  gap: 0px;
}
@media (max-width: 991px) {
  .div-120 {
    flex-wrap: wrap;
  }
}
.div-121 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: stretch;
  text-wrap: nowrap;
  background-color: var(--gray-200, #eff2f4);
  width: 204px;
  max-width: 100%;
  padding: 11px 20px 11px 10px;
}
@media (max-width: 991px) {
  .div-121 {
    text-wrap: initial;
  }
}
.div-122 {
  background-color: #fff;
  align-self: stretch;
  display: flex;
  width: 322px;
  max-width: 100%;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: auto;
  padding: 0 -41px 0 -204px;
}
.div-123 {
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-123 {
    max-width: 100%;
  }
}
.div-124 {
  background-color: #e0e7e9;
  align-self: stretch;
  z-index: 1;
  width: 100%;
  height: 1px;
}
@media (max-width: 991px) {
  .div-124 {
    max-width: 100%;
  }
}
.div-125 {
  align-self: center;
  display: flex;
  margin-left: -35px;
  width: 88px;
  max-width: 100%;
  align-items: flex-start;
  gap: 9px;
}
.div-126 {
  background-color: #e0e7e9;
  align-self: stretch;
  width: 1px;
  height: 36px;
}
.div-127 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: center;
  text-wrap: nowrap;
  margin: auto 0;
}
@media (max-width: 991px) {
  .div-127 {
    text-wrap: initial;
  }
}
.div-128 {
  position: relative;
  align-self: start;
  display: flex;
  width: 250px;
  max-width: 100%;
  align-items: flex-start;
  gap: 7px;
  margin: 28px 0 0 19px;
}
@media (max-width: 991px) {
  .div-128 {
    margin-left: 10px;
  }
}
.img-20 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 20px;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
}
.div-129 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-129 {
    text-wrap: initial;
  }
}
.div-130 {
  position: relative;
  align-self: start;
  display: flex;
  width: 323px;
  max-width: 100%;
  align-items: flex-start;
  gap: 7px;
  margin: 14px 0 0 19px;
}
@media (max-width: 991px) {
  .div-130 {
    margin-left: 10px;
  }
}
.img-21 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 20px;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
}
.div-131 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  text-wrap: nowrap;
  flex-grow: 1;
  flex-basis: auto;
}
@media (max-width: 991px) {
  .div-131 {
    text-wrap: initial;
  }
}
.div-132 {
  position: relative;
  align-self: start;
  display: flex;
  width: 295px;
  max-width: 100%;
  align-items: flex-start;
  gap: 7px;
  margin: 14px 0 0 19px;
}
@media (max-width: 991px) {
  .div-132 {
    margin-left: 10px;
  }
}
.img-22 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 20px;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
}
.div-133 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  text-wrap: nowrap;
  flex-grow: 1;
  flex-basis: auto;
}
@media (max-width: 991px) {
  .div-133 {
    text-wrap: initial;
  }
}
.div-134 {
  position: relative;
  align-self: start;
  display: flex;
  width: 250px;
  max-width: 100%;
  align-items: flex-start;
  gap: 7px;
  margin: 14px 0 0 19px;
}
@media (max-width: 991px) {
  .div-134 {
    margin-left: 10px;
  }
}
.img-23 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 20px;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
}
.div-135 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-135 {
    text-wrap: initial;
  }
}
.column-6 {
  display: flex;
  flex-basis: 24%;
  flex-direction: column;
  line-height: normal;
  width: 24%;
}
@media (max-width: 991px) {
  .column-6 {
    width: 100%;
  }
}
.div-136 {
  border-radius: 6px;
  border: 1px solid var(--gray-300, #dee2e7);
  background-color: #fff;
  display: flex;
  width: 280px;
  max-width: 100%;
  flex-direction: column;
  margin: 0 auto;
  padding: 23px 20px 36px 16px;
}
@media (max-width: 991px) {
  .div-136 {
    margin-top: 20px;
  }
}
.div-137 {
  color: var(--base-color-dark, #1c1c1c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  align-self: start;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-137 {
    text-wrap: initial;
  }
}
.div-138 {
  align-self: start;
  display: flex;
  margin-top: 15px;
  width: 100%;
  align-items: flex-start;
  gap: 11px;
}
.div-139 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.img-24 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 100%;
  overflow: hidden;
  align-self: stretch;
  width: 80px;
  height: 80px;
}
.img-25 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 100%;
  overflow: hidden;
  align-self: stretch;
  margin-top: 16px;
}
.img-26 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 100%;
  overflow: hidden;
  align-self: stretch;
  margin-top: 16px;
}
.img-27 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 100%;
  overflow: hidden;
  align-self: stretch;
  margin-top: 16px;
}
.img-28 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 100%;
  overflow: hidden;
  align-self: stretch;
  margin-top: 16px;
  flex-grow: 1;
}
.div-140 {
  align-self: center;
  display: flex;
  flex-direction: column;
  margin: auto 0;
}
.div-141 {
  color: var(--dark, #1c1c1c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
}
.div-142 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: center;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-142 {
    text-wrap: initial;
  }
}
.div-143 {
  color: var(--dark, #1c1c1c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  margin-top: 24px;
}
.div-144 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  margin-top: 6px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-144 {
    text-wrap: initial;
  }
}
.div-145 {
  color: var(--dark, #1c1c1c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  margin-top: 24px;
}
.div-146 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-146 {
    text-wrap: initial;
  }
}
.div-147 {
  color: var(--dark, #1c1c1c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  margin-top: 24px;
}
.div-148 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-148 {
    text-wrap: initial;
  }
}
.div-149 {
  color: var(--dark, #1c1c1c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  margin-top: 24px;
}
.div-150 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  margin-top: 6px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-150 {
    text-wrap: initial;
  }
}
.div-151 {
  border-radius: 6px;
  border: 1px solid var(--gray-300, #dee2e7);
  background-color: #fff;
  align-self: center;
  display: flex;
  margin-top: 20px;
  width: 100%;
  max-width: 1180px;
  flex-direction: column;
  padding: 26px 20px 38px;
}
@media (max-width: 991px) {
  .div-151 {
    max-width: 100%;
  }
}
.div-152 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 991px) {
  .div-152 {
    max-width: 100%;
    margin-right: 6px;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.div-153 {
  align-self: end;
  display: flex;
  flex-direction: column;
}
.div-154 {
  color: var(--dark, #1c1c1c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.2px;
  align-self: start;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-154 {
    text-wrap: initial;
  }
}
.div-155 {
  justify-content: center;
  align-items: flex-start;
  align-self: start;
  display: flex;
  margin-top: 18px;
  width: 172px;
  max-width: 100%;
  flex-grow: 1;
  flex-direction: column;
}
.div-156 {
  border-radius: 6px;
  background-color: #eee;
  align-self: start;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 24px;
  padding: 9px 10px 9px 7px;
}
.div-157 {
  justify-content: center;
  align-items: center;
  mix-blend-mode: multiply;
  background-color: #fff;
  align-self: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 12px;
}
.img-29 {
  aspect-ratio: 0.99;
  object-fit: cover;
  object-position: center;
  width: 100%;
  overflow: hidden;
  align-self: stretch;
}
.div-158 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-top: 17px;
}
.div-159 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: start;
  margin-top: 12px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-159 {
    text-wrap: initial;
  }
}
.div-160 {
  justify-content: center;
  align-items: flex-start;
  align-self: end;
  display: flex;
  margin-top: 37px;
  flex-direction: column;
}
.div-161 {
  border-radius: 6px;
  background-color: #eee;
  align-self: start;
  display: flex;
  width: 172px;
  max-width: 100%;
  flex-direction: column;
  padding: 9px 10px 9px 7px;
}
.div-162 {
  justify-content: center;
  align-items: center;
  mix-blend-mode: multiply;
  background-color: #fff;
  align-self: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px 20px;
}
.img-30 {
  aspect-ratio: 0.84;
  object-fit: cover;
  object-position: center;
  width: 100%;
  overflow: hidden;
  align-self: stretch;
}
@media (max-width: 991px) {
  .img-30 {
    margin: 0 1px;
  }
}
.div-163 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-top: 17px;
}
.div-164 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: start;
  margin-top: 12px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-164 {
    text-wrap: initial;
  }
}
.div-165 {
  justify-content: center;
  align-items: flex-start;
  align-self: end;
  display: flex;
  margin-top: 37px;
  flex-direction: column;
}
.div-166 {
  border-radius: 6px;
  background-color: #eee;
  align-self: start;
  display: flex;
  width: 172px;
  max-width: 100%;
  flex-direction: column;
  padding: 9px 10px 9px 7px;
}
.div-167 {
  justify-content: center;
  align-items: center;
  mix-blend-mode: multiply;
  background-color: #fff;
  align-self: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 3px 2px 3px 3px;
}
.img-31 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 100%;
  overflow: hidden;
  align-self: stretch;
  flex-grow: 1;
}
.div-168 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-top: 17px;
}
.div-169 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: start;
  margin-top: 12px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-169 {
    text-wrap: initial;
  }
}
.div-170 {
  justify-content: center;
  align-items: flex-start;
  align-self: end;
  display: flex;
  margin-top: 37px;
  flex-direction: column;
}
.div-171 {
  border-radius: 6px;
  background-color: #eee;
  align-self: start;
  display: flex;
  width: 172px;
  max-width: 100%;
  flex-direction: column;
  padding: 9px 10px 9px 7px;
}
.div-172 {
  justify-content: center;
  align-items: center;
  mix-blend-mode: multiply;
  background-color: #fff;
  align-self: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 19px 20px;
}
.img-32 {
  aspect-ratio: 0.97;
  object-fit: cover;
  object-position: center;
  width: 100%;
  overflow: hidden;
  align-self: stretch;
}
@media (max-width: 991px) {
  .img-32 {
    margin-left: 1px;
  }
}
.div-173 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-top: 17px;
}
.div-174 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: start;
  margin-top: 12px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-174 {
    text-wrap: initial;
  }
}
.div-175 {
  justify-content: center;
  align-items: flex-start;
  align-self: end;
  display: flex;
  margin-top: 37px;
  flex-direction: column;
}
.div-176 {
  border-radius: 6px;
  background-color: #eee;
  align-self: start;
  display: flex;
  width: 172px;
  max-width: 100%;
  flex-direction: column;
  padding: 14px 16px 14px 13px;
}
.img-33 {
  aspect-ratio: 0.99;
  object-fit: cover;
  object-position: center;
  width: 100%;
  overflow: hidden;
  align-self: stretch;
}
.div-177 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-top: 17px;
}
.div-178 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: start;
  margin-top: 12px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-178 {
    text-wrap: initial;
  }
}
.div-179 {
  justify-content: center;
  align-items: flex-start;
  align-self: end;
  display: flex;
  margin-top: 37px;
  flex-direction: column;
}
.img-34 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 100%;
  overflow: hidden;
  align-self: start;
}
.div-180 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-top: 17px;
}
.div-181 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  align-self: start;
  margin-top: 12px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-181 {
    text-wrap: initial;
  }
}
.div-182 {
  border-radius: 8px;
  background-color: #005ade;
  align-self: center;
  display: flex;
  margin-top: 20px;
  width: 100%;
  max-width: 1180px;
  padding-right: 20px;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-182 {
    max-width: 100%;
  }
}
.div-183 {
  align-self: center;
  display: flex;
  width: 100%;
  max-width: 1135px;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 991px) {
  .div-183 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.img-35 {
  aspect-ratio: 6.21;
  object-fit: cover;
  object-position: center;
  width: 745px;
  fill: #237cff;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
}
.div-184 {
  color: var(--white, #fff);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  align-self: center;
  text-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: var(--orange, #ff9017);
  width: 150px;
  max-width: 100%;
  margin: auto 0;
  padding: 11px 16px;
}
@media (max-width: 991px) {
  .div-184 {
    text-wrap: initial;
  }
}
.div-185 {
  background-color: #fff;
  align-self: stretch;
  display: flex;
  margin-top: 88px;
  width: 100%;
  padding-top: 40px;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-185 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.div-186 {
  align-self: center;
  width: 100%;
  max-width: 1125px;
  padding: 0 20px;
}
@media (max-width: 991px) {
  .div-186 {
    max-width: 100%;
  }
}
.div-187 {
  gap: 20px;
  display: flex;
}
@media (max-width: 991px) {
  .div-187 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}
.column-7 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 72%;
  margin-left: 0px;
}
@media (max-width: 991px) {
  .column-7 {
    width: 100%;
  }
}
.div-188 {
  flex-grow: 1;
}
@media (max-width: 991px) {
  .div-188 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.div-189 {
  gap: 20px;
  display: flex;
}
@media (max-width: 991px) {
  .div-189 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}
.column-8 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0px;
}
@media (max-width: 991px) {
  .column-8 {
    width: 100%;
  }
}
.div-190 {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-190 {
    margin-top: 40px;
  }
}
.img-36 {
  aspect-ratio: 3.28;
  object-fit: cover;
  object-position: center;
  width: 151px;
  align-items: center;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
}
.div-191 {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  margin-top: 13px;
}
.img-37 {
  aspect-ratio: 6.25;
  object-fit: cover;
  object-position: center;
  width: 200px;
  overflow: hidden;
  align-self: start;
  margin-top: 19px;
  max-width: 100%;
  flex-grow: 1;
}
.column-9 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 15%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-9 {
    width: 100%;
  }
}
.div-192 {
  display: flex;
  margin-top: 5px;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-192 {
    margin-top: 40px;
  }
}
.div-193 {
  color: var(--dark, #1c1c1c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 137.5%;
  align-self: start;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-193 {
    text-wrap: initial;
  }
}
.div-194 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  margin-top: 13px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-194 {
    text-wrap: initial;
  }
}
.div-195 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  margin-top: 7px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-195 {
    text-wrap: initial;
  }
}
.div-196 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  margin-top: 7px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-196 {
    text-wrap: initial;
  }
}
.div-197 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  margin-top: 4px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-197 {
    text-wrap: initial;
  }
}
.column-10 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 17%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-10 {
    width: 100%;
  }
}
.div-198 {
  display: flex;
  margin-top: 5px;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-198 {
    margin-top: 40px;
  }
}
.div-199 {
  color: var(--dark, #1c1c1c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 137.5%;
  align-self: start;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-199 {
    text-wrap: initial;
  }
}
.div-200 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  margin-top: 10px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-200 {
    text-wrap: initial;
  }
}
.div-201 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  margin-top: 7px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-201 {
    text-wrap: initial;
  }
}
.div-202 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  margin-top: 7px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-202 {
    text-wrap: initial;
  }
}
.div-203 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  margin-top: 4px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-203 {
    text-wrap: initial;
  }
}
.column-11 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 19%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-11 {
    width: 100%;
  }
}
.div-204 {
  display: flex;
  margin-top: 5px;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-204 {
    margin-top: 40px;
  }
}
.div-205 {
  color: var(--dark, #1c1c1c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 137.5%;
  align-self: start;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-205 {
    text-wrap: initial;
  }
}
.div-206 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  margin-top: 13px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-206 {
    text-wrap: initial;
  }
}
.div-207 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  margin-top: 4px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-207 {
    text-wrap: initial;
  }
}
.div-208 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  margin-top: 4px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-208 {
    text-wrap: initial;
  }
}
.div-209 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  margin-top: 4px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-209 {
    text-wrap: initial;
  }
}
.column-12 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 28%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-12 {
    width: 100%;
  }
}
.div-210 {
  display: flex;
  margin-top: 5px;
  width: 289px;
  max-width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 991px) {
  .div-210 {
    margin-top: 40px;
  }
}
.div-211 {
  align-self: start;
  display: flex;
  flex-direction: column;
}
.div-212 {
  color: var(--dark, #1c1c1c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 137.5%;
  align-self: start;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-212 {
    text-wrap: initial;
  }
}
.div-213 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  margin-top: 13px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-213 {
    text-wrap: initial;
  }
}
.div-214 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  margin-top: 4px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-214 {
    text-wrap: initial;
  }
}
.div-215 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  margin-top: 4px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-215 {
    text-wrap: initial;
  }
}
.div-216 {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: start;
  margin-top: 4px;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-216 {
    text-wrap: initial;
  }
}
.div-217 {
  align-self: start;
  display: flex;
  flex-direction: column;
}
.div-218 {
  color: var(--dark, #1c1c1c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 137.5%;
  align-self: start;
  text-wrap: nowrap;
}
@media (max-width: 991px) {
  .div-218 {
    text-wrap: initial;
  }
}
.img-38 {
  aspect-ratio: 2.95;
  object-fit: cover;
  object-position: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  align-self: start;
  margin-top: 17px;
}
.img-39 {
  aspect-ratio: 2.95;
  object-fit: cover;
  object-position: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  align-self: start;
  margin-top: 8px;
  flex-grow: 1;
}
.div-219 {
  border-top: 1px solid var(--gray-300, #dee2e7);
  background-color: var(--gray-200, #eff2f4);
  align-self: stretch;
  display: flex;
  margin-top: 59px;
  width: 100%;
  flex-direction: column;
  padding: 22px 20px;
}
@media (max-width: 991px) {
  .div-219 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.div-220 {
  align-self: center;
  display: flex;
  width: 100%;
  max-width: 1176px;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 991px) {
  .div-220 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.div-221 {
  color: var(--base-color-gray-800, #606060);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: center;
  max-width: 344px;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
}
.div-222 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0px;
}
@media (max-width: 991px) {
  .div-222 {
    justify-content: center;
  }
}
.img-40 {
  aspect-ratio: 1.41;
  object-fit: cover;
  object-position: center;
  width: 24px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}
.div-223 {
  color: var(--base-color-gray-800, #606060);
  text-align: right;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
  align-self: center;
  margin: auto 0;
}
.img-41 {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 24px;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
}
.writing_description_divider-line {
  border-top: 1px solid #ccc; /* You can adjust the color and style of the line */
  margin-top: 0px; /* Adjust this value to set the distance between the heading and the line */
}
.writing_description_product-sliding-container {
  /* Adjust container styles as needed */
  /*
  color: #333;
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 350px;
  border-radius: 0.5rem;*/
  display: flex;
  border-radius: 6px;
  color: #333;
  padding: 1rem;
  /*box-shadow: 0 0 1rem #000 inset;*/
  justify-content: space-evenly;
  position: relative;
  


}
.writing_description_product-slides {
  /* Add styles for the product slides container */
  display: flex;
  transition: transform 0.3s ease; /* Smooth sliding transition */
  flex-direction: row;
}
.writing_description_product-sliding-card{
  /* Adjust styles for individual product cards */
 /*width: 340px; 
  padding: 10px;
  text-align: center;
  background-color: white;
  text-decoration: none;
  /* Add other styles */
  flex: 1 0 250px;
  box-shadow: 0 1px 1rem -4px #000;
  background: #fff;
  height: 320px;
  margin: 1rem;
  overflow: hidden;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  text-decoration: none;
  

  
  &:hover {
    box-shadow: 0 4px 1rem -4px #000;
    transform: translateY(-3px);
  }
}
.writing_description_product-sliding-img{
    
    height: 250;
    width: 250;
    object-fit: fill;
    border-radius: 0.5rem;
}
.writing_description_product-sliding-img img{
    height: 250px;
    width: 250px;
    padding: 0.5rem;
    border-radius: 0.5rem;
    object-fit: fill;
}

.link {
    text-decoration: none;
    /* Add other styles as needed */
  }

.writing_description_product-sliding-name{
    text-decoration: none;
    text-align: center;

    color: black;
    font-size: 17px;

}
.writing_description_ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
.writing_description_product-sliding-price{
    text-decoration: none;
    text-align: center;
    font-weight: 700;
    color: black;
    margin-top: auto;
    margin-bottom: 2px;

    align-items: end;
    position: absolute;
    bottom: 5px; /* Adjust this value as needed for the desired distance from the bottom */
    left: 0;
    right: 0;


}
.stationary_description_product-list {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.stationary_description_product-list-card {
  display: flex;
  margin-bottom: 10px; /* Adjust margin for spacing between cards */
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  background: #fff;
  height: 90px;
  transition: all 250ms ease-in-out;
}

.stationary_description_product-list-img {
  width: 150px; /* Adjust the width of the image container */
  height: 150px; /* Adjust the height of the image container */
  border-radius: 6px 0 0 6px;
  overflow: hidden;
}

.stationary_description_product-list-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.stationary_description_product-list-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.stationary_description_product-list-name {
  text-decoration: none;
  text-align: center;
  color: black;
  font-size: 17px;
}

.stationary_description_ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stationary_description_product-list-price {
  text-decoration: none;
  text-align: center;
  font-weight: 700;
  color: black;
  margin-top: auto;
  margin-bottom: 2px;
}
.writing_category-card {
  border-radius: 6px;
  background-color: white;
  align-self: start;
  display: flex;
  width: 172px;
  max-width: 100%;
  flex-direction: column;
  padding: 9px 10px 9px 7px;
  text-decoration: none;
}
.writing_category-img {
  justify-content: center;
  align-items: center;
  mix-blend-mode: multiply;
  background-color: #fff;
  align-self: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px 20px;
}
.writing_category-img_part {
  aspect-ratio: 0.84;
  object-fit: cover;
  object-position: center;
  width: 100%;
  overflow: hidden;
  align-self: stretch;
}
@media (max-width: 991px) {
  .writing_category-img_part {
    margin: 0 1px;
  }
}
.writing_category-name {
  color: var(--gray-600, #505050);
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 17px;
  font: 400 16px Inter, sans-serif;
}
.writing_category-price {
  color: var(--gray-500, #8b96a5);
  font-feature-settings: "clig" off, "liga" off;
  align-self: center;
  text-align: center;
  margin-top: 12px;
  text-wrap: nowrap;
  font: 400 16px Inter, sans-serif;
}
@media (max-width: 991px) {
  .writing_category-price {
    text-wrap: initial;
  }
}
.writing_category-card-container {
  justify-content: space-evenly;
  align-items: flex-start;
  align-self: end;
  text-decoration: none;
 background-color: white;
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  text-decoration: none;
}
.bottom_ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.right_ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}



.school_landing_div-402 {
  border: 1px solid #fff;
  background-color: #445268;
  align-self: start;
  display: flex;
  margin-top: 31px;
  width: 100%;
  flex-direction: column;
  padding: 50px 29px 34px 80px;
}
@media (max-width: 991px) {
  .school_landing_div-402 {
    max-width: 100%;
    padding: 0 20px;
  }
}
.school_landing_div-403 {
  color: #fff;
  letter-spacing: 0.8px;
  align-self: center;
  margin-top: 6px;
  white-space: nowrap;
  font: 400 16px/62.5% Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
@media (max-width: 991px) {
  .school_landing_div-403 {
    max-width: 100%;
    white-space: initial;
  }
}
.school_landing_div-404 {
  align-self: center;
  display: flex;
  margin-top: 16px;
  width: 436px;
  max-width: 100%;
  align-items: flex-start;
  gap: 20px;
}
@media (max-width: 991px) {
  .school_landing_div-404 {
    flex-wrap: wrap;
  }
}
.school_landing_div-405 {
  align-self: start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: auto;
  width: 300px;
}
.school_landing_div-406 {
  color: #adb1b9;
  letter-spacing: 0.9px;
  align-self: stretch;
  white-space: nowrap;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background-color: #313d51;
  width: 100%;
  padding: 14px 80px 14px 10px;
  font: 400 18px/56% Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
@media (max-width: 991px) {
  .school_landing_div-406 {
    white-space: initial;
    padding-right: 20px;
  }
}
.school_landing_div-407 {
  color: #adb1b9;
  letter-spacing: 0.575px;
  align-self: stretch;
  margin-top: 9px;
  white-space: nowrap;
  font: 400 11.5px/87% Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
@media (max-width: 991px) {
  .school_landing_div-407 {
    white-space: initial;
  }
}
.school_landing_div-408 {
  color: #fff;
  letter-spacing: 0.7px;
  align-self: start;
  white-space: nowrap;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background-color: #313d51;
  width: 116px;
  max-width: 100%;
  padding: 15px 26px 15px 24px;
  font: 400 14px/71% Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
@media (max-width: 991px) {
  .school_landing_div-408 {
    white-space: initial;
    padding: 0 20px;
  }
}
.school_landing_div-409 {
  align-self: center;
  display: flex;
  margin-top: 32px;
  width: 100%;
  max-width: 1351px;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 991px) {
  .school_landing_div-409 {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.school_landing_div-410 {
  color: #fff;
  letter-spacing: 0.9px;
  font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
@media (max-width: 991px) {
  .school_landing_div-410 {
    text-align:center;
    justify-content: center;
  }
}
.school_landing_div-411 {
  color: #fff;
  letter-spacing: 0.9px;
  font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
@media (max-width: 991px) {
  .school_landing_div-411 {
    text-align:center;
    justify-content: center;
  }
}
.school_landing_div-412 {
  color: #fff;
  letter-spacing: 0.9px;
  align-self: start;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
@media (max-width: 991px) {
  .school_landing_div-412 {
    text-align:center;
    justify-content: center;
  }
}
.school_landing_div-413 {
  color: #fff;
  letter-spacing: 0.9px;
  font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
@media (max-width: 991px) {
  .school_landing_div-413 {
    text-align:center;
    justify-content: center;
  }
}
.school_landing_div-414 {
  color: #fff;
  letter-spacing: 0.9px;
  align-self: start;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
@media (max-width: 991px) {
  .school_landing_div-414 {
    text-align:center;
    justify-content: center;
  }
}
.school_landing_div-415 {
  background-color: #364358;
  align-self: center;
  margin-top: 37px;
  width: 1220px;
  height: 1px;
}
@media (max-width: 991px) {
  .school_landing_div-415 {
    max-width: 100%;
  }
}
.school_landing_div-416 {
  align-self: center;
  display: flex;
  margin-top: 18px;
  width: 100%;
  max-width: 1166px;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 991px) {
  .school_landing_div-416 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.school_landing_div-417 {
  align-self: start;
  display: flex;
  margin-top: 4px;
  align-items: flex-start;
  gap: 15px;
}
@media (max-width: 991px) {
  .school_landing_div-417 {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.school_landing_div-418 {
  color: #fff;
  letter-spacing: 0.9px;
  align-self: center;
  margin: auto 0;
  font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.school_landing_div-419 {
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 5px;
  background-color: #999;
  align-self: stretch;
  display: flex;
  width: 190px;
  max-width: 100%;
  gap: 10px;
  padding: 5px 15px 5px 5px;
}
.school_landing_img-140 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 35px;
  fill: rgba(0, 0, 0, 0);
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
}
.school_landing_div-420 {
  color: #fff;
  letter-spacing: 0.8px;
  font: 700 16px/18px Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.school_landing_div-421 {
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 5px;
  background-color: #a4c639;
  align-self: stretch;
  display: flex;
  width: 185px;
  max-width: 100%;
  gap: 5px;
  padding: 5px 15px 5px 5px;
}
.school_landing_img-141 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 35px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
}
.school_landing_div-422 {
  color: #fff;
  letter-spacing: 0.8px;
  font: 700 16px/18px Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.school_landing_div-423 {
  align-self: start;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
}
@media (max-width: 991px) {
  .school_landing_div-423 {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.school_landing_div-424 {
  color: #fff;
  letter-spacing: 0.9px;
  margin: auto 0;
  font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.school_landing_img-142 {
  aspect-ratio: 0.92;
  object-fit: contain;
  object-position: center;
  width: 55px;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
}
.school_landing_div-425 {
  color: #fff;
  letter-spacing: 0.9px;
  align-self: center;
  margin: auto 0;
  font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.school_landing_img-143 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 25px;
  fill: rgba(0, 0, 0, 0);
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}
.school_landing_img-144 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 25px;
  fill: rgba(0, 0, 0, 0);
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}
.school_landing_img-145 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 25px;
  fill: rgba(0, 0, 0, 0);
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}
.school_landing_img-146 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 25px;
  fill: rgba(0, 0, 0, 0);
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}
.school_landing_img-147 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 25px;
  fill: rgba(0, 0, 0, 0);
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}
.school_landing_div-426 {
  align-self: center;
  display: flex;
  margin-top: 46px;
  width: 100%;
  max-width: 1057px;
  align-items: flex-start;
  gap: 18px;
}
@media (max-width: 991px) {
  .school_landing_div-426 {
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
  }
}
.school_landing_img-148 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 25px;
  fill: rgba(222, 196, 196, 0);
  overflow: hidden;
  align-self: start;
  max-width: 100%;
}
.school_landing_div-427 {
  color: #fff;
  letter-spacing: 0.9px;
  align-self: start;
  white-space: nowrap;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
@media (max-width: 991px) {
  .school_landing_div-427 {
    max-width: 100%;
    white-space: initial;
  }
}
.school_landing_div-428 {
  color: #fff;
  letter-spacing: 0.9px;
  align-self: end;
  white-space: nowrap;
  margin: 4px 30px 0 0;
  font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
@media (max-width: 991px) {
  .school_landing_div-428 {
    max-width: 100%;
    margin-right: 10px;
    white-space: initial;
  }
}
.school_landing_div-429 {
  color: #fff;
  letter-spacing: 0.9px;
  align-self: center;
  margin-left: 52px;
  white-space: nowrap;
  font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
@media (max-width: 991px) {
  .school_landing_div-429 {
    max-width: 100%;
    white-space: initial;
  }
}
.school_landing_div-430,
.school_landing_div-431 {
  color: #fff;
  letter-spacing: 0.9px;
  align-self: center;
  font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica, sans-serif;
  white-space: normal; /* Allow text to wrap */
}

@media (max-width: 991px) {
  .school_landing_div-430,
  .school_landing_div-431 {
    max-width: 100%;
    text-align: center;
    margin-top: 9px; /* Adjust margin-top for school_landing_div-430 */
  }

  .school_landing_div-431 {
    margin-top: 16px; /* Adjust margin-top for school_landing_div-431 */
  }
}

.school_landing_div-432 {
  align-self: center;
  display: flex;
  margin-top: 29px;
  width: 805px;
  max-width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 991px) {
  .school_landing_div-432 {
    flex-wrap: wrap;
  }
}








