.header {
  height: 60px;
  display: flex;
  align-items: center;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 100;
}

.header__logo {
  width: 100px;
  height: 40px;
  object-fit: contain;
  border-radius: 10px;
  margin: 0 20px;

}
.header_element_container{
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: 1600px;
}
.header__search {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  
  border: 2px solid rgb(218, 220, 221); /* Set initial border color to gray */
  height: 35px;
  max-width: 500px;
  border-radius: 5px;
}
.header__search:focus-within {
  border-color: #0D6EFD; /* Change border color when the input or button inside is focused */
}

.header__searchInput {
  height: 30px;
  padding: 10px;
  border: none; /* Remove border from input */
  flex-basis: 90%;
  width: 100%;
  outline: none; /* Remove the default focus outline */
  margin:2px;
}

.header__searchIcon {
  border-radius: 5px;
  height: 35px !important;
  color: grey;
  flex-basis: 10%;
  margin: 2px;

  justify-content: center;
  align-items: center;
}

.header__optionLineOne {
  font-size: 10px;
}

.header__optionLineTwo {
  font-size: 13px;
  font-weight: 800;
}

.header__optionBasket {
  display: flex;
  align-items: center;
  color: white;
}

.header__basketCount {
  margin-left: 10px;
  margin-right: 10px;
}

.header__nav {
  display: flex;
  justify-content: space-evenly;
}

.header__option {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
}
.pages_container
{
  display: flex;
  width: 500px;
  justify-content: space-evenly;
  align-items: center;
  align-self: self-end;
  margin-right: 0;
  margin-left: auto;
  height: 60px;
  margin-right: 30px;
}
.pages {
padding: 10px;
display:flex;
flex-direction: column;
font-weight: 400;
font-family: Arial, Helvetica, sans-serif;
color: #333; /* Set the initial color to light black */
transition: color 0.3s; /* Add transition effect for a smooth color change */
align-items: center;
gap: 5px;
font-size: 12px;
}

.pages:hover {
color: #0D6EFD; /* Change the color to black on hover */
}

.social_pages{
height: 30px;
padding: 5px;
font-size: 20px;
transition: color 0.3s; 
color: #333;
}

.social_pages:hover {
color: #0D6EFD; 
}
.logo_search_container
{
display: flex;
width: 100%;
padding: 2px;
align-items: center;


}
.mobileMenuButton {
display: none; /* Initially hide the button on larger screens */
}

.mobileMenuContainer {
position: fixed;
top: 0;
left: -70%; /* Initially position off-screen to the left */
width: 70%; /* Take 70% of the screen width */
height: 100%;
background-color: white;
z-index: 101; /* Ensure it's above other elements */
transition: left 0.3s ease-in-out; /* Add a smooth transition for sliding effect */
}

.mobileMenuContainer.visible {
left: 0; /* Slide in from the left when visible */
}

@media only screen and (max-width: 768px) {
.header {
  flex-direction: column; /* Change the flex direction to column for mobile */
  height: auto; /* Remove fixed height for mobile */
}

.header__search {
  order: 1; /* Move the search bar to the top for mobile */
  padding: 2px;
}

.pages_container {
  flex-direction: row; /* Stack pages vertically for mobile */
  width: 100%; /* Occupy full width for mobile */
  margin: 0; /* Remove margin for mobile */
}

.social_pages {
   /* Move social icons to the top for mobile */
  margin-top: 10px; /* Add some spacing between elements */
}
}
