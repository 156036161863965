.order_request_page{
    max-width: 100%;
    align-items:center;
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
 

}
.Heading{
    align-items: center;
    justify-content: center;
    color: rgb(51, 51, 51);
    box-sizing: border-box;
    font: 700 28px / 35.9999px OpenSans, "Open sans", "PingFang SC", "Microsoft YaHei", "Helvetica Neue", Tahoma, Arial, Helvetica;
    padding: 30px;

}
.product_background {
    background-color: white;
    padding: 20px;
    width: 1200px;
    height: 400px;
    border-radius: 15px;
}
.product_heading {
    font-size: 20px;
    font-display: left;
}
.product_info {
    display: flex;
    flex-direction: row ;
}
.product_img{
    width:400px;
    height: 300px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    

}
.product_img img{
    object-fit: contain;
    max-width: 100%;
    padding: 20px;
    max-height: 100%;
}
.product_name {
    font-size: 16px;
    font-style: italic;
    font-weight: bold;
    text-align: left;
  }
  .next_steps {
    background-color: white;
    margin-top: 20px;
   
    border-radius: 15px;
    padding: 20px;
    width: 1200px;

  }
  .next_step_title{
    font-size: 16px;
    font-weight: bold;
  }
  .steps {
    display: flex;
    flex-direction: row;
    width: 1200px;
    justify-content: space-evenly;
    padding: 10px;
  }
  .step{
    display: flex;
    flex-direction: row;
    padding: 20px;
  }

  .product_detail_title{
    font: 700 20px / 30px OpenSans, "Open sans", "PingFang SC", "Microsoft YaHei", "Helvetica Neue", Tahoma, Arial, Helvetica;
  }
  .quantity-input {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

  }
  .quantity-btn-left {
  /*  width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: blue;
    color: #FFF;
    font-size: 30px;
    cursor: pointer;
    margin-right : -25px;
    border: none;
    outline: none;
    z-index: 1;
    display:flex;
    align-items: center;
    justify-content: center;*/
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: blue;
    color: #FFF;
    font-size: 30px;
    cursor: pointer;
    margin-right: -25px;
    border: none;
    outline: none;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Quantity_Show
  {
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .quantity-btn-right {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: blue;
    color: #FFF;
    font-size: 30px;
    cursor: pointer;
    z-index: 1;
    margin-left: -25px;
    border: none;
    outline: none;
  }
  .inpur_number{
    height: 30px;
    width: 600px; /* Adjust the width as needed */
    border: 1px solid #007BFF;
     /* Half of the height to make it a rounded rectangle */
    text-align: center;
  
  }
  /* Hide the default arrows */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Style for the input */
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield;
  width: 100px; /* Adjust the width as needed */
  height: 30px;
  padding: 5px;
  text-align: center;
  border: 1px solid #007BFF;
  border-radius: 15px; /* Half of the height to make it a rounded rectangle */
}

/* Style for the increment and decrement buttons */
input[type="number"] + div {
  display: inline-flex;
  flex-direction: column;
}

input[type="number"] + div button {
  width: 30px;
  height: 15px;
  border: none;
  background-color: #007BFF;
  color: white;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
}

input[type="number"] + div button:first-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

input[type="number"] + div button:last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.product_section
{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100px;
  width: 800px;
  justify-content: center;
  background-color: #f7f7f7;
}

.total_amount_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
}
.approx{
  text-align: center;
}

/* FileUpload.css */
.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 10px;
 
}

.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  color: #4CAF50;
  border: 2px solid #4CAF50;
  padding: 8px 20px;
  border-radius: 5px;
  background-color: white;
  width: 200px;
  height: 40px;
  text-align: center;
  transition: background-color 0.3s;
}

.custom-file-upload:hover {
  background-color: #4CAF50;
  color: white;
}

#file-input {
  display: none;
}

/* Style for displaying the uploaded file information */
.uploaded-file {
  display: flex;
  flex-direction: row;
  align-items: left;
  margin-top: 10px;
  font: arial;
  border-radius: 0.5rem;
  background-color: #fafafa;
  height: 40px;

}

p {
  font-size: 14px;
  margin-bottom: 5px;
  flex-basis: 80%;
  align-items: center;
  padding: 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}

button {
  cursor: pointer;
  color: #ff0000;
  background: none;
  border: none;
  font-size: 14px;
  align-items: center;
  

}
button_2 {
  cursor: pointer;
  color: #ff0000;
  background: none;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border: none;
  font-size: 14px;
  align-items: center;
  flex-basis: 20%;
}
.asking_details{
  text-align: left;
  justify-content: center;
  font-size: 15px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  padding: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  

}
.phone_number_label
{
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.lower_section{
  display: flex;
  flex-direction: row;
  
}
.attach_file_text{
  text-align: left;
  font-size: 15px;
  font-family: sans-serif;
  padding: 10px;
  width: 500px;

}
.average_order_container{
  display: flex;

  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  border: 1px solid #000; 
  justify-content: center;
}
.average_order_heading{
text-align: center;
font-size: 15px;
font-family: Arial, Helvetica, sans-serif;
font-weight: 500;
}
.qantity_container{
  display: flex;
  flex-direction: row;
}
.average_order_quantity{
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}
.average_order_suffix{
  text-align: center;
  font-size: 10px;

}
.product_price{
  align-items: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  width: 250px;
}
.inpur_section{
  width: 600x;
  flex-basis: 75%;
  height: 200px;

}
.divider-line {
  border-top: 1px solid #ccc; /* You can adjust the color and style of the line */
  margin-top: 0px; /* Adjust this value to set the distance between the heading and the line */
}


.shippment_details{
  background-color: white;
  margin-top: 10px;
  padding: 20px;
  border-radius: 15px;
  width: 1200px;
  
}


/* YourComponent.css */

/* Styles for the shipping details section */
.shippment_details {
  margin-top: 20px;

}

.shipping_detail_text {
  font-size: 18px;
  margin-bottom: 10px;

}

/* Styles for the react-select dropdown */
.example-col {
  margin-top: 10px;
}

/* Custom styles for the dropdown control */
.custom-select-control {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 500px;
}

/* Styles for the dropdown options */
.custom-select-option {
  color: #333;
  cursor: pointer;
  &:hover {
    background-color: #d6ebff; /* Light blue for hover effect */
  }
}

/* Styles for the selected option */
.custom-select-single-value {
  color: #2684FF; /* Blue color for the selected value */
}

/* Styles for the dropdown indicator */
.custom-select-indicator {
  color: #2684FF; /* Blue color for the indicator */
}

/* Styles for the dropdown menu */
.custom-select-menu {
  margin-top: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Styles for the dropdown menu items */
.custom-select-menu-item {
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #d6ebff; /* Light blue for hover effect */
  }
}
.address-input-container {
  margin-top: 15px;
  width: 500px; /* Set your desired width */
}

/* Styles for the address input */
.address-input {
  width: 100%;
  height: 100px; /* Set your desired height */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  outline: none;
  resize: vertical; /* Allow vertical resizing */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Focus style for the address input */
.address-input:focus {
  border: 1px solid #2684FF; /* Blue border on focus */
}

.submit_button_container{
  width: 200px;
  height: 40px;
  background-color: #f60;
  border-color: #f60;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 auto;
  border-radius: 20px;

  align-self: center;
}
.submit_button_text
{
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}