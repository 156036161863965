.Glenbard_Page {
    display: flex;
    align-self: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    
}
.glenbard_form_section
{
    width:400px;
    background-color: white;
    align-items: left;
    align-self: center;
    display: flex;
    margin: 10px auto ;
    flex-direction: column;
    border-radius: 0.5rem;
    height: 300px;
    padding: 10px;

}
.glenbard_page_heading{
    align-items: left;
    text-align: left;
    display: flex;
    font-size: 25px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 15px;
    margin-top: 10px;

}
.glenbard_page_subheading{
    align-items: left;
    text-align: left;
    font-size: 12px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}
.glenbard_class_selection
{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    align-items: left;
    text-align: left;
    
}
.glenbard_select_class_dropdown{
    margin-top: 5px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    appearance: none; /* Hides the default arrow */
    -webkit-appearance: none; /* For older versions of Chrome and Safari */
    -moz-appearance: none; /* For older versions of Firefox */
    background-color: #fff;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M7 10l5 5 5-5z"/></svg>'); /* Custom arrow */
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 20px;
    
}
.glenbard_select_class_dropdown option:hover {
    background-color: #f4f4f4;
  }
  
  /* Style for selected option */
  .glenbard_select_class_dropdown option:checked {
    background-color: #e0f3ff;
  }
  .glenbard_confirm_button{
    margin-top: auto;
    margin-bottom: 5px;
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;
    background-color: blue;
    color:white;
    border-radius: 0.5rem;
    padding: 10px;
    font-weight: 20px;
    

  }

  .error{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    align-items: left;
    text-align: left;
  }