
.illustration_div {
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }
  .illustration_div-2 {
    align-self: stretch;
    display: flex;
    margin-top: 38px;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .illustration_div-2 {
      max-width: 100%;
    }
  }
  .illustration_div-3 {
    align-self: center;
    display: flex;
    width: 100%;
    max-width: 993px;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    padding: 0 20px;
  }
  @media (max-width: 991px) {
    .illustration_div-3 {
      max-width: 100%;
      flex-wrap: wrap;
    }
  }
  .illustration_div-4 {
    color: #000;
    text-align: center;
    align-self: stretch;
    max-width: 1038px;
    flex-grow: 1;
    flex-basis: auto;
    font: 300 36px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-4 {
      max-width: 100%;
    }
  }
  .illustration_div-5 {
    align-self: stretch;
    display: flex;
    width: 185px;
    max-width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
  }
  @media (max-width: 991px) {
    .illustration_div-5 {
      justify-content: center;
    }
  }
  .illustration_img {
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    width: 100%;
    overflow: hidden;
    flex: 1;
  }
  .illustration_img-2 {
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    width: 100%;
    overflow: hidden;
    border-radius: 50%;
    flex: 1;
  }
  .illustration_div-6 {
    background-color: #d9d9d9;
    align-self: center;
    display: flex;
    margin-top: 27px;
    width: 100%;
    max-width: 1511px;
    flex-grow: 1;
    flex-direction: column;
    padding: 77px 20px 58px;
  }
  @media (max-width: 991px) {
    .illustration_div-6 {
      max-width: 100%;
    }
  }
  .illustration_div-7 {
    align-self: center;
    display: flex;
    width: 100%;
    max-width: 992px;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
  }
  @media (max-width: 991px) {
    .illustration_div-7 {
      max-width: 100%;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .illustration_div-8 {
    color: #000;
    text-align: center;
    align-self: center;
    max-width: 383px;
    flex-grow: 1;
    flex-basis: auto;
    margin: auto 0;
    font: 600 36px Inter, sans-serif;
  }
  .illustration_div-9 {
    color: #000;
    text-align: center;
    align-self: start;
    max-width: 383px;
    flex-grow: 1;
    flex-basis: auto;
    font: 600 36px Inter, sans-serif;
  }
  .illustration_div-10 {
    color: #000;
    text-align: center;
    align-self: start;
    max-width: 383px;
    flex-grow: 1;
    flex-basis: auto;
    font: 600 36px Inter, sans-serif;
  }
  .illustration_div-11 {
    color: #000;
    text-align: center;
    align-self: start;
    max-width: 383px;
    flex-grow: 1;
    flex-basis: auto;
    font: 600 36px Inter, sans-serif;
  }
  .illustration_div-12 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    margin: 129px 27px 0 28px;
    padding: 0 20px;
  }
  @media (max-width: 991px) {
    .illustration_div-12 {
      max-width: 100%;
      margin: 40px 10px 0 0;
    }
  }
  .illustration_div-13 {
    color: #000;
    text-align: center;
    align-self: start;
    margin-left: 31px;
    max-width: 569px;
    font: 600 36px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-13 {
      max-width: 100%;
    }
  }
  .illustration_div-14 {
    align-self: center;
    display: flex;
    margin-top: 49px;
    width: 100%;
    max-width: 1457px;
    align-items: flex-start;
    justify-content: space-between;
    gap: 17px;
  }
  @media (max-width: 991px) {
    .illustration_div-14 {
      max-width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 40px;
    }
  }
  .illustration_img-3 {
    aspect-ratio: 0.87;
    object-fit: cover;
    object-position: center;
    width: 99px;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
  }
  .illustration_div-15 {
    color: #000;
    text-align: center;
    align-self: stretch;
    border-radius: 10px;
    background-color: #d9d9d9;
    width: 281px;
    max-width: 100%;
    padding: 48px 14px 37px 10px;
    font: 600 30px Inter, sans-serif;
  }
  @media (max-width: 640px) {
    .illustration_div-15 {
      width: 318px;
    }
  }
  .illustration_div-16 {
    color: #000;
    text-align: center;
    align-self: stretch;
    border-radius: 10px;
    background-color: #d9d9d9;
    width: 352px;
    max-width: 100%;
    padding: 47px 16px 38px 10px;
    font: 600 30px Inter, sans-serif;
  }
  .illustration_div-17 {
    color: #000;
    text-align: center;
    align-self: stretch;
    border-radius: 10px;
    background-color: #d9d9d9;
    width: 363px;
    max-width: 100%;
    padding: 48px 9px 37px 10px;
    font: 600 30px Inter, sans-serif;
  }
  .illustration_div-18 {
    border-radius: 10px;
    background-color: #d9d9d9;
    align-self: stretch;
    display: flex;
    width: 287px;
    max-width: 100%;
    flex-direction: column;
    padding: 49px 20px 42px;
  }
  @media (max-width: 640px) {
    .illustration_div-18 {
      width: 314px;
    }
  }
  .illustration_div-19 {
    color: #000;
    text-align: center;
    align-self: center;
    font: 600 30px Inter, sans-serif;
  }
  .illustration_div-20 {
    align-self: center;
    display: flex;
    margin-top: 425px;
    width: 100%;
    max-width: 1233px;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .illustration_div-20 {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  .illustration_div-21 {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 13px;
    margin: 0 11px 0 22px;
    padding: 0 20px;
  }
  @media (max-width: 991px) {
    .illustration_div-21 {
      max-width: 100%;
      margin-right: 10px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .illustration_div-22 {
    align-self: start;
    display: flex;
    margin-top: 4px;
    width: 296px;
    max-width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
  }
  .illustration_div-23 {
    align-self: start;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: auto;
  }
  .illustration_img-4 {
    aspect-ratio: 1.34;
    object-fit: cover;
    object-position: center;
    width: 156px;
    overflow: hidden;
    align-self: start;
    max-width: 100%;
  }
  .illustration_div-24 {
    color: #000;
    align-self: center;
    margin: 22px 0 0 -12px;
    font: 600 32px Inter, sans-serif;
  }
  .illustration_div-25 {
    color: #7a7a7a;
    align-self: end;
    margin-top: 31px;
    font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .illustration_div-26 {
    background-color: #000;
    align-self: start;
    margin-top: 4px;
    width: 1px;
    height: 416px;
  }
  .illustration_div-27 {
    align-self: start;
    display: flex;
    flex-direction: column;
  }
  .illustration_img-5 {
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    width: 132px;
    overflow: hidden;
    align-self: start;
    max-width: 100%;
  }
  .illustration_div-28 {
    color: #000;
    align-self: start;
    margin: 7px 0 0 16px;
    font: 600 32px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-28 {
      margin-left: 10px;
    }
  }
  .illustration_div-29 {
    color: #7a7a7a;
    align-self: start;
    margin: 41px 0 0 16px;
    font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-29 {
      margin: 40px 0 0 10px;
    }
  }
  .illustration_div-30 {
    background-color: #000;
    align-self: start;
    width: 1px;
    height: 416px;
    flex-grow: 1;
    flex-basis: auto;
  }
  .illustration_div-31 {
    align-self: start;
    display: flex;
    margin-top: 11px;
    flex-direction: column;
  }
  .illustration_img-6 {
    aspect-ratio: 1.3;
    object-fit: cover;
    object-position: center;
    width: 144px;
    overflow: hidden;
    align-self: start;
    max-width: 100%;
  }
  .illustration_div-32 {
    color: #000;
    align-self: start;
    margin: 23px 0 0 11px;
    font: 600 32px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-32 {
      margin-left: 10px;
    }
  }
  .illustration_div-33 {
    color: #7a7a7a;
    align-self: start;
    margin: 35px 0 0 11px;
    font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-33 {
      margin-left: 10px;
    }
  }
  .illustration_div-34 {
    align-self: start;
    display: flex;
    margin-top: 8px;
    flex-direction: column;
  }
  .illustration_img-7 {
    aspect-ratio: 1.23;
    object-fit: cover;
    object-position: center;
    width: 143px;
    overflow: hidden;
    align-self: start;
    max-width: 100%;
  }
  .illustration_div-35 {
    color: #000;
    align-self: start;
    margin-top: 35px;
    font: 600 32px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-35 {
      margin-left: 6px;
    }
  }
  .illustration_div-36 {
    color: #7a7a7a;
    align-self: start;
    max-width: 290px;
    margin: 57px 0 0 11px;
    font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-36 {
      margin: 40px 0 0 10px;
    }
  }
  .illustration_div-37 {
    align-self: stretch;
    
    padding: 0 20px;
  }
  @media (max-width: 991px) {
    .illustration_div-37 {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  .illustration_div-38 {
    gap: 20px;
    display: flex;
  }
  @media (max-width: 991px) {
    .illustration_div-38 {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  .column {
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    line-height: normal;
    width: 47%;
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    .column {
      width: 100%;
    }
  }
  .illustration_div-39 {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .illustration_div-39 {
      max-width: 100%;
      margin-top: 22px;
    }
  }
  .illustration_img-8 {
    aspect-ratio: 0.64;
    object-fit: cover;
    object-position: center;
    width: 494px;
    overflow: hidden;
    align-self: start;
    max-width: 100%;
  }
  .illustration_div-40 {
    align-self: end;
    margin-top: 124px;
    width: 542px;
    max-width: 100%;
  }
  @media (max-width: 991px) {
    .illustration_div-40 {
      margin-top: 40px;
    }
  }
  .illustration_div-41 {
    gap: 20px;
    display: flex;
  }
  @media (max-width: 991px) {
    .illustration_div-41 {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  .illustration_column-2 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 55%;
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    .illustration_column-2 {
      width: 100%;
    }
  }
  .illustration_div-42 {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .illustration_div-42 {
      margin-top: 40px;
    }
  }
  .illustration_img-9 {
    aspect-ratio: 1.23;
    object-fit: cover;
    object-position: center;
    width: 118px;
    overflow: hidden;
    align-self: start;
    max-width: 100%;
  }
  .illustration_div-43 {
    color: #000;
    align-self: start;
    margin: 35px 0 0 15px;
    font: 600 32px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-43 {
      margin-left: 10px;
    }
  }
  .illustration_div-44 {
    color: #7a7a7a;
    align-self: start;
    margin: 35px 0 0 15px;
    font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-44 {
      margin-left: 10px;
    }
  }
  .illustration_column-3 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 45%;
    margin-left: 20px;
  }
  @media (max-width: 991px) {
    .illustration_column-3 {
      width: 100%;
    }
  }
  .illustration_div-45 {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .illustration_div-45 {
      margin-top: 40px;
    }
  }
  .illustration_img-10 {
    aspect-ratio: 1.01;
    object-fit: cover;
    object-position: center;
    width: 117px;
    overflow: hidden;
    align-self: start;
    max-width: 100%;
  }
  .illustration_div-46 {
    color: #000;
    align-self: start;
    margin-top: 27px;
    font: 600 32px Inter, sans-serif;
  }
  .illustration_div-47 {
    color: #7a7a7a;
    align-self: start;
    margin-top: 22px;
    font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .illustration_div-48 {
    align-self: center;
    margin-top: 90px;
    width: 542px;
    max-width: 100%;
  }
  @media (max-width: 991px) {
    .illustration_div-48 {
      margin-top: 40px;
    }
  }
  .illustration_div-49 {
    gap: 20px;
    display: flex;
  }
  @media (max-width: 991px) {
    .illustration_div-49 {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  .illustration_column-4 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 57%;
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    .illustration_column-4 {
      width: 100%;
    }
  }
  .illustration_div-50 {
    display: flex;
    flex-direction: column;
    margin: auto 0;
  }
  @media (max-width: 991px) {
    .illustration_div-50 {
      margin-top: 40px;
    }
  }
  .illustration_img-11 {
    aspect-ratio: 0.85;
    object-fit: cover;
    object-position: center;
    width: 100px;
    overflow: hidden;
    align-self: start;
    max-width: 100%;
  }
  .illustration_div-51 {
    color: #000;
    align-self: start;
    margin-top: 15px;
    font: 600 32px Inter, sans-serif;
  }
  .illustration_div-52 {
    color: #7a7a7a;
    align-self: start;
    margin-top: 25px;
    font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .illustration_column-5 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 43%;
    margin-left: 20px;
  }
  @media (max-width: 991px) {
    .illustration_column-5 {
      width: 100%;
    }
  }
  .illustration_div-53 {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .illustration_div-53 {
      margin-top: 40px;
    }
  }
  .illustration_img-12 {
    aspect-ratio: 0.72;
    object-fit: cover;
    object-position: center;
    width: 103px;
    overflow: hidden;
    align-self: start;
    max-width: 100%;
  }
  .illustration_div-54 {
    color: #000;
    align-self: start;
    margin-top: 4px;
    font: 600 32px Inter, sans-serif;
  }
  .illustration_div-55 {
    color: #7a7a7a;
    align-self: start;
    margin-top: 35px;
    font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .illustration_column-6 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 53%;
    margin-left: 20px;
  }
  @media (max-width: 991px) {
    .illustration_column-6 {
      width: 100%;
    }
  }
  .illustration_div-56 {
    display: flex;
    margin-top: 82px;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .illustration_div-56 {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  .illustration_div-57 {
    align-self: end;
    width: 616px;
    max-width: 100%;
  }
  .illustration_div-58 {
    gap: 20px;
    display: flex;
  }
  @media (max-width: 991px) {
    .illustration_div-58 {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  .illustration_column-7 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 43%;
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    .illustration_column-7 {
      width: 100%;
    }
  }
  .illustration_div-59 {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .illustration_div-59 {
      margin-top: 40px;
    }
  }
  .illustration_img-13 {
    aspect-ratio: 1.29;
    object-fit: cover;
    object-position: center;
    width: 154px;
    overflow: hidden;
    align-self: start;
    max-width: 100%;
  }
  .illustration_div-60 {
    color: #000;
    align-self: start;
    margin: 4px 0 0 12px;
    font: 600 32px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-60 {
      margin-left: 10px;
    }
  }
  .illustration_div-61 {
    color: #7a7a7a;
    align-self: start;
    margin: 32px 0 0 16px;
    font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-61 {
      margin-left: 10px;
    }
  }
  .illustration_column-8 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 57%;
    margin-left: 20px;
  }
  @media (max-width: 991px) {
    .illustration_column-8 {
      width: 100%;
    }
  }
  .illustration_div-62 {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .illustration_div-62 {
      margin-top: 40px;
    }
  }
  .illustration_img-14 {
    aspect-ratio: 1.16;
    object-fit: cover;
    object-position: center;
    width: 116px;
    overflow: hidden;
    align-self: start;
    max-width: 100%;
  }
  .illustration_div-63 {
    color: #000;
    align-self: center;
    margin-top: 22px;
    font: 600 32px Inter, sans-serif;
  }
  .illustration_div-64 {
    color: #7a7a7a;
    align-self: start;
    margin-top: 31px;
    max-width: 290px;
    font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-64 {
      margin-left: 9px;
    }
  }
  .illustration_div-65 {
    align-self: start;
    margin-top: 169px;
    width: 628px;
    max-width: 100%;
  }
  @media (max-width: 991px) {
    .illustration_div-65 {
      margin-top: 40px;
    }
  }
  .illustration_div-66 {
    gap: 20px;
    display: flex;
  }
  @media (max-width: 991px) {
    .illustration_div-66 {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  .illustration_column-9 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 45%;
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    .illustration_column-9 {
      width: 100%;
    }
  }
  .illustration_div-67 {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .illustration_div-67 {
      margin-top: 40px;
    }
  }
  .illustration_img-15 {
    aspect-ratio: 1.29;
    object-fit: cover;
    object-position: center;
    width: 146px;
    overflow: hidden;
    align-self: start;
    max-width: 100%;
  }
  .illustration_div-68 {
    color: #000;
    align-self: center;
    margin: 37px 0 0 -21px;
    font: 600 32px Inter, sans-serif;
  }
  .illustration_div-69 {
    color: #7a7a7a;
    align-self: end;
    margin-top: 47px;
    font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-69 {
      margin-top: 40px;
    }
  }
  .illustration_column-10 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 55%;
    margin-left: 20px;
  }
  @media (max-width: 991px) {
    .illustration_column-10 {
      width: 100%;
    }
  }
  .illustration_div-70 {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .illustration_div-70 {
      margin-top: 40px;
    }
  }
  .illustration_img-16 {
    aspect-ratio: 1.1;
    object-fit: cover;
    object-position: center;
    width: 128px;
    overflow: hidden;
    align-self: start;
    max-width: 100%;
  }
  .illustration_div-71 {
    color: #000;
    align-self: start;
    margin: 34px 0 0 15px;
    font: 600 32px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-71 {
      margin-left: 10px;
    }
  }
  .illustration_div-72 {
    color: #7a7a7a;
    align-self: start;
    max-width: 290px;
    margin: 38px 0 0 19px;
    font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-72 {
      margin-left: 10px;
    }
  }
  .illustration_div-73 {
    align-self: center;
    width: 584px;
    max-width: 100%;
    margin: 136px 0 0 12px;
  }
  @media (max-width: 991px) {
    .illustration_div-73 {
      margin-top: 40px;
    }
  }
  .illustration_div-74 {
    gap: 20px;
    display: flex;
  }
  @media (max-width: 991px) {
    .illustration_div-74 {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  .illustration_column-11 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 45%;
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    .illustration_column-11 {
      width: 100%;
    }
  }
  .illustration_div-75 {
    display: flex;
    margin-top: 10px;
    flex-grow: 1;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .illustration_div-75 {
      margin-top: 40px;
    }
  }
  .illustration_img-17 {
    aspect-ratio: 1.07;
    object-fit: cover;
    object-position: center;
    width: 128px;
    overflow: hidden;
    align-self: start;
    max-width: 100%;
  }
  .illustration_div-76 {
    color: #000;
    align-self: start;
    margin: 11px 0 0 24px;
    font: 600 32px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-76 {
      margin-left: 10px;
    }
  }
  .illustration_div-77 {
    color: #7a7a7a;
    align-self: start;
    margin: 50px 0 0 16px;
    font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-77 {
      margin: 40px 0 0 10px;
    }
  }
  .illustration_column-12 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 55%;
    margin-left: 20px;
  }
  @media (max-width: 991px) {
    .illustration_column-12 {
      width: 100%;
    }
  }
  .illustration_div-78 {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .illustration_div-78 {
      margin-top: 40px;
    }
  }
  .illustration_img-18 {
    aspect-ratio: 0.93;
    object-fit: cover;
    object-position: center;
    width: 108px;
    overflow: hidden;
    align-self: start;
    max-width: 100%;
  }
  .illustration_div-79 {
    color: #000;
    align-self: start;
    margin: 29px 0 0 20px;
    font: 600 32px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-79 {
      margin-left: 10px;
    }
  }
  .illustration_div-80 {
    color: #7a7a7a;
    align-self: start;
    margin: 43px 0 0 18px;
    font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-80 {
      margin: 40px 0 0 10px;
    }
  }
  .illustration_div-81 {
    align-self: center;
    display: flex;
    margin-top: 528px;
    width: 100%;
    max-width: 1115px;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    padding: 0 20px;
  }
  @media (max-width: 991px) {
    .illustration_div-81 {
      max-width: 100%;
      margin-top: 40px;
      flex-wrap: wrap;
    }
  }
  .illustration_div-82 {
    color: #000;
    align-self: start;
    margin-top: 7px;
    max-width: 1177px;
    flex-grow: 1;
    flex-basis: auto;
    font: 600 32px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-82 {
      max-width: 100%;
    }
  }
  .illustration_img-19 {
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    width: 39px;
    fill: #fff;
    stroke-width: 3px;
    stroke: #000;
    overflow: hidden;
    align-self: start;
    max-width: 100%;
  }
  .illustration_div-83 {
    background-color: #f3f2f0;
    align-self: stretch;
    display: flex;
    margin-top: 221px;
    width: 100%;
    flex-direction: column;
    padding: 75px 20px 187px;
  }
  @media (max-width: 991px) {
    .illustration_div-83 {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  .illustration_div-84 {
    align-self: center;
    width: 100%;
    max-width: 1176px;
  }
  @media (max-width: 991px) {
    .illustration_div-84 {
      max-width: 100%;
    }
  }
  .illustration_div-85 {
    gap: 20px;
    display: flex;
  }
  @media (max-width: 991px) {
    .illustration_div-85 {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  .illustration_column-13 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    .illustration_column-13 {
      width: 100%;
    }
  }
  @media (max-width: 991px) {
    .illustration_div-86 {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  .illustration_div-87 {
    gap: 20px;
    display: flex;
  }
  @media (max-width: 991px) {
    .illustration_div-87 {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  .illustration_div-88 {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .illustration_div-88 {
      margin-top: 40px;
    }
  }
  .illustration_div-89 {
    color: #000;
    align-self: start;
    width: 242px;
    font: 600 28px Inter, sans-serif;
  }
  .illustration_div-90 {
    color: #000;
    align-self: start;
    margin-top: 41px;
    width: 242px;
    font: 300 24px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-90 {
      margin-top: 40px;
    }
  }
  .illustration_div-91 {
    color: #000;
    align-self: start;
    margin-top: 32px;
    width: 242px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-92 {
    color: #000;
    align-self: start;
    margin-top: 32px;
    width: 242px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-93 {
    color: #000;
    align-self: start;
    margin-top: 33px;
    width: 242px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-94 {
    color: #000;
    margin-top: 33px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-95 {
    color: #000;
    align-self: start;
    margin-top: 32px;
    width: 242px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-96 {
    color: #000;
    align-self: start;
    margin-top: 32px;
    width: 242px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_column-14 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 53%;
    margin-left: 20px;
  }
  @media (max-width: 991px) {
    .illustration_column-14 {
      width: 100%;
    }
  }
  .illustration_div-97 {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .illustration_div-97 {
      margin-top: 40px;
    }
  }
  .illustration_div-98 {
    color: #000;
    align-self: start;
    width: 241px;
    font: 600 28px Inter, sans-serif;
  }
  .illustration_div-99 {
    color: #000;
    align-self: start;
    margin-top: 40px;
    width: 242px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-100 {
    color: #000;
    align-self: start;
    margin-top: 27px;
    width: 242px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-101 {
    color: #000;
    align-self: start;
    margin-top: 27px;
    width: 242px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-102 {
    color: #000;
    align-self: start;
    margin-top: 27px;
    text-wrap: nowrap;
    font: 300 24px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-102 {
      text-wrap: initial;
    }
  }
  .illustration_div-103 {
    color: #000;
    align-self: start;
    margin-top: 27px;
    width: 242px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-104 {
    color: #000;
    align-self: start;
    margin-top: 27px;
    width: 242px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_column-15 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 20px;
  }
  @media (max-width: 991px) {
    .illustration_column-15 {
      width: 100%;
    }
  }
  .illustration_div-105 {
    flex-grow: 1;
  }
  @media (max-width: 991px) {
    .illustration_div-105 {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  .illustration_div-106 {
    gap: 20px;
    display: flex;
  }
  @media (max-width: 991px) {
    .illustration_div-106 {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  .illustration_column-16 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    .illustration_column-16 {
      width: 100%;
    }
  }
  .illustration_div-107 {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .illustration_div-107 {
      margin-top: 40px;
    }
  }
  .illustration_div-108 {
    color: #000;
    align-self: start;
    width: 241px;
    font: 600 28px Inter, sans-serif;
  }
  .illustration_div-109 {
    color: #000;
    align-self: start;
    margin-top: 42px;
    width: 241px;
    font: 300 24px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-109 {
      margin-top: 40px;
    }
  }
  .illustration_div-110 {
    color: #000;
    align-self: start;
    margin-top: 27px;
    width: 241px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-111 {
    color: #000;
    align-self: start;
    margin-top: 32px;
    width: 241px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-112 {
    color: #000;
    align-self: start;
    margin-top: 27px;
    width: 241px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-113 {
    color: #000;
    align-self: start;
    margin-top: 27px;
    width: 241px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-114 {
    color: #000;
    align-self: start;
    margin-top: 27px;
    width: 241px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-115 {
    color: #000;
    margin-top: 27px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-116 {
    color: #000;
    margin-top: 28px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_column-17 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 20px;
  }
  @media (max-width: 991px) {
    .illustration_column-17 {
      width: 100%;
    }
  }
  .illustration_div-117 {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .illustration_div-117 {
      margin-top: 40px;
    }
  }
  .illustration_div-118 {
    color: #000;
    align-self: start;
    width: 242px;
    font: 600 28px Inter, sans-serif;
  }
  .illustration_div-119 {
    color: #000;
    align-self: start;
    margin-top: 48px;
    width: 241px;
    font: 300 24px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .illustration_div-119 {
      margin-top: 40px;
    }
  }
  .illustration_div-120 {
    color: #000;
    align-self: start;
    margin-top: 29px;
    width: 241px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-121 {
    color: #000;
    align-self: start;
    margin-top: 26px;
    width: 242px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-122 {
    color: #000;
    align-self: start;
    margin-top: 31px;
    width: 242px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-123 {
    color: #000;
    align-self: start;
    margin-top: 33px;
    width: 242px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-124 {
    color: #000;
    align-self: start;
    margin-top: 27px;
    width: 242px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-125 {
    color: #000;
    align-self: start;
    margin-top: 32px;
    width: 242px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-126 {
    color: #000;
    align-self: start;
    margin-top: 33px;
    width: 242px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-127 {
    color: #000;
    align-self: start;
    margin-top: 26px;
    width: 242px;
    font: 300 24px Inter, sans-serif;
  }
  .illustration_div-128 {
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.76);
    align-self: start;
    display: flex;
    margin-top: 50px;
    width: 194px;
    max-width: 100%;
    flex-direction: column;
    padding: 18px 20px 11px;
  }
  @media (max-width: 991px) {
    .illustration_div-128 {
      margin: 40px 0 0 -3px;
    }
  }
  .illustration_div-129 {
    color: #fff;
    align-self: center;
    width: 100%;
    font: 600 40px Inter, sans-serif;
  }
  .illustration_div-130 {
    background-color: #000;
    align-self: stretch;
    min-height: 1px;
    width: 100%;
    margin: 27px -20px -37px 0;
  }
  @media (max-width: 991px) {
    .illustration_div-130 {
      max-width: 100%;
      margin-bottom: 10px;
    }
  }
  .bottom_line {
    background-color: #000;
    align-self: center;
    width: 100%;
    height: 1px;
  }

 