.static_page{
    max-width:1500px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

}
.page_part{
    width: 100%;

}
.header_poster{
    background-color: skyblue;
    height: 500px;
    align-items: center;
    justify-content: center;
    width: 100%;
    display: flex;
    flex-direction: row;

}
.header_image{
    padding: 20px;
    object-fit: fill;
    align-items: center;
    justify-content: center;
}
.header_image img{
    padding: 20px;
    object-fit: fill;
    align-items: center;
    justify-content: center;
}
.text_container{
    padding-top: 20px;

}

.top_title{
    font: 700 18px / 27px Graphik, sans-serif;
    text-align: left;
    margin-left: 100px;
}
.second_title{
    padding-top: 5px;
    font: 900 64.998px / 64.998px Graphik, sans-serif;
    text-align: left;
    margin-left: 100px;
    width: 576.906px;

}
.title_message{
    font: 18px / 27px Graphik, sans-serif;
    text-align: left;
    margin-left: 100px;
    width: 576.906px;
    padding-top: 5px;
}
.page_section_migration_container{
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    margin-left: 100px;
    width: 400px;
    justify-content: space-around;

}
.First_Button_Container{
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-decoration: none;
    width: 200px;
}
.First_Button
{
    text-decoration: none;
    width: 150px; /* Adjust the width as needed */
    height: 40px; /* Adjust the height to match the width */
    border: 1px solid white;
    border-radius: 30px;
    font: 700 14.004px / 19.6056px Graphik, sans-serif;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
    display: flex;
    background-color: white;
    color: black;
}
.second_button_container{
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-decoration: none;
    width: 200px;

}
.second_button

{
    text-decoration: none;
    width: 150px; /* Adjust the width as needed */
    height: 40px; /* Adjust the height to match the width */
    border: 1px solid rgb(0, 0, 0);
    border-radius: 30px;
    font: 700 14.004px / 19.6056px Graphik, sans-serif;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
    display: flex;
    background-color: black;
    color: white;
}

.section_2{
    align-items: center;
    background-color: white;
    
}
.section_2_title{
    text-align: center;
    align-items: center;
    font: 700 46.008px / 55.2096px Graphik, sans-serif;
    padding-top: 40px;
}
.section_2_subtitle{
    padding-top: 20px;
    text-align: center;
    align-items: center;
    font: 18px / 27px Graphik, sans-serif;
}
.section_2_icons_container{
    
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    width: 600px;
    height: 300px;

    position: relative;
    align-self: center;
}
.section_2_icons{
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 250px;
    width: 250px;
    object-fit: fill;
    
}
.section_2_icon_image{
    padding: 10px;
    object-fit: fill;
    height: 220px;
    width: 220px;

}
.section_2_icon_image img{
    padding: 30px;
    object-fit: fill;
    height: 220px;
    width: 220px;
    border-radius: 50%;

}

.a{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}
.section_2_icon_text{
    align-items: center;
    justify-content: center;
    text-align: center;
    font: 18px / 27px Graphik, sans-serif;
    width: 210px;
}
.section_2_next_word{
    text-align: center;
    font: 700 14.004px / 21.006px Graphik, sans-serif;
    justify-content: center;
    align-items: center;
    padding: 30px;
}
.section_2_next_word_tag{
    text-decoration: none;
    color: black;
}
.section_3
{
    align-items: center;
    justify-content: center;
    background-color: white;

}
.section_3_heading{
    padding-top: 20px;
    text-align:center;
    justify-content: center;
    align-items: center;
    font: 700 46.008px / 55.2096px Graphik, sans-serif;
}
.section_3_subheading{
    padding-top: 20px;
    text-align: center;
    align-items: center;
    font: 18px / 27px Graphik, sans-serif;
    justify-content: center;
}
.section_3_card_container{
    display: flex;
    flex-direction: row;
    height: 600px;
    max-width: 1500px;
align-items: center;
justify-content: space-around; /* Adjust as needed */
   
   
    margin: 0 auto; /* Center horizontally */
}
.section_3_cards{
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 400px;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 10px;
    background: rgb(248, 248, 248) ;
   


}
.section_3_card_image{
    width: 100%; /* Take the full width of the card container */
    height: 100%;
    object-fit: fill;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    margin: 0;
    

    

}
.section_3_card_image img{
    width: 100%; /* Take the full width of the image container */
    height: 100%;
    object-fit: fill; /* Adjust this based on your design needs */
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;

    
}
.section_3_card_text
{
    align-items: center;
    text-align: center;
    height: 50px;
    font-size: 18px;
    font-weight: 700;
    font-family: sans-serif;
    width: 100%; 
}
.section_4{
    height: 400px;
    max-width: 1500px;
    background-color: white;
}
.section_4_heading{
    text-align: left;
    margin-left: 10px;
    font-size: 27px;
    font-weight: 700;
    font-family: sans-serif;
    padding-left: 20px;
}
.section_4_card_container{
    display: flex;
    flex-direction: row;
    height: 400px;
    max-width: 1500px;
    align-items: center;
justify-content: space-around; /* Adjust as needed */

}
.section_4_card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.section_4_card_image
{
    height: 200px;
    width: 200px;
    align-items: center;
    object-fit: fill;
    border-radius: 50%;
    background: rgb(248, 248, 248);
}
.section_4_card_image img
{
    height: 200px;
    width: 200px;
    align-items: center;
    object-fit: fill;
    border-radius: 50%;
}
.section_4_card_text{
    text-align: center;
    align-items: center;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 500;

   padding-top: 5px;
}
.section_5
{
    height: 200px;
    max-width: 1500px;
    align-items: center;
    justify-content: center;

}
.section_5_heading{
    align-items: center;
    justify-content: center;
    text-align: center;
    font: 700 46.008px / 55.2096px Graphik, sans-serif;
    padding-bottom: 10px;
}
.sectiion_5_subheading
{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
   
    border: 1px solid rgb(0, 0, 0);
    height: 40px;
    border-radius: 30px;
    width: 200px;
    background-color: aqua;
    margin: 0 auto; 
    background:black;
    color: white;

}

.contact_number{
    text-decoration: none;
    color: white;
    display: flex;
    flex-direction: row;
}