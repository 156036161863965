/* Reset some default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Apply styles to the entire home section */
.home {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  max-width: 1500px;
  margin: 0 auto;

  width: 1500px;
}

/* Style the image slider container */
.image-slider {
  width: 100%;

  height: auto; /* Set the height based on your design */
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: flex-start; /* All images will align to the left */
  align-items: center;
}

/* Style individual slides/images */
.currentSlide {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.5s ease;
  opacity: 0;
  object-fit: fill; /* Add this line to maintain aspect ratio and fit the image within the container */
}



/* Style the active slide */
.currentSlide.active {
  opacity: 1;
  transition-duration: 0.5s;
  transform: scale(1.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Style the left and right arrow buttons */
.left, .right {
  position: absolute;
  top: 50%;
  font-size: 3rem;
  color: yellow;
  z-index: 5;
  cursor: pointer;
  user-select: none;
}

.left {
  left: 2rem;
}

.right {
  right: 2rem;
}

/* Style the product rows */
.home__row {
  display: flex;
  margin: 2px ;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: 1500px;
  flex-direction: row;
  height: 350px;
  background-color: rgb(229, 231, 235);
}
.second_advert{
  width: 1500px;
  height: 200px;
  object-fit: fill;
}
.second_advert img{
  width: 1500px;
  height: 500px;
}
/* Modify scrollbar styles */
*::-webkit-scrollbar {
  display: none;
}
.home__container{
  width: 1500px;
  height: auto;
  justify-content: center;
  background-color: rgb(229, 231, 235);
  
  }

  /* Modify the .image class */

  /* Add a custom class to target the Carousel component */
.carousel-container {

  position: absolute;

  max-width: 1500px;

}

.Sections {

  padding: 20px;
  align-items: center;
  position: relative;
  vertical-align: middle;
  width: 370px;
  height: 350px;
  border-radius: 16px;

  justify-items: center;
  object-fit: fill;
  flex-basis: 25%;

  
} 
.home_page_text{
  height: 100px;
  text-align: center;
}

.Sections img{
  background-color:white;

  align-items: center;
  position: relative;
  vertical-align: middle;
  height: 100%;
  border-radius: 16px;
  width: 100%;
  justify-items: center;
  object-fit: fill;
  &:hover {
    box-shadow: 0 4px 1rem -4px #000;
    transform: translateY(-3px);
  }

} 


.page_cards {
  flex-direction: row;
  display: flex;
  height:  500px;
  max-width: 1500px;
  align-items: center;
  justify-items: center;
  vertical-align: middle;
  position: relative;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  object-fit: contain;

}
.page_card_left {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-items: center;
  vertical-align: middle;
  width: 400px;
  flex-basis: 25%;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: auto;
  box-sizing: border-box;

}
.page_card_middle
{
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-items: center;
  vertical-align: middle;
  width: 750px;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  flex-basis: 50%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  box-sizing: border-box;
}

.page_card_middle_middle
{
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-items: center;
  
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;

}


.page_card_right {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-items: center;
  vertical-align: middle;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-basis: 25%;

}

.card_left_top {
  height: 200px;
  width: 350px;
  flex-basis: auto;
  align-self: start;
  padding-bottom: 0.5rem;
}

.card_left_top img {
  height: 200px;
  width: 100%;
  flex-basis: auto;
  align-self: start;
  border-radius: 0.5rem;
    
  &:hover {
    box-shadow: 0 4px 1rem -4px #000;
    transform: translateY(-3px);
  }
}
.card_left_middle{
  width: 100%;
  height: 500px;
  flex-basis: auto;
  align-self: start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

}
.card_left_middle img{
  border-radius: 0.5rem;
  margin-left: 0;
  height: 500px;
  width: 350px;
    
  &:hover {
    box-shadow: 0 4px 1rem -4px #000;
    transform: translateY(-3px);
  }

}
.card_left_down{
  width: 350px;
  height: 260px;
  text-align: left;
  flex-basis: auto;
  align-self: start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

}
.card_left_down img {
  width: 350px;
  height: 250px;
  border-radius: 0.5rem;
  margin: 0; /* Align the image to the left */
  object-fit: fill;
    
  &:hover {
    box-shadow: 0 4px 1rem -4px #000;
    transform: translateY(-3px);
  }
}
.card_middle_top {
  border-radius: 0.5rem;
  height: 400px;

  padding-bottom: 0.5rem;
  align-self: start;
  object-fit: contain;
}
.card_middle_top img{
  border-radius: 0.5rem;
  height: 500px;
  width: 750px;
    
  &:hover {
    box-shadow: 0 4px 1rem -4px #000;
    transform: translateY(-3px);
  }
}
.card_right_top{
  height: 200px;
  width: 100%;
  object-fit: contain;
  padding-bottom: 0.5rem;
  align-self: start;
}
.card_right_top img{
  height: 400px;
  width: 100%;
  align-self: start;
  border-radius: 0.5rem;
    
  &:hover {
    box-shadow: 0 4px 1rem -4px #000;
    transform: translateY(-3px);
  }

}
.card_right_middle{
  width: 100%;
  height: 500px;
  object-fit: contain;
  align-self:start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.card_right_middle img {
  width: 100%;
  height: 250px;
  align-self: start;
  border-radius: 0.5rem;
    
  &:hover {
    box-shadow: 0 4px 1rem -4px #000;
    transform: translateY(-3px);
  }
}

.card_middle_middle_left{
    width: 100%;
    height: 300px;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    flex-basis: 50%;
    object-fit: contain;
    align-self: start;
}
.card_middle_middle_left img{
  width: 450px;
  height: 300px;
  border-radius: 0.5rem;
    
  &:hover {
    box-shadow: 0 4px 1rem -4px #000;
    transform: translateY(-3px);
  }

}

.card_middle_middle_right{
  width: 100%;
  height: 300px;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  flex-basis: 50%;
}

.card_middle_middle_right img{
  width: 450px;
  height: 300px;

  border-radius: 0.5rem;
    
  &:hover {
    box-shadow: 0 4px 1rem -4px #000;
    transform: translateY(-3px);
  }

}
.card_middle_down{
  width: 100%;
  height: 250px;
  object-fit: contain;
  align-self: start;
  padding-top: 0.5rem;
}
.card_middle_down img{
  width: 100%;
  height: 250px;
  border-radius: 0.5rem;
    
  &:hover {
    box-shadow: 0 4px 1rem -4px #000;
    transform: translateY(-3px);
  }
}
.card_right_down img{
  border-radius: 0.5rem;
  height: 470px;
    
  &:hover {
    box-shadow: 0 4px 1rem -4px #000;
    transform: translateY(-3px);
  }
}
.card_right_down {
  padding-top: 0.5rem;
}
