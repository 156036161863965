.h1 {
    padding-top: 30px ;
    align-items: center;
    font-size: 40px;
    height: 55px;
    color: #f57224;

}
.h1_sub {
    align-items: center;
    font-size: 20px;
    height: 25px;
    color: #f57224;
}
.category_display {
    background-color: #fff;
    height: 185px;
    width: 1200px;
    margin: 0 auto -77px;
    top: -77px;
    z-index: 2;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    align-items: center;
}
.institute_section_page_container
{
    display: flex;
    flex-direction: row;

}

.product_page_main_section_container
{
    max-width: 1600px;
    display: flex;
    margin: 0 20px;
}
.product_page_main_section
{
    max-width: 1600px;
  
}
.product_display {
    
    height: 400px;
    width: 1600px;
    margin: 0 auto 50px;
    top: 30px;
    z-index: 2;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}
.product_heading{
    padding: 10px;
    font-size: 20px;
    align-items: initial;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;

}
.category_heading {
    align-items: center;
    height: 28px;
    padding: 5px;
    color: rgb(51, 51, 51);
    padding: 5px;
    font: 700 20px / 28px OpenSans, "Open sans", "Helvetica Neue", Helvetica, Arial, "Microsoft YaHei", "PingFang SC", Tahoma;

}
.divider-line {
    border-top: 1px solid #ccc; /* You can adjust the color and style of the line */
    margin-top: 0px; /* Adjust this value to set the distance between the heading and the line */
  }
.product-sliding-container {
    /* Adjust container styles as needed */
    /*
    color: #333;
    background: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    height: 350px;
    border-radius: 0.5rem;*/
    display: flex;
    border-radius: 6px;
    color: #333;
    background: #eee;
    padding: 1rem;
    /*box-shadow: 0 0 1rem #000 inset;*/
    overflow-x: auto;
    position: relative;
    
    &::-webkit-scrollbar {
      display: none;
    }
  }


.product-sliding-card{
  /* Adjust styles for individual product cards */
 /*width: 340px; 
  padding: 10px;
  text-align: center;
  background-color: white;
  text-decoration: none;
  /* Add other styles */
  flex: 1 0 250px;
  box-shadow: 0 1px 1rem -4px #000;
  background: #fff;
  height: 320px;
  margin: 1rem;
  overflow: hidden;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  text-decoration: none;
  

  
  &:hover {
    box-shadow: 0 4px 1rem -4px #000;
    transform: translateY(-3px);
  }
}
.product-sliding-img{
    
    height: 250;
    width: 250;
    object-fit: fill;
    border-radius: 0.5rem;
}
.product-sliding-img img{
    height: 250px;
    width: 250px;
    padding: 0.5rem;
    border-radius: 0.5rem;
    object-fit: fill;
}

.link {
    text-decoration: none;
    /* Add other styles as needed */
  }

.product-sliding-name{
    text-decoration: none;
    text-align: center;

    color: black;
    font-size: 17px;

}
.ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  


  .products-container .product-card .ellipsis_product {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;


}

.product-sliding-price{
    text-decoration: none;
    text-align: center;
    font-weight: 700;
    color: black;
    margin-top: auto;
    margin-bottom: 2px;

    align-items: end;
    position: absolute;
    bottom: 5px; /* Adjust this value as needed for the desired distance from the bottom */
    left: 0;
    right: 0;


}
.category_products-container
{
    display: flex;
    flex-direction: row;
    
    align-items: center;
    padding: 2px;
    
    height: auto;

}
.category-img img {


    block-size: 60px;
    border-block-end-color: rgb(34, 34, 34);
    border-block-start-color: rgb(34, 34, 34);
    border-inline-end-color: rgb(34, 34, 34);
    border-inline-start-color: rgb(34, 34, 34);
    box-sizing: border-box;
    caret-color: rgb(34, 34, 34);
    color: rgb(34, 34, 34);
    column-rule-color: rgb(34, 34, 34);
    cursor: pointer;
    height: 60px;
    inline-size: 60px;
    max-block-size: 60px;
    max-height: 60px;
    max-inline-size: 60px;
    max-width: 60px;
    perspective-origin: 30px 30px;
    text-decoration: none solid rgb(34, 34, 34);
    text-emphasis-color: rgb(34, 34, 34);
    text-size-adjust: 100%;
    transform-origin: 30px 30px;
    width: 60px;
    border: 0px none rgb(34, 34, 34);
    font: 14px / 20px OpenSans, "Open sans", "Helvetica Neue", Helvetica, Arial, "Microsoft YaHei", "PingFang SC", Tahoma;
    outline: rgb(34, 34, 34) none 0px;
    border-radius: 999%;
  }

.category-card {

    text-decoration: none;
    align-items: center;
    block-size: 136px;
    border-block-end-color: rgb(34, 34, 34);
    border-block-start-color: rgb(34, 34, 34);
    border-inline-end-color: rgb(34, 34, 34);
    border-inline-start-color: rgb(34, 34, 34);
    bottom: 0px;
    box-sizing: border-box;
    caret-color: rgb(34, 34, 34);
    color: rgb(34, 34, 34);
    column-rule-color: rgb(34, 34, 34);
    cursor: pointer;
    display: flex;
    height: 136px;
    inline-size: 150px;
    inset-block-end: 0px;
    inset-block-start: 0px;
    inset-inline-end: 0px;
    inset-inline-start: 0px;
    justify-content: center;
    left: 0px;
    max-inline-size: 300px;
    max-width: 300px;
    min-block-size: auto;
    min-height: auto;
    min-inline-size: auto;
    min-width: auto;
    padding-block-end: 8px;
    padding-block-start: 8px;
    padding-inline-end: 10px;
    padding-inline-start: 10px;
    perspective-origin: 75px 68px;
    position: relative;
    right: 0px;
    text-decoration: none solid rgb(34, 34, 34);
    text-emphasis-color: rgb(34, 34, 34);
    text-size-adjust: 100%;
    top: 0px;
    transform-origin: 75px 68px;
    width: 150px;
    border: 0px none rgb(34, 34, 34);
    flex-flow: column nowrap;
    font: 14px / 20px OpenSans, "Open sans", "Helvetica Neue", Helvetica, Arial, "Microsoft YaHei", "PingFang SC", Tahoma;
    outline: rgb(34, 34, 34) none 0px;
    padding: 8px 10px;

  }
.category-card-container{
    display: flex;
    flex-direction: row;
}
.category-name {

    word-wrap: break-word; /* This property allows text to wrap within the container */
    font: 14px / 20px OpenSans, "Open sans", "Helvetica Neue", Helvetica, Arial, "Microsoft YaHei", "PingFang SC", Tahoma;

    
  }
.page-name
{
    text-align: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F10.png?alt=media&token=93382b10-593f-402c-a233-36f423d40e98&_gl=1*10w6yj9*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzQ4NTA5My4zOC4xLjE2OTc0OTA0NjAuNDUuMC4w");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% ;
    color: #f57224;
    height: 240px;

}

.page-name-school
{
    text-align: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F10.png?alt=media&token=93382b10-593f-402c-a233-36f423d40e98&_gl=1*10w6yj9*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzQ4NTA5My4zOC4xLjE2OTc0OTA0NjAuNDUuMC4w");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% ;
    color: #f57224;
    height: 240px;
    
}
.page-name-school::before {
    content: '';
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 240px;
    background: rgba(0, 0, 0, 0.3);
}
.school_page_heading
{
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    display: -webkit-box;

    inline-size: 1200px;
    min-block-size: auto;
    min-height: auto;
    min-inline-size: auto;
    min-width: auto;
    perspective-origin: 600px 27.5px;
    text-align: center;
    justify-content: center;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    text-overflow: ellipsis;
    text-size-adjust: 100%;
    transform-origin: 600px 27.5px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    border: 0px none rgb(255, 255, 255);
    font: 700 40px / 51.4284px OpenSans, "Open sans", "Helvetica Neue", Helvetica, Arial, "Microsoft YaHei", "PingFang SC", Tahoma;
    outline: rgb(255, 255, 255) none 0px;
    overflow: hidden;
    padding-top: 50px;
 
}
.school_page_sub_heading{
  color: rgb(255, 255, 255);
  font: 600 20px / 30px OpenSans, "Open sans", "Helvetica Neue", Helvetica, Arial, "Microsoft YaHei", "PingFang SC", Tahoma;
}
.page-name-corporate
{
    text-align: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F23.png?alt=media&token=37ef7b5d-3252-4b68-9bd7-265905457fc7&_gl=1*vtbbkp*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzQ4NTA5My4zOC4xLjE2OTc0OTI3MzYuNC4wLjA.");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% ;
    color: #f57224;
    height: 240px;

}
.page-name-hospital
{
    text-align: center;
    
    background-image: url("https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F6.png?alt=media&token=6bcb190a-eb8c-4ca1-83a8-833e1a094158&_gl=1*jt9dso*_ga*NTExNDU2NjM0LjE2OTU5NzE0MDY.*_ga_CW55HF8NVT*MTY5NzQ4NTA5My4zOC4xLjE2OTc0OTIzMDEuNjAuMC4w");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% ;

    color: #f57224;
    height: 240px;

}
.product-bg
{
    background-color: white;
    height: auto;
    
}

@media(max-width: 768px) {
    h1 {
        text-align: center;
        padding: 10px;
    }
}

.products-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-wrap: wrap;
    text-decoration: none;
    
}

.products-container .product-card {
    width: 300px;
    height: 500px;
    margin-right: 15px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    box-shadow: 5px 5px 4px #e4e4e4;
    position: relative;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    text-decoration: none;
    transition: all 250ms ease-in-out;
    &:hover {
        box-shadow: 0 4px 1rem -4px #000;
        transform: translateY(-3px);
      }
}
.products-container .product-card-link{
text-decoration: none;
}
.products-container .product-card .product-img {
    width: 100%;
    height: 300px;
    padding: 5px;
    border-radius: 10px;
    

}

.products-container .product-card .product-img img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
}

.products-container .product-card .product-name {
    width: 100%;
    margin-top: 2px;
    margin-bottom: 2px;
    font-weight: 400;
    text-decoration: none;
    color: black;
    font-size: 15px;
    padding: 3px;
   


}

.products-container .product-card .product-price {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 20px;
    text-decoration: none;
    color: black;
    font-weight: bold;
    text-decoration: none;
}

.products-container .product-card .addcart-btn {
    background-color: #f57224;
    color: #fff;
    border: hidden;
    cursor: pointer;
    width: 100%;
    padding: 10px 14px;
    margin-top: auto;
    margin-bottom: 2px;
    outline: none;
    border-radius: 10px;
    box-sizing: border-box;
    
}
.products-container .product-card .product-purchases {
   /* align-items: center;
    margin-top: auto;
    margin-bottom: 5px;
    font-size: 10px;
    display: block;*/
    display: block;
    align-items: center;
    width: 100%;
    color: rgb(34, 34, 34);
    font-family: Arial;
    font-size: 15px;
    font-weight: lighter;
    margin-top: auto !important;
    margin-bottom: 0px !important;
    padding: 0 !important;
    text-decoration: none;
    box-sizing: border-box;

    
}
.products-container .product-card .min_order {
    /* align-items: center;
     margin-top: auto;
     margin-bottom: 5px;
     font-size: 10px;
     display: block;*/
     display: block;
     align-items: center;
     width: 100%;
     color: rgb(34, 34, 34);
     font-family: Arial;
     font-size: 15px;
     font-weight: lighter;
     margin-top: auto !important;
     margin-bottom: 0px !important;
     padding: 0 !important;
     text-decoration: none;
     box-sizing: border-box;
 
     
 }
.products-container .product-card .product-rating {
    /* align-items: center;
     margin-top: auto;
     margin-bottom: 5px;
     font-size: 10px;
     display: block;*/
     display: block;
     align-items: center;
     width: 100%;
     color: rgb(34, 34, 34);
     font-family: Arial;
     font-size: 15px;
     font-weight: lighter;
     margin-top: auto !important;
     margin-bottom: 0px !important;
     padding: 0 !important;
     text-decoration: none;
     box-sizing: border-box;
 
     
 }




/* Added for sliding container
*/

/* ... Existing CSS ... */

/* Add this CSS class for circular product container */


  
.product-slides {
    /* Add styles for the product slides container */
    display: flex;
    transition: transform 0.3s ease; /* Smooth sliding transition */
    flex-direction: row;
  }
  

/* Add CSS for the "Prev" and "Next" buttons */
.prev-button,
.next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #f57224;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 3;
}

.prev-button {
    left: 10px;
}

.next-button {
    right: 10px;
}
.show_more{
    position: absolute;
  top: 0;
  right: 0;
  
}
/* ... Remaining CSS ... */

.carousel-container_1{
    z-index: 2;
    align-items: center;

}

.slider_display {
    background-color: #fff;
    height: 300px;
    width: 1200px;
    margin: 0 auto 100px;
    top: 100px;
    z-index: 2;
    position: relative;
    overflow: hidden;
    
}
.slider_display img{
    height: 300px;
    border-radius: 10px;
    width: 1200px;
    align-items: center;
    justify-content: center;
    
}
.product_page_heading{
    align-items: center;
    font-size: 30px;
    font-weight: 800;
    font-family: Arial, Helvetica, sans-serif;
    justify-content: center;
    text-align: center;
    padding: 25px;
    height: 100px;

}
.product_page_heading_display{
    display: flex;
    flex-direction: row;
    height: 200px;
    width: 100%;
    object-fit: contain;
    background-color: white;
}
.heading_display_left{
    height: 100%;
    flex-basis: 30%;
    justify-content: contain;
    object-fit: contain;

}
.heading_display_left img{
    height: 100%;
    width: 100%;
    object-fit: fill;
    align-items: center;

}
.heading_display_right{
    height: 100%;
    flex-basis: 30%;
    justify-content: contain;
    object-fit: contain;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;

}
.heading_display_right img{
    height: 100%;
    width: 100%;
    justify-content: contain;
    object-fit: fill;
   
}
.heading_display_cards {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    flex-basis: 40%;
    justify-content: space-around;
}
.heading_page_card_display{
    display: flex;
    flex-direction: column;
    flex-basis: 33%;
    height: 100%;
    border-radius: 10px;
    align-items: center;


}

.heading_page_card_display img {
    height:250px;
    width: 100%;
    flex-direction: column;
    padding: 0.5rem;
    border-radius: 10%;
    align-items: center;
    object-fit: fill;

}
.heading_page_card_display_right{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    flex-basis: 33%;
    height: 100%;
    align-items: center;
    padding-right: 0.5rem;
}
.heading_display_right_txt {
    margin-top: 10px;
    padding-top: 10px;
    align-items: center;
    text-align: center;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    flex-basis: 40%;

}

.product_page_heading_display_image {
  flex-basis: 70%;
  object-fit: fill;
  width: 100%;
  overflow: hidden;
}

.product_page_heading_display_image img {
  height: 100%;
  
  width: 100%;
  object-fit: fill;
}
.product_page_heading_display_txt
{
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-basis: 30%;
  display: flex;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 15px;

}




.heading_display_right_contact_us_bg{
    box-sizing: border-box;
    background-color: #f57224;
    width:80px;
    align-items: center;
    height: 80px;
    text-decoration: none;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 80px;
    padding-left: 80px;
    border-radius: 20px;
    align-self: center;
    margin-top: 100px;
   
}
.heading_display_right_contact_us{
    margin-top: 100px;
    text-decoration: none;
    font-size: 30px;
    font-weight: 500;
    color: white;
    
}
.heading_page_card_display_right img {
    height:250px;
    width: 100%;
    flex-direction: column;
    padding: 0.5rem;
    border-radius: 10%;
    align-items: center;
    object-fit: fill;

}
.heading_page_card_display_left{
    display: flex;
    height: 100%;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    padding-left: 0.5rem;
    flex-basis: 33%;
    padding-top: 0;
}
.heading_page_card_display_left img {
    height:250px;
    width: 100%;
    flex-direction: column;
    padding: 0.5rem;
    border-radius: 10%;
    align-items: center;
    object-fit: fill;
    

}
.page_header_links{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;
    background-color: white;
}
.pade_header_page_link_left{
    display: flex;
    flex-direction: row;
    flex-basis: 40%;
    align-items: center;
    text-align: center;

}

.page_header_about_us{
    text-align: center;
    align-items: center;
    flex-basis: 33%;
}
.page_header_writing_customization{
    text-align: center;
    align-items: center;
    flex-basis: 33%;
}
.page_header_shipping_options{
    text-align: center;
    align-items: center;
    flex-basis: 33%;
}
.page_header_blank_space{
    flex-basis: 40%;
}
.pade_header_page_link_right{
    display: flex;
    flex-direction: row;
    align-items: center;

    flex-basis: 10%;
}
.page_header_contact{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-basis: 50%;

}
.page_header_help{
    flex-basis: 50%;
    align-items: center;
    text-align: center;

}
.supplies_display_add{
    width: 100%;
    height: 70px;
    margin-top: 30px;

}
.supplies_display_add img{
    width: 100%;
    height: 70px;
    align-items: center;
    object-fit: fill;
    margin-top: 10px;
    
}










.institute_page_left_section
{
  flex-basis: 20%;
}

.insitute_page_left_div {
    justify-content: center;
    position: relative;
    margin-left: auto;
    display: flex;
    margin-right: 0;
    width: 216px;
    flex-direction: column;
  }
  .insitute_page_left_div-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px 8px;
  }
  .insitute_page_left_div-3 {
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
    padding: 0 20px;
  }
  .insitute_page_left_div-4 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
  .insitute_page_left_img {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
  }
  .insitute_page_left_div-5 {
    color: var(--colors-gray-500, #64748b);
    align-self: stretch;
    white-space: nowrap;
    font: 400 16px/150% Nunito Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .insitute_page_left_div-5 {
      white-space: initial;
    }
  }
  .insitute_page_left_img-2 {
    aspect-ratio: 1.67;
    object-fit: contain;
    object-position: center;
    width: 10px;
    overflow: hidden;
    align-self: center;
    max-width: 100%;
    margin: auto 0;
  }
  .insitute_page_left_div-6 {
    align-items: flex-start;
    align-self: center;
    display: flex;
    width: 83px;
    max-width: 100%;
    flex-direction: column;
    margin: 14px 0 0 -26px;
    padding: 0 20px;
  }
  .insitute_page_left_div-7 {
    color: var(--colors-gray-500, #64748b);
    align-self: start;
    white-space: nowrap;
    font: 400 14px/143% Nunito Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .insitute_page_left_div-7 {
      white-space: initial;
    }
  }
  .insitute_page_left_div-8 {
    color: var(--colors-gray-500, #64748b);
    align-self: start;
    margin-top: 12px;
    white-space: nowrap;
    font: 400 14px/143% Nunito Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .insitute_page_left_div-8 {
      white-space: initial;
    }
  }
  .insitute_page_left_div-9 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    margin-top: 24px;
    flex-grow: 1;
    flex-direction: column;
    padding: 5px 8px;
  }
  .insitute_page_left_div-10 {
    align-items: flex-start;
    align-self: start;
    border-radius: 4px;
    display: flex;
    width: 200px;
    max-width: 100%;
    justify-content: space-between;
    gap: 20px;
    padding: 0 20px;
  }
  .insitute_page_left_div-11 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
  .insitute_page_left_div-12 {
    color: var(--colors-gray-500, #64748b);
    align-self: stretch;
    white-space: nowrap;
    font: 400 16px/150% Nunito Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .insitute_page_left_div-12 {
      white-space: initial;
    }
  }
  .insitute_page_left_img-3 {
    aspect-ratio: 1.67;
    object-fit: contain;
    object-position: center;
    width: 10px;
    overflow: hidden;
    align-self: center;
    max-width: 100%;
    margin: auto 0;
  }
  .insitute_page_left_div-13 {
    align-items: flex-start;
    align-self: start;
    display: flex;
    width: 68px;
    max-width: 100%;
    flex-direction: column;
    margin: 14px 0 0 32px;
    padding: 0 20px;
  }
  @media (max-width: 991px) {
    .insitute_page_left_div-13 {
      margin-left: 10px;
    }
  }
  .insitute_page_left_div-14 {
    color: var(--colors-gray-500, #64748b);
    align-self: start;
    white-space: nowrap;
    font: 400 14px/143% Nunito Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .insitute_page_left_div-14 {
      white-space: initial;
    }
  }
  .insitute_page_left_div-15 {
    color: var(--colors-gray-500, #64748b);
    align-self: start;
    margin-top: 12px;
    white-space: nowrap;
    font: 400 14px/143% Nunito Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .insitute_page_left_div-15 {
      white-space: initial;
    }
  }
  .insitute_page_left_div-16 {
    color: var(--colors-gray-500, #64748b);
    align-self: start;
    margin-top: 12px;
    white-space: nowrap;
    font: 400 14px/143% Nunito Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .insitute_page_left_div-16 {
      white-space: initial;
    }
  }
  .insitute_page_left_div-17 {
    color: var(--colors-gray-500, #64748b);
    align-self: start;
    margin-top: 12px;
    white-space: nowrap;
    font: 400 14px/143% Nunito Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .insitute_page_left_div-17 {
      white-space: initial;
    }
  }
  .insitute_page_left_div-18 {
    color: var(--colors-gray-500, #64748b);
    align-self: start;
    margin-top: 12px;
    white-space: nowrap;
    font: 400 14px/143% Nunito Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .insitute_page_left_div-18 {
      white-space: initial;
    }
  }
  .insitute_page_left_div-19 {
    color: var(--colors-gray-500, #64748b);
    align-self: start;
    margin-top: 12px;
    white-space: nowrap;
    font: 400 14px/143% Nunito Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .insitute_page_left_div-19 {
      white-space: initial;
    }
  }
  .insitute_page_left_div-20 {
    color: var(--colors-primary-500, #0ea5e9);
    align-self: start;
    margin-top: 12px;
    white-space: nowrap;
    font: 800 14px/143% Nunito Sans, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .insitute_page_left_div-20 {
      white-space: initial;
    }
  }





.institute_page_right_section
{
  flex-basis: 20%;
}
  .insitute_page_custom_div {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    display: flex;
    min-height: 420px;
    padding: 0 20px;
    width: 350px;
    border-radius:0.5rem;
  }
  .insitute_page_custom_img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius:0.5rem;
  }
  .insitute_page_custom_div-2 {
    position: relative;
    align-self: stretch;
    margin: 30px 11px 44px 20px;
  }
  @media (max-width: 991px) {
    .insitute_page_custom_div-2 {
      max-width: 100%;
      margin: 0 10px 40px 0;
    }
  }
  .insitute_page_custom_div-3 {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .insitute_page_custom_div-3 {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  .insitute_page_custom_column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 100%;
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    .insitute_page_custom_column {
      width: 100%;
    }
  }
  .insitute_page_custom_div-4 {
    position: relative;
    display: flex;
    margin-top: 18px;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .insitute_page_custom_div-4 {
      margin-top: 40px;
    }
  }
  .insitute_page_custom_div-5 {
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    letter-spacing: -0.2px;
    align-self: start;
    width: 440px;
    max-width: 100%;
    font: 600 32px Inter, sans-serif;
  }
  .insitute_page_custom_div-6 {
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    letter-spacing: -0.2px;
    margin-top: 12px;
    font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .insitute_page_custom_column-2 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 100%;
    background:  #fff;
    border-radius:0.5rem;
    
  }
  @media (max-width: 991px) {
    .insitute_page_custom_column-2 {
      width: 100%;
    }
  }
  .insitute_page_custom_div-7 {
    display: flex;
    flex-direction: column;
    fill: #fff;
    filter: drop-shadow(0px 1px 2px rgba(56, 56, 56, 0.25));
    overflow: hidden;
    position: relative;
    display: flex;
    min-height: 346px;
    flex-grow: 1;
    padding: 27px 20px;
    border-radius:0.5rem;
  }
  @media (max-width: 991px) {
    .insitute_page_custom_div-7 {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  .insitute_page_custom_div-8 {
    position: relative;
    color: #1c1c1c;

    letter-spacing: -0.2px;
    align-self: start;
    width: 332px;
    max-width: 100%;
    font: 600 20px/140% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .insitute_page_custom_div-9 {
    position: relative;
    align-self: start;
    display: flex;
    margin-top: 21px;
    width: 440px;
    max-width: 100%;
    flex-direction: column;
    border: 1px solid rgb(218, 220, 221); /* Set initial border color to gray */
  }
  .insitute_page_custom_div-10 {
    display: flex;
    flex-direction: column;
    color: #1c1c1c;
    align-self: stretch;
    position: relative;
    white-space: nowrap;
    fill: #fff;
  

    overflow: hidden;
    min-height: 40px;
    flex-grow: 1;
    padding: 13px 20px 13px 10px;
    font: 400 16px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .insitute_page_custom_div-10 {
      white-space: initial;
      max-width: 100%;
    }
  }
  .insitute_page_custom_div-11 {
    position: relative;
  }
  .insitute_page_custom_div-12 {
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid rgb(218, 220, 221); /* Set initial border color to gray */
    fill: #fff;
    stroke-width: 1px;
    stroke: var(--gray-300, #dee2e7);
    overflow: hidden;
    align-self: start;
    display: flex;
    min-height: 73px;
    margin-top: 20px;
    width: 440px;
    max-width: 100%;
    padding: 14px 7px 6px 11px;
  }
  .insitute_page_custom_div-13 {
    position: relative;
    color: var(--gray-500, #8b96a5);
    font: 400 16px Inter, sans-serif;
  }
  .insitute_page_custom_img-2 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 8px;
    fill: #e4e4e4;
    overflow: hidden;
    align-self: end;
    margin-top: 30px;
    max-width: 100%;
  }
  .insitute_page_custom_div-14 {
    position: relative;
    align-self: start;
    display: flex;
    margin-top: 20px;
    width: 325px;
    max-width: 100%;
    align-items: flex-start;
    border: 1px solid rgb(218, 220, 221); /* Set initial border color to gray */

  }
  .insitute_page_custom_div-15 {
    align-self: stretch;
    display: flex;
    align-items: center;


  }
  .insitute_page_custom_div-16 {
    display: flex;
    flex-direction: column;
    color: var(--dark, #1c1c1c);
    align-self: stretch;
    position: relative;
    white-space: nowrap;
    fill: #fff;
    stroke-width: 1px;
    stroke: var(--gray-300, #dee2e7);
    overflow: hidden;
    aspect-ratio: 5.15;
    flex-grow: 1;

    font: 400 16px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .insitute_page_custom_div-16 {
      white-space: initial;
    }
  }
  .insitute_page_custom_div-17 {
    position: relative;
  }
  .insitute_page_custom_img-3 {
    aspect-ratio: 2.78;
    object-fit: contain;
    object-position: center;
    width: 111px;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
  }
  .insitute_page_custom_div-18 {
    position: relative;
    color: black;
    text-align: center;
    background: linear-gradient(
      95deg,
      #2c7cf1 7.19%,
      rgba(0, 209, 255, 0.5) 89.49%
    );
    font-feature-settings: "clig" off, "liga" off;
    align-self: start;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: var(
      --primary-gradient,
      linear-gradient(180deg, #127fff 0%, #0067ff 100%)
    );
    margin-top: 20px;
    width: 128px;
    max-width: 100%;
    padding: 11px 16px;
    font: 500 16px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .insitute_page_custom_div-18 {
      white-space: initial;
      margin-left: 1px;
    }
  }
