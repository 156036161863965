
    .clothing_div {
      background-color: #fff;
      display: flex;
      flex-direction: column;
    }
    .clothing_div-2 {
      align-self: stretch;
      display: flex;
      margin-top: 38px;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .clothing_div-2 {
        max-width: 100%;
        margin-top: 0;
      }
    }
    .clothing_div-3 {
      align-self: center;
      display: flex;
      width: 100%;
      max-width: 993px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
      padding: 0 20px;
    }
    @media (max-width: 991px) {
      .clothing_div-3 {
        max-width: 100%;
        flex-wrap: wrap;
      }
    }
    .clothing_div-4 {
      color: #000;
      text-align: center;
      align-self: stretch;
      max-width: 1038px;
      flex-grow: 1;
      flex-basis: auto;
      font: 300 36px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-4 {
        max-width: 100%;
      }
    }
    .clothing_div-5 {
      align-self: stretch;
      display: flex;
      width: 185px;
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    @media (max-width: 991px) {
      .clothing_div-5 {
        justify-content: center;
      }
    }
    .img {
      aspect-ratio: 1;
      object-fit: cover;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .clothing_img-2 {
      aspect-ratio: 1;
      object-fit: cover;
      object-position: center;
      width: 100%;
      overflow: hidden;
      border-radius: 50%;
      flex: 1;
    }
    .clothing_div-6 {
      background-color: #d9d9d9;
      align-self: center;
      display: flex;
      margin-top: 27px;
      width: 100%;
      max-width: 1511px;
      flex-grow: 1;
      flex-direction: column;
      padding: 77px 20px 58px;
    }
    @media (max-width: 991px) {
      .clothing_div-6 {
        max-width: 100%;
      }
    }
    .clothing_div-7 {
      align-self: center;
      display: flex;
      width: 100%;
      max-width: 992px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    @media (max-width: 991px) {
      .clothing_div-7 {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .clothing_div-8 {
      color: #000;
      text-align: center;
      align-self: center;
      max-width: 383px;
      flex-grow: 1;
      flex-basis: auto;
      margin: auto 0;
      font: 600 36px Inter, sans-serif;
    }
    .clothing_div-9 {
      color: #000;
      text-align: center;
      align-self: start;
      max-width: 383px;
      flex-grow: 1;
      flex-basis: auto;
      font: 600 36px Inter, sans-serif;
    }
    .clothing_div-10 {
      color: #000;
      text-align: center;
      align-self: start;
      max-width: 383px;
      flex-grow: 1;
      flex-basis: auto;
      font: 600 36px Inter, sans-serif;
    }
    .clothing_div-11 {
      color: #000;
      text-align: center;
      align-self: start;
      max-width: 383px;
      flex-grow: 1;
      flex-basis: auto;
      font: 600 36px Inter, sans-serif;
    }
    .clothing_div-12 {
      align-self: center;
      display: flex;
    
      width: 100%;
      max-width: 1395px;
      flex-direction: column;
      padding: 20px 20px 0  20px;

    }
    @media (max-width: 991px) {
      .clothing_div-12 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .clothing_div-13 {
      color: #000;
      text-align: center;
      align-self: start;
      max-width: 800px;
      font: 600 30px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-13 {
        max-width: 100%;
      }
    }
    .clothing_div-14 {
      align-self: center;
      display: flex;
      width: 100%;
      height: 60px;
      align-items: stretch; /* Change to stretch to make all items equal height */
      justify-content: space-between; /* Equally space the items */
    
      
      margin: 30px 20px 0 20px;
    }
    @media (max-width: 991px) {
      .clothing_div-14 {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 40px;
        height: auto;
        gap:5px;
      }
    }
    .clothing_img-3 {
      aspect-ratio: 0.87;
      object-fit: cover;
      object-position: center;
      width: 99px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .clothing_div-15 {
      color: #000;
      height: 80px;
      border-radius: 10px;
      background-color: #d9d9d9;
      width: 300px;
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center; /* Center vertically */
      text-align: center; /* Center horizontally */
      padding: 15px;
      font: 600 25px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-15 {
        
        width: 300px;
      }
    }
    @media (max-width: 640px) {
      .clothing_div-15 {
        width: 300px;
      }
    }
    .clothing_div-16 {
      color: #000;
      height: 80px;
      border-radius: 10px;
      background-color: #d9d9d9;
      width: 300px;
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center; /* Center vertically */
      text-align: center; /* Center horizontally */
      padding: 15px;
      font: 600 25px Inter, sans-serif;
    }
    @media (max-width: 640px) {
      .clothing_div-16 {
        width: 300px;
      }
    }
    .clothing_div-17 {
      color: #000;
      height: 80px;
      border-radius: 10px;
      background-color: #d9d9d9;
      width: 300px;
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center; /* Center vertically */
      text-align: center; /* Center horizontally */
      padding: 15px;
      font: 600 25px Inter, sans-serif;
    }
    .clothing_div-18 {
      color: #000;
      height: 80px;
      border-radius: 10px;
      background-color: #d9d9d9;
      width: 300px;
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center; /* Center vertically */
      text-align: center; /* Center horizontally */
      padding: 15px;
      font: 600 25px Inter, sans-serif;
    }
    .clothing_div-19 {
      align-self: center;
      display: flex;
      margin-top: 100px;
      width: 100%;
      max-width: 1387px;
      flex-direction: column;
      padding: 0 20px;
    }
    @media (max-width: 991px) {
      .clothing_div-19 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .clothing_div-20 {
      align-self: center;
      width: 100%;
      max-width: 1376px;
      padding: 0 20px;
    }
    @media (max-width: 991px) {
      .clothing_div-20 {
        max-width: 100%;
      }
    }
    .clothing_div-21 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .clothing_div-21 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .clothing_column {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 50%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .clothing_column {
        width: 100%;
        margin-left: auto;
      }
    }
    .clothing_div-22 {
      display: flex;
      margin-top: 246px;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .clothing_div-22 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .clothing_div-23 {
      align-self: start;
      width: 575px;
      max-width: 100%;
    }
    .clothing_div-24 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .clothing_div-24 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .column-2 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 54%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .column-2 {
        width: 100%;
      }
    }
    .clothing_div-25 {
      display: flex;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .clothing_div-25 {
        margin-top: 40px;
        align-items:center;
      }
    }
    .clothing_img-4 {
      aspect-ratio: 1.04;
      object-fit: cover;
      object-position: center;
      width: 117px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    @media (max-width: 991px) {
      .clothing_img-4 {
        align-self: center;
      }
    }
    .clothing_div-26 {
      color: #000;
      margin: 34px 0 0 15px;
      font: 600 27px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-26 {
        margin-left: 0;
      }
    }
    .clothing_div-27 {
      color: #7a7a7a;
      align-self: start;
      margin-top: 75px;
      font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-27 {
        margin: 10px 0 0 0;
        align-self: center;
        text-align:center;
      }
    }
    .column-3 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 46%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .column-3 {
        width: 100%;
        margin-left: 0;
      }
    }
    .clothing_div-28 {
      display: flex;
      margin-top: 18px;
      flex-grow: 1;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .clothing_div-28 {
        margin-top: 40px;
      }
    }
    .clothing_img-5 {
      aspect-ratio: 1.43;
      object-fit: cover;
      object-position: center;
      width: 110px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    @media (max-width: 991px) {
      .clothing_img-5 {
        align-self:center;
      }
    }
    .clothing_div-29 {
      color: #000;
      margin-top: 38px;
      font: 600 27px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-29 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-30 {
      color: #7a7a7a;
      align-self: start;
      margin-top: 45px;
      font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-30 {
        margin-top: 10px;
        align-self: center;

        text-align:center;
      }
    }
    .clothing_div-31 {
      align-self: start;
      margin-top: 1px;
      width: 602px;
      max-width: 100%;
    }
    @media (max-width: 991px) {
      .clothing_div-31 {
        margin-top: 40px;
      }
    }
    .clothing_div-32 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .clothing_div-32 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .column-4 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 52%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .column-4 {
        width: 100%;
      }
    }
    .clothing_div-33 {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .clothing_div-33 {
        margin-top: 40px;
      }
    }
    .clothing_img-6 {
      aspect-ratio: 1.06;
      object-fit: cover;
      object-position: center;
      width: 166px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    @media (max-width: 991px) {
      .clothing_img-6  {
        align-self: center;
      }
    }



    .clothing_div-34 {
      color: #000;
      margin: 21px 0 0 21px;
      font: 600 27px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-34 {
        margin:auto;
      }
    }
    .clothing_div-35 {
      color: #7a7a7a;
      align-self: end;
      margin-top: 27px;
      font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
    }

    @media (max-width: 991px) {
      .clothing_div-35 {
        margin:auto;
        align-self: center;
        text-align:center;
        
      }
    }
    .column-5 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 48%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .column-5 {
        width: 100%;
        margin-left: 0;
      }
    }
    .clothing_div-36 {
      display: flex;
      margin-top: 13px;
      flex-grow: 1;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .clothing_div-36 {
        margin-top: 40px;
      }
    }
    .clothing_img-7 {
      aspect-ratio: 1.12;
      object-fit: cover;
      object-position: center;
      width: 147px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    @media (max-width: 991px) {
      .clothing_img-7 {
        align-self: center;
      }
    }
    .clothing_div-37 {
      color: #000;
      margin: 33px 0 0 14px;
      font: 600 27px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-37 {
        margin:auto;
      }
    }
    .clothing_div-38 {
      color: #7a7a7a;
      align-self: start;
      margin: 26px 0 0 14px;
      font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-38 {
        margin:auto;
      }
    }
    .clothing_column-6 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 100%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .clothing_column-6 {
        margin-left: 0;
      }
    }
    .clothing_img-8 {
      aspect-ratio: 0.69;
      object-fit: cover;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex-grow: 1;
    }
    @media (max-width: 991px) {
      .clothing_img-8 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .clothing_div-39 {
      align-self: center;
     
      width: 100%;
      max-width: 100%;
      padding: 0 20px;
    }
    @media (max-width: 991px) {
      .clothing_div-39 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .clothing_div-40 {
     
      display: flex;
    }
    @media (max-width: 991px) {
      .clothing_div-40 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .column-7 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 49%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .column-7 {
        width: 100%;
      }
    }
    .clothing_div-41 {
      margin: auto 0;
    }
    @media (max-width: 991px) {
      .clothing_div-41 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .clothing_div-42 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .clothing_div-42 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .column-8 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 55%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .column-8 {
        width: 100%;
      }
    }
    .clothing_div-43 {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .clothing_div-43 {
        margin-top: 40px;
      }
    }
    .clothing_img-9 {
      aspect-ratio: 1.31;
      object-fit: cover;
      object-position: center;
      width: 152px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    .clothing_div-44 {
      color: #000;
      margin-top: 9px;
      font: 600 27px Inter, sans-serif;
    }
    .clothing_div-45 {
      color: #7a7a7a;
      align-self: start;
      margin-top: 25px;
      font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .column-9 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 45%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .column-9 {
        width: 100%;
      }
    }
    .clothing_div-46 {
      display: flex;
      flex-direction: column;
      margin: auto 0;
    }
    @media (max-width: 991px) {
      .clothing_div-46 {
        margin-top: 40px;
      }
    }
    .clothing_img-10 {
      aspect-ratio: 1.25;
      object-fit: cover;
      object-position: center;
      width: 125px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    .clothing_div-47 {
      color: #000;
      margin: 9px 0 0 14px;
      font: 600 27px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-47 {
        margin-left: 10px;
      }
    }
    .clothing_div-48 {
      color: #7a7a7a;
      align-self: start;
      margin: 19px 0 0 14px;
      font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-48 {
        margin-left: 10px;
      }
    }
    .column-10 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 24%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .column-10 {
        width: 100%;
        margin-left: 0;
      }
    }
    .clothing_div-49 {
      display: flex;
      flex-grow: 1;
      align-items: flex-start;
      justify-content: space-between;
      gap: 9px;
    }
    @media (max-width: 991px) {
      .clothing_div-49 {
        margin-top: 40px;
      }
    }
    .clothing_div-50 {
      background-color: #000;
      align-self: stretch;
      width: 1px;
      height: 467px;
      flex-grow: 1;
      flex-basis: auto;
    }
    .clothing_div-51 {
      align-self: center;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: auto;
      margin: auto 0;
    }
    .clothing_img-11 {
      aspect-ratio: 1.4;
      object-fit: cover;
      object-position: center;
      width: 162px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    @media (max-width: 991px) {
      .clothing_img-11 {
        align-self: center;
      }
    }
    .clothing_div-52 {
      color: #000;
      margin: 8px 0 0 0px;
      font: 600 27px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-52 {
        align-self: center;
        margin:auto;
      }
    }
    .clothing_div-53 {
      color: #7a7a7a;
      align-self: end;
      margin-top: 10px;
      font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-53 {
        align-self: center;
        text-align:center;
      }
    }
    .column-11 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 27%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .column-11 {
        width: 100%;
        margin-left: 0;
      }
    }
    .clothing_div-54 {
      display: flex;
      flex-grow: 1;
      align-items: flex-start;
      justify-content: space-between;
      gap: 9px;
    }
    @media (max-width: 991px) {
      .clothing_div-54 {
        margin-top: 40px;
      }
    }
    .clothing_div-55 {
      background-color: #000;
      align-self: stretch;
      width: 1px;
      height: 467px;
      
      flex-basis: auto;
    }
    @media (max-width: 991px) {
      .clothing_div-55 {
        display:none;
      }
    }
    .clothing_div-56 {
      align-self: center;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: auto;
      margin: auto 0;
    }
    .clothing_img-12 {
      aspect-ratio: 1.42;
      object-fit: cover;
      object-position: center;
      width: 179px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    @media (max-width: 991px) {
      .clothing_img-12 {
        align-self: center;
      }
    }
    .clothing_div-57 {
      color: #000;
      margin: 18px 0 0 24px;
      font: 600 27px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-57 {
        margin:auto;
      }
    }
    .clothing_div-58 {
      color: #7a7a7a;
      align-self: end;
      margin-top: 6px;
      max-width: 290px;
      font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-58 {
        text-align:center;
        align-self:center;
      }
    }
    .clothing_div-59 {
      align-self: start;

      width: 632px;
      max-width: 100%;
      padding: 0 20px;
    }
    @media (max-width: 991px) {
      .clothing_div-59 {
        margin-top: 40px;
      }
    }
    .clothing_div-60 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .clothing_div-60 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .column-12 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 53%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .column-12 {
        width: 100%;
      }
    }
    .clothing_div-61 {
      display: flex;
      flex-direction: column;
      margin: auto 0;
    }
    @media (max-width: 991px) {
      .clothing_div-61 {
        margin-top: 40px;
      }
    }
    .clothing_img-13 {
      aspect-ratio: 1.04;
      object-fit: cover;
      object-position: center;
      width: 132px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    @media (max-width: 991px) {
      .clothing_img-13 {
        align-self: center;
    
      }
    }
    .clothing_div-62 {
      color: #000;
      margin: 7px 0 0 24px;
      font: 600 27px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-62 {
        margin:auto;
      }
    }
    .clothing_div-63 {
      color: #7a7a7a;
      align-self: end;
      margin-top: 62px;
      max-width: 290px;
      font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-63 {
        margin-top: 40px;
        align-self: center;
        text-align:center;
      }
    }
    .column-13 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 47%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .column-13 {
        width: 100%;
        margin-left: 0;
      }
    }
    .clothing_div-64 {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .clothing_div-64 {
        margin-top: 40px;
      }
    }
    .clothing_img-14 {
      aspect-ratio: 0.98;
      object-fit: cover;
      object-position: center;
      width: 162px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    @media (max-width: 991px) {
      .clothing_img-14 {
        align-self: center;
      }
    }
    .clothing_div-65 {
      color: #000;
      align-self: start;
      margin-top: 19px;
      max-width: 245px;
      font: 600 27px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-65 {
        align-self: center;
        text-align:center;
      }
    } 
    .clothing_div-66 {
      color: #7a7a7a;
      align-self: start;
      margin-top: 33px;
      font: 400 21px/142.857% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
    }       
     @media (max-width: 991px) {
      .clothing_div-66 {
        align-self: center;
        text-align:center;
      }
    } 
    .clothing_div-67 {
      align-self: center;
      display: flex;
      margin-top: 50px;
      width: 100%;
      max-width: 1115px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
      padding: 0 20px;
    }
    @media (max-width: 991px) {
      .clothing_div-67 {
        max-width: 100%;
        margin-top: 40px;
        flex-wrap: wrap;
      }
    }
    .clothing_div-68 {
      color: #000;
      align-self: start;
      margin-top: 7px;
      max-width: 1177px;
      flex-grow: 1;
      flex-basis: auto;
      font: 600 32px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-68 {
        max-width: 100%;
        align-self: start;
        text-align:center;
      }
    }
    .clothing_img-15 {
      aspect-ratio: 1;
      object-fit: cover;
      object-position: center;
      width: 39px;
      fill: #fff;
      stroke-width: 3px;
      stroke: #000;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    @media (max-width: 991px) {
      .clothing_img-15 {
    
        align-self: center;
        justify-content:center;
      }
    }
    .clothing_div-69 {
      background-color: #f3f2f0;
      align-self: stretch;
      display: flex;
      margin-top: 221px;
      width: 100%;
      flex-direction: column;
      padding: 75px 20px 187px;
    }
    @media (max-width: 991px) {
      .clothing_div-69 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .clothing_div-70 {
      align-self: center;
      width: 100%;
      max-width: 1176px;
    }
    @media (max-width: 991px) {
      .clothing_div-70 {
        max-width: 100%;
      }
    }
    .clothing_div-71 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .clothing_div-71 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .column-14 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 50%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .column-14 {
        width: 100%;
      }
    }

    @media (max-width: 991px) {
      .clothing_div-72 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .clothing_div-73 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .clothing_div-73 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .column-15 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 47%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .column-15 {
        width: 100%;
      }
    }
    .clothing_div-74 {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .clothing_div-74 {
        margin-top: 40px;
        align-items:center;
      }
    }
    .clothing_div-75 {
      color: #000;
      align-self: start;
      width: 242px;
      font: 600 28px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-75 {
        align-self: center;
        align-items:center;
        text-align:center;
      }
    }
    .clothing_div-76 {
      color: #000;
      align-self: start;
      margin-top: 41px;
      width: 242px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-76 {
        margin-top: 40px;
        align-self: center;
        text-align:center;
      }
    }
    .clothing_div-77 {
      color: #000;
      align-self: start;
      margin-top: 32px;
      width: 242px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-77 {
      
        align-self: center;
        text-align:center;
      }
    }
    .clothing_div-78 {
      color: #000;
      align-self: start;
      margin-top: 32px;
      width: 242px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-78 {
      
        align-self: center;
        text-align:center;
      }
    }
    .clothing_div-79 {
      color: #000;
      align-self: start;
      margin-top: 33px;
      width: 242px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-79 {
      
        align-self: center;
        text-align:center;
      }
    }
    .clothing_div-80 {
      color: #000;
      margin-top: 33px;
      font: 300 24px Inter, sans-serif;
    }
    .clothing_div-81 {
      color: #000;
      align-self: start;
      margin-top: 32px;
      width: 242px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-81 {
      
        align-self: center;
        text-align:center;
      }
    }
    .clothing_div-82 {
      color: #000;
      align-self: start;
      margin-top: 32px;
      width: 242px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-82 {
      
        align-self: center;
        text-align:center;
      }
    }
    .column-16 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 53%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .column-16 {
        width: 100%;
        margin-left: 0;
      }
    }
    .clothing_div-83 {
      display: flex;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .clothing_div-83 {
        margin-top: 40px;
      }
    }
    .clothing_div-84 {
      color: #000;
      align-self: start;
      width: 241px;
      font: 600 28px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-84 {
      
        align-self: center;
        text-align:center;
      }
    }
    .clothing_div-85 {
      color: #000;
      align-self: start;
      margin-top: 40px;
      width: 242px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-85 {
      
        align-self: center;
        text-align:center;
      }
    }
    .clothing_div-86 {
      color: #000;
      align-self: start;
      margin-top: 27px;
      width: 242px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-86 {
      
        align-self: center;
        text-align:center;
      }
    }
    .clothing_div-87 {
      color: #000;
      align-self: start;
      margin-top: 27px;
      width: 242px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-87 {
      
        align-self: center;
        text-align:center;
      }
    }
    .clothing_div-88 {
      color: #000;
      align-self: start;
      margin-top: 27px;
      text-wrap: nowrap;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-88 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-89 {
      color: #000;
      align-self: start;
      margin-top: 27px;
      width: 242px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-89 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-90 {
      color: #000;
      align-self: start;
      margin-top: 27px;
      width: 242px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-90 {
        align-self:center;
        text-align:center;
      }
    }
    .column-17 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 50%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .column-17 {
        width: 100%;
        margin-left:0;
      }
    }
    .clothing_div-91 {
      flex-grow: 1;
    }
    @media (max-width: 991px) {
      .clothing_div-91 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .clothing_div-92 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .clothing_div-92 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .column-18 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 50%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .column-18 {
        width: 100%;
      }
    }
    .clothing_div-93 {
      display: flex;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .clothing_div-93 {
        margin-top: 40px;
      }
    }
    .clothing_div-94 {
      color: #000;
      align-self: start;
      width: 241px;
      font: 600 28px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-94 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-95 {
      color: #000;
      align-self: start;
      margin-top: 42px;
      width: 241px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-95 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-96 {
      color: #000;
      align-self: start;
      margin-top: 27px;
      width: 241px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-96 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-97 {
      color: #000;
      align-self: start;
      margin-top: 32px;
      width: 241px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-97 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-98 {
      color: #000;
      align-self: start;
      margin-top: 27px;
      width: 241px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-98 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-99 {
      color: #000;
      align-self: start;
      margin-top: 27px;
      width: 241px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-99 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-100 {
      color: #000;
      align-self: start;
      margin-top: 27px;
      width: 241px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-100 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-101 {
      color: #000;
      margin-top: 27px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-101 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-102 {
      color: #000;
      margin-top: 28px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-102 {
        align-self:center;
        text-align:center;
      }
    }
    .column-19 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 50%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .column-19 {
        width: 100%;
        margin-left:0;
      }
    }
    .clothing_div-103 {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .clothing_div-103 {
        margin-top: 40px;
      }
    }
    .clothing_div-104 {
      color: #000;
      align-self: start;
      width: 242px;
      font: 600 28px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-104 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-105 {
      color: #000;
      align-self: start;
      margin-top: 48px;
      width: 241px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-105 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-106 {
      color: #000;
      align-self: start;
      margin-top: 29px;
      width: 241px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-106 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-107 {
      color: #000;
      align-self: start;
      margin-top: 26px;
      width: 242px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-107 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-108 {
      color: #000;
      align-self: start;
      margin-top: 31px;
      width: 242px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-108 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-109 {
      color: #000;
      align-self: start;
      margin-top: 33px;
      width: 242px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-109 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-110 {
      color: #000;
      align-self: start;
      margin-top: 27px;
      width: 242px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-110 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-111 {
      color: #000;
      align-self: start;
      margin-top: 32px;
      width: 242px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-111 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-112 {
      color: #000;
      align-self: start;
      margin-top: 33px;
      width: 242px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-112 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-113 {
      color: #000;
      align-self: start;
      margin-top: 26px;
      width: 242px;
      font: 300 24px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .clothing_div-113 {
        align-self:center;
        text-align:center;
      }
    }
    .clothing_div-114 {
      border-radius: 30px;
      background-color: rgba(0, 0, 0, 0.76);
      align-self: start;
      display: flex;
      margin-top: 50px;
      width: 194px;
      max-width: 100%;
      flex-direction: column;
      padding: 18px 20px 11px;
    }
    @media (max-width: 991px) {
      .clothing_div-114 {
        margin: 40px 0 0 0;
        align-self:center;
      }
    }
    .clothing_div-115 {
      color: #fff;
      align-self: center;
      width: 100%;
      font: 600 40px Inter, sans-serif;
    }
    .clothing_div-116 {
      background-color: #000;
      align-self: stretch;
      min-height: 1px;
      width: 100%;
      margin: 27px -20px -37px 0;
    }
    @media (max-width: 991px) {
      .clothing_div-116 {
        max-width: 100%;
        margin-bottom: 10px;
      }
    }
    .clothing_bottom_line {
      background-color: #000;
      align-self: center;
      width: 100% !important;
      height: 1px;
    }
    @media (max-width: 991px) {
      .clothing_bottom_line  {
        display:none;
      }
    }
  