
    .school_landing_div {
      background-color: #fff;
      display: flex;
      flex-direction: column; /* Stack sections vertically */
      align-items: center; /* Center items horizontally */
      justify-content: center; 
    }
    @media (max-width: 991px) {
      .school_landing_div {
        max-width: 100%;
      }
    }

      .arrow_next {
        position: absolute;
        right: 10px; /* Adjust the right offset as needed */
        transform: translateY(-50%);
        color: black;
        cursor: pointer;
        outline: none;
        border: none;
        top: 50%;
        font-size: 50px;
        background-color: transparent; /* Adjust background color if needed */
    
      }
      @media (max-width: 991px) {
        .arrow_next {
          top: 60%;
          font-size: 15px;
        }
      }
      .arrow_prev {
        left: 10px; /* Adjust the left offset as needed */
        transform: translateY(-50%);
        top: 50%;
        z-index: 1;
        color: black;
        position: absolute;
        cursor: pointer;
        outline: none;
        border: none;
        font-size: 50px;
        background-color: transparent; /* Adjust background color if needed */
      }
      @media (max-width: 991px) {
        .arrow_prev {
          font-size: 15px;
          top: 60%;
        }
      }
    .school_landing_div-2 {
      background-color: #53c3f4;
      align-self: start;
      display: flex;
      width: 100%;
      height: 60px;
      flex-direction: column;
      padding: 0 80px;
    }
    @media (max-width: 991px) {
      .school_landing_div-2 {
        max-width: 100%;
        padding: 0 20px;
      }
    }
    .section {
      /* Common styles for all sections */
      display: flex;
      flex-direction: column; /* Stack sections vertically */
      align-items: center; /* Center items horizontally */
      justify-content: center; 
    }
    
    .section.active {
      /* Styles for active sections */
      display: flex;
      flex-direction: column; /* Stack sections vertically */
      align-items: center; /* Center items horizontally */
      justify-content: center; 
    }
    
    .section.inactive {
      /* Styles for inactive sections */
      display: flex;
      flex-direction: column; /* Stack sections vertically */
      align-items: center; /* Center items horizontally */
      justify-content: center; 
    }
    .school_landing_div-3 {
      align-self: center;
      display: flex;
      width: 100%;
      height: 60px;
      max-width: 1150px;
      align-items: flex-start;
      gap: 16px;
    }
    @media (max-width: 991px) {
      .school_landing_div-3 {
        max-width: 100%;
        flex-wrap: wrap;
    
      }
    }
    .school_landing_div-4 {
      display: flex;
      flex-direction: column;
      fill: #00d0fe;
      overflow: hidden;
      align-self: stretch;
      position: relative;
      display: flex;
      min-height: 60px;
      width: 100%;
      max-width: 1004px;
      flex-grow: 1;
      flex-basis: auto;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    
    }
    @media (max-width: 991px) {
      .school_landing_div-4 {
        max-width: 100%;
        flex-wrap: wrap;
        padding-left: 0;
      }
    }
    .school_landing_img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
    .school_landing_div-5 {
      position: relative;
      align-self: stretch;
      display: flex;
      background: url('https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FVector%201.png?alt=media&token=dca70f4d-9f11-4483-b6fa-b2eca9df3021') center center/contain no-repeat; 
      width: 800px;
      max-width: 100%;
      height: 100%;
      align-items: flex-start;
      padding: 0 50px;
      justify-content: space-between;
      gap: 20px;
      margin: auto 0;
    }
    @media (max-width: 991px) {
      .school_landing_div-5 {
        flex-wrap: wrap;
        justify-content: center;
        width:100%;
        padding: 0;
        height:auto; 
        background-size:cover;
      }
    }
    .school_landing_div-6 {
      display: flex;
      flex-direction: column;
      color: #fe3e00;
      align-self: stretch;
      position: relative;
      overflow: hidden;
      aspect-ratio: 2.975;
      width: 119px;
      max-width: 100%;
      padding: 5px 31px 5px 4px;
      font: italic 800 16px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-6 {
        padding-right: 20px;
      }
    }
    .school_landing_div-7 {
      position: relative;
    }
    .school_landing_div-8 {
      color: #fff;
      margin: auto 0;
      display: flex;
      align-items: center;
      font: 700 26px Roboto, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-8 {
        font:500 1rem Roboto, sans-serif;
        padding:10px;
        height:100%;
        text-align: center;
      }
    }

    
    .school_landing_div-9 {
      color: #fff;
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin: auto 0;
      font: 400 24px Roboto, sans-serif;
    }
    
    @media (max-width: 991px) {
      .school_landing_div-9 {
        white-space: initial;
      }
    }
    .school_landing_div-10 {
      position: relative;
      border-radius: 50%;
      align-self: center;
      display: flex;
      width: 10px;
      height: 10px;
      flex-direction: column;
      margin: auto 0;
    }
    .ellipsis_school {
      display: -webkit-box;
      -webkit-line-clamp: 4; /* Number of lines to show */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .school_landing_div-11 {
      color: #fe3e00;
      align-self: center;
      white-space: nowrap;
      border-radius: 20px;
      background-color: #fff;
      width: 130px;
      max-width: 100%;
      margin:  0 auto;
      padding: 9px 27px;
      font: 600 16px Roboto, sans-serif;
      text-align: center;
    }
    @media (max-width: 991px) {
      .school_landing_div-11 {
      display:none;           
      }
    }
    .school_landing_div-12 {
      background-color: #f2f3f7;
      align-self: start;
      z-index: 1;
      display: flex;
      margin-top: -30px;
      width: 100%;
      flex-direction: column;
      padding: 30px 20px 60px;
    }
    @media (max-width: 991px) {
      .school_landing_div-12 {
        max-width: 100%;
        padding-bottom: 10px;
      }
    }
    .school_landing_div-13 {
      align-self: center;
      display: flex;
      width: 100%;
      max-width: 1448px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_div-13 {
        max-width: 100%;
        flex-wrap: wrap;
      }
    }
    .school_landing_img-2 {
      aspect-ratio: 2.52;
      object-fit: contain;
      object-position: center;
      width: 209px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    .school_landing_div-14 {
      align-self: start;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: auto;
    }
    @media (max-width: 991px) {
      .school_landing_div-14 {
        max-width: 100%;
      }
    }
    .school_landing_div-15 {
      align-self: start;
      padding:10px 0 0 0;
      display: flex;
      margin: 0 auto;
      width: 321px;
      max-width: 100%;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-15 {
        margin : 0 auto;
      }
    }
    .school_landing_div-16 {
      color: #000;
      align-self: center;
      font: 500 32px/102% Nobile, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_img-3 {
      aspect-ratio: 80.25;
      object-fit: contain;
      object-position: center;
      width: 100%;
      fill: #000;
      overflow: hidden;
      align-self: stretch;
      margin-top: 10px;
      flex-grow: 1;
    }
    .school_landing_div-17 {
      align-self: center;
      display: flex;
      margin-top: 15px;
      width: 100%;
      max-width: 1200px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_div-17 {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .school_landing_div-18 {
      border-radius: 20px;
      border: 1px solid #0175fe;
      align-self: stretch;
      display: flex;
      padding-left: 695px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 9px;
    }
    @media (max-width: 991px) {
      .school_landing_div-18 {
        max-width: 100%;
        flex-wrap: wrap;
        padding-left: 20px;
      }
    }
    .school_landing_img-4 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 24px;
      opacity: 0.5;
      overflow: hidden;
      align-self: center;
      max-width: 100%;
      margin: auto 0;
    }
    .school_landing_div-19 {
      border-radius: 0px 20px 20px 0px;
      background-color: #0075fe;
      align-self: stretch;
      display: flex;
      width: 140px;
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 9px;
      padding: 12px 38px 12px 36px;
    }
    @media (max-width: 991px) {
      .school_landing_div-19 {
        padding: 0 20px;
      }
    }
    .school_landing_img-5 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 15px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-20 {
      color: #fff;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 600 14px Roboto, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-20 {
        white-space: initial;
      }
    }
    .school_landing_img-6 {
      aspect-ratio: 0.73;
      object-fit: contain;
      object-position: center;
      width: 16px;
      overflow: hidden;
      align-self: center;
      max-width: 100%;
      margin: auto 0;
    }
    .school_landing_img-7 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 18px;
      overflow: hidden;
      align-self: start;
      margin-top: 5px;
      max-width: 100%;
    }
    .school_landing_img-8 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 20px;
      overflow: hidden;
      align-self: start;
      margin-top: 4px;
      max-width: 100%;
    }
    .school_landing_img-9 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 24px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    .school_landing_div-21 {
      background-color: rgba(214, 182, 182, 0.4);
      align-self: stretch;
      min-height: 1px;
      width: 100%;
      margin: 0 -20px 0 0;
    }
    @media (max-width: 991px) {
      .school_landing_div-21 {
        max-width: 100%;
      }
    }
    .school_landing_div-22 {
      align-self: center;
      display: flex;
      margin-top: 4px;
      width: 100%;
      max-width: 1448px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_div-22 {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .school_landing_img-10 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 24px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    .school_landing_div-23 {
      align-self: start;
      display: flex;
      margin-top: 6px;
      align-items: flex-start;
      gap: 10px;
    }
    .school_landing_img-11 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_div-24 {
      opacity: 0.5;
      background-color: rgba(0, 0, 0, 0.6);
      height: 15px;
      flex: 1;
    }
    .school_landing_div-25 {
      align-self: start;
      display: flex;
      margin-top: 6px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 11px;
    }
    @media (max-width: 991px) {
      .school_landing_div-25 {
        justify-content: center;
      }
    }
    .school_landing_div-26 {
      opacity: 0.5;
      background-color: rgba(0, 0, 0, 0.6);
      height: 15px;
      flex: 1;
    }
    .school_landing_img-12 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_div-27 {
      opacity: 0.5;
      background-color: rgba(0, 0, 0, 0.6);
      height: 15px;
      flex: 1;
    }
    .school_landing_div-28 {
      align-self: start;
      display: flex;
      margin-top: 6px;
      align-items: flex-start;
      gap: 3px;
    }
    .school_landing_img-13 {
      aspect-ratio: 1.29;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_img-14 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_div-29 {
      align-self: center;
      display: flex;
      width: 677px;
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
      margin: 18px 0 0 74px;
    }
    @media (max-width: 991px) {
      .school_landing_div-29 {
        flex-wrap: wrap;
        margin: auto 0;
        align-items: center;
      }
    }
    .school_landing_div-30 {
      align-self: stretch;
      display: flex;
      flex-grow: 1;
      flex-basis: auto;
      align-items: flex-start;
      justify-content: space-between;
      gap: 5px;
    }
    @media (max-width: 991px) {
      .school_landing_div-30 {
        max-width: 100%;
        align-items:center;
        flex-wrap: wrap;
      }
    }
    .school_landing_img-15 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 24px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    @media (max-width: 991px) {
      .school_landing_img-15  {
        display:none;
      }
    }
    .school_landing_div-31 {
      color: #000;
      letter-spacing: 0.7px;
      align-self: center;
   
      flex-grow: 1;
      flex-basis: auto;
      margin: auto 0;
      font: 400 14px/71% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-31 {
        max-width: 100%;
        letter-spacing: 2px;
        white-space: wrap;
        text-align:center;
        height: 30px;
        margin-top:20px;
        font: 400 14px/100% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
      }
    }
    .school_landing_div-32 {
      align-self: center;
      display: flex;
      align-items: flex-start;
      gap: 6px;
      margin: auto 0;
    }
    @media (max-width: 991px) {
      .school_landing_div-32 {
display:none;
      }
    }
    .school_landing_div-33 {
      color: #000;
      letter-spacing: 0.7px;
      align-self: center;
      margin: auto 0;
      font: 400 14px/71% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_img-16 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 16px;
      transform: rotate(-90deg);
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-34 {
      align-self: center;
      display: flex;
      margin-top: 21px;
      width: 100%;
      max-width: 1103px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_div-34 {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        display:flex;
        flex-direction: column;
      }
    }
    .school_landing_img-17 {
      aspect-ratio: 2.75;
      object-fit: contain;
      object-position: center;
      width: 99px;
      overflow: hidden;
      align-self: center;
      max-width: 100%;
      margin: auto 0;
    }
    .school_landing_div-35 {
      border-radius: 50px;
      background-color: #fff;
      align-self: stretch;
      display: flex;
      width: 250px;
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 14px;
      padding: 8px 10px 8px 8px;
    }
    @media (max-width: 991px) {
      .school_landing_div-35 {
        padding:5px;
        align-self:center;
        
        
      }
    }
    .school_landing_img-18 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 60px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-36 {
      color: #000;
      align-self: center;
      
      width: 170px;
      font: 700 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-36 {
        white-space: initial;
      }
    }
    .school_landing_div-37 {
      border-radius: 50px;
      background-color: #fff;
      align-self: stretch;
      display: flex;
      width: 250px;
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 14px;
      padding: 8px 10px 8px 7px;
    }
    @media (max-width: 991px) {
      .school_landing_div-37 {
        padding:5px;
        align-self:center;
      }
    }
    .school_landing_img-19 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 60px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-38 {
      color: #000;
      align-self: center;
      width: 170px;
      font: 700 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-38 {
        white-space: initial;
      }
    }
    .school_landing_div-39 {
      border-radius: 50px;
      background-color: #fff;
      align-self: stretch;
      display: flex;
      width: 270px;
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 14px;
      padding: 7px 10px 7px 10px;
    }
    @media (max-width: 991px) {
      .school_landing_div-39 {
        padding:5px;
        align-self:center;
      }
    }
    .school_landing_img-20 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 60px;
      align-items: center;
      overflow: hidden;
     
      max-width: 100%;
      display:flex;
    }
    .school_landing_div-40 {
      color: #000;
    
      width:170px;
      font: 700 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-41 {
      color: #000;
      align-self: center;
      white-space: nowrap;
      border-radius: 50px;
      border: 1px solid #000;
      width: 250px;
      max-width: 100%;
      margin: auto 0;
      padding: 19px 42px 19px 40px;
      font: 500 14px Roboto, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-41 {
        white-space: initial;
        padding: 10px 20px;
        text-align:center;
        width:250px;
      }
    }
    .school_landing_div-42 {
      background-color: #fff;
      align-self: center;
      margin-top: 15px;
      width: 100%;
      max-width: 1400px;
      padding: 22px 23px 2rem 23px;
    }
    @media (max-width: 991px) {
      .school_landing_div-42 {
        max-width: 100%;
        padding-left: 20px;

      }
    }
    .school_landing_div-43 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-43 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 18%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .school_landing_column {
        width: 100%;
      }
    }
    .school_landing_div-44 {
      display: flex;
      margin-top: 15px;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-44 {
        margin-top: 15px;
      }
    }
    .school_landing_div-45 {
      align-self: stretch;
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }

    @media (max-width: 991px) {
      .school_landing_div-45 {
        align-self: center;
        align-items:center;
        justify-content: center;
      }
    }
    .school_landing_div-46 {
      align-self: end;
      display: flex;
      flex-direction: column;
      
    }
    .school_landing_div-47 {
      color: #000;
      align-self: stretch;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-47 {
        text-align:center;
      }
    }
    .school_landing_div-48 {
      align-self: stretch;
      display: flex;
      margin-top: 26px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 5px;
    }
    @media (max-width: 991px) {
      .school_landing_div-48 {
        text-align:center;
        display: flex;
        flex-direction:column;
      }
    }
    .school_landing_img-21 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 40px;
      overflow: hidden;
      border-radius: 50%;
      align-self: stretch;
      max-width: 100%;
    }
    @media (max-width: 991px) {
      .school_landing_img-21 {
        aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 40px;
      overflow: hidden;
      border-radius: 50%;
      align-self: center;
      max-width: 100%;
      }
    }
    .school_landing_div-49 {
      color: #000;
      opacity: 0.8;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 500 13px Roboto, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-49 {
        white-space: initial;
      }
    }
    .school_landing_div-50 {
      align-self: stretch;
      display: flex;
      margin-top: 11px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 5px;
    }
    .school_landing_img-22 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 40px;
      overflow: hidden;
      border-radius: 50%;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-51 {
      color: #000;
      opacity: 0.8;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 500 13px Roboto, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-51 {
        white-space: initial;
      }
    }
    .school_landing_div-52 {
      align-self: end;
      display: flex;
      margin-top: 48px;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-52 {
        margin-top: 40px;
      }
    }
    .school_landing_img-23 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      transform: rotate(-90deg);
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_img-24 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      transform: rotate(-90deg);
      overflow: hidden;
      align-self: stretch;
      margin-top: 30px;
      flex-grow: 1;
    }
    .school_landing_div-53 {
      align-self: stretch;
      display: flex;
      margin-top: 13px;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_div-53  {
        justify-content: center;
        flex-direction:column;


        align-self: center;
        align-items:center;
        justify-content: center;
      }
    }

    .school_landing_div-54 {
      align-self: stretch;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 5px;
    }
    @media (max-width: 991px) {
      .school_landing_div-54  {
        justify-content: center;
        flex-direction:column;
      }
    }
    .school_landing_div-55 {
      border-radius: 50%;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      width: 40px;
    
    }
    @media (max-width: 991px) {
      .school_landing_div-55  {
        align-self: center;
      }
    }
    .school_landing_img-25 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-56 {
      color: #000;
      opacity: 0.8;
      align-self: center;
      white-space: initial;
      margin: auto 0;
      font: 500 13px Roboto, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-56 {
        white-space: initial;
      }
    }
    .school_landing_img-26 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 16px;
      transform: rotate(-90deg);
      overflow: hidden;
      align-self: center;
      max-width: 100%;
      margin: auto 0;
    }
    .school_landing_div-57 {
      align-self: stretch;
      display: flex;
      margin-top: 12px;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_div-57  {
        justify-content: center;
        align-items: center;
      }
    }
    .school_landing_div-58 {
      align-self: stretch;
      display: flex;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-58  {
        flex-direction: column;
      }
    }
    .school_landing_div-59 {
      align-self: stretch;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 5px;
    }
    @media (max-width: 991px) {
      .school_landing_div-59  {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    .school_landing_img-27 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 40px;
      overflow: hidden;
      border-radius: 50%;
      align-self: stretch;
      max-width: 100%;
    }
    @media (max-width: 991px) {
      .school_landing_img-27  {
        align-self: center;
        justify-content: center;
        align-items: center;
        display:flex;
        margin:auto 0;
      }
    }
    .school_landing_div-60 {
      color: #000;
      opacity: 0.8;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 500 13px Roboto, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-60 {
        white-space: initial;
      }
    }
    .school_landing_div-61 {
      align-self: stretch;
      display: flex;
      margin-top: 12px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 5px;
    }
    .school_landing_img-28 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 34px;
      overflow: hidden;
      border-radius: 50%;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-62 {
      color: #000;
      opacity: 0.8;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 400 12px Roboto, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-62 {
        white-space: initial;
      }
    }
    .school_landing_div-63 {
      align-self: center;
      display: flex;
      flex-direction: column;
      margin: auto 0;
    }
    .school_landing_img-29 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      transform: rotate(-90deg);
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_img-30 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      transform: rotate(-90deg);
      overflow: hidden;
      align-self: stretch;
      margin-top: 30px;
      flex-grow: 1;
    }
    .school_landing_div-64 {
      align-self: stretch;
      display: flex;
      margin-top: 12px;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_div-64 {
        align-self:center;
        align-items:center;
        flex-direction:column;
        justify-content: center;
        margin:auto 0;
      }
    }
    .school_landing_div-65 {
      align-self: stretch;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 5px;
    }
    @media (max-width: 991px) {
      .school_landing_div-65 {
        align-self:center;
        align-items:center;
        flex-direction:column;
        justify-content: center;
        margin:auto 0;
      }
    }
    .school_landing_img-31 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 40px;
      overflow: hidden;
      border-radius: 50%;
      align-self: stretch;
      max-width: 100%;
    }
    @media (max-width: 991px) {
      .school_landing_img-31  {
        align-self:center;
        align-items:center;
        flex-direction:column;
        justify-content: center;
        margin:auto 0;
      }
    }
    .school_landing_div-66 {
      color: #000;
      opacity: 0.8;
      align-self: center;
    
      margin: auto 0;
      font: 500 13px Roboto, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-66 {
        white-space: initial;
      }
    }
    .school_landing_img-32 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 16px;
      transform: rotate(-90deg);
      overflow: hidden;
      align-self: center;
      max-width: 100%;
      margin: auto 0;
    }
    .school_landing_div-67 {
      align-self: stretch;
      display: flex;
      margin-top: 12px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 5px;
    }
    @media (max-width: 991px) {
      .school_landing_div-67 {
        justify-content: center;
        flex-direction:column;
        align-items:center;
      }
    }
    .school_landing_img-33 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 40px;
      overflow: hidden;
      border-radius: 50%;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-68 {
      color: #000;
      opacity: 0.8;
      margin: auto 0;
      font: 500 13px Roboto, sans-serif;
    }
    .school_landing_img-34 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 16px;
      transform: rotate(-90deg);
      overflow: hidden;
      align-self: center;
      max-width: 100%;
      margin: auto 0;
    }
    .school_landing_column-2 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 82%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-2 {
        width: 100%;
        margin-left:0;
      }
    }
    .school_landing_div-69 {
      display: flex;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-69 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .school_landing_div-70 {
      align-self: stretch;
    }
    @media (max-width: 991px) {
      .school_landing_div-70 {
        max-width: 100%;
      }
    }
    .school_landing_div-71 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-71 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column-3 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 62%;
      height: 100%;
      margin-left: 0px;
  
    }
    @media (max-width: 991px) {
      .school_landing_column-3 {
        width: 100%;
      }
    }
    .school_landing_img-35 {
      aspect-ratio: 1.86;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex-grow: 1;
    }
    @media (max-width: 991px) {
      .school_landing_img-35 {
        max-width: 100%;
        margin-top: 36px;
      }
    }
    .school_landing_column-4 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 20%;
      
    }
    @media (max-width: 991px) {
      .school_landing_column-4 {
        width: 100%;
        margin-left:0;
      }
    }
    .school_landing_div-72 {
      display: flex;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-72 {
        margin-top: 38px;
      }
    }
    .school_landing_div-73 {
      color: #000;
      opacity: 0.8;
      align-self: stretch;
      font: 500 13px Roboto, sans-serif;
    }

    .school_landing_div-74 {
      color: #fff;
      letter-spacing: 0.7px;
      align-items: center; /* Center vertically */
      text-align: center; 
      align-self: stretch;
      white-space: nowrap;
      border-radius: 20px;
      background-color: #0175fe;
      margin-top: 25px;
      width: 100%;
      padding: 10px;
      font: 700 14px/71% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-74 {
        white-space: initial;
        padding: 8px 20px;
      }
    }
    .school_landing_div-75 {
      color: #020202;
      letter-spacing: 0.7px;
      align-self: stretch;
      white-space: nowrap;
      border-radius: 20px;
      text-align: center; 
      border: 1px solid #000;
      margin-top: 22px;
      width: 100%;
      padding: 10px;
      font: 700 14px/71% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-75 {
        white-space: initial;
        padding: 8px 20px;
      }
    }
    .school_landing_div-76 {
      align-self: stretch;
      display: flex;
      margin-top: 12px;
      padding-right: 9px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 15px;
    }
    .school_landing_div-77 {
      color: #000;
      margin: auto 0;
      font: 500 20px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_img-36 {
      aspect-ratio: 1.08;
      object-fit: contain;
      object-position: center;
      width: 26px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_img-37 {
      aspect-ratio: 2.45;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
      margin-top: 6px;
    }
    .school_landing_div-78 {
      border-radius: 10px;
      background-color: #0175fe;
      align-self: stretch;
      display: flex;
      margin-top: 22px;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
      padding: 9px 12px 9px 10px;
    }
    .school_landing_div-79 {
      color: #fff;
      opacity: 0.8;
      margin: auto 0;
      font: 500 16px Roboto, sans-serif;
    }
    .school_landing_img-38 {
      aspect-ratio: 1.06;
      object-fit: contain;
      object-position: center;
      width: 85px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-80 {
      align-self: center;
      display: flex;
      width: 94px;
      max-width: 100%;
      align-items: flex-start;
      gap: 4px;
      margin: 11px 0 0 -136px;
    }
    @media (max-width: 991px) {
      .school_landing_div-80 {
        justify-content: center;
      }
    }
    .school_landing_div-81 {
      border-radius: 50%;
      display: flex;
      height: 10px;
      flex-direction: column;
      flex: 1;
    }
    .school_landing_div-82 {
      border-radius: 50%;
      display: flex;
      height: 10px;
      flex-direction: column;
      flex: 1;
    }
    .school_landing_div-83 {
      border-radius: 50%;
      display: flex;
      height: 10px;
      flex-direction: column;
      flex: 1;
    }
    .school_landing_div-84 {
      border-radius: 50%;
      display: flex;
      height: 10px;
      flex-direction: column;
      flex: 1;
    }
    .school_landing_div-85 {
      border-radius: 50%;
      display: flex;
      height: 10px;
      flex-direction: column;
      flex: 1;
    }
    .school_landing_div-86 {
      border-radius: 50%;
      display: flex;
      height: 10px;
      flex-direction: column;
      flex: 1;
    }
    .school_landing_div-87 {
      border-radius: 50%;
      display: flex;
      height: 10px;
      flex-direction: column;
      flex: 1;
    }
    .school_landing_div-88 {
      border-radius: 10px;
      background-color: #0175fe;
      align-self: center;
      margin-top: 24px;
      width: 100%;
      max-width: 1400px;
      padding: 16px 12px 16px 30px;
    }
    @media (max-width: 991px) {
      .school_landing_div-88 {
        max-width: 100%;
        padding-left: 20px;
      }
    }
    .school_landing_div-89 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-89 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column-5 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 26%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .school_landing_column-5 {
        width: 100%;
      }
    }
    .school_landing_div-90 {
      display: flex;
      flex-direction: column;
      margin: auto 0;
    }
    @media (max-width: 991px) {
      .school_landing_div-90 {
        margin-top: 40px;
      }
    }
    .school_landing_div-91 {
      color: #fff;
      align-self: stretch;
      font: 700 25px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-92 {
      color: #fff;
      align-self: stretch;
      margin-top: 21px;
      font: 500 20px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-93 {
      color: #000;
      align-self: stretch;
      white-space: initial;
      border-radius: 50px;
      background-color: #f4f4f4;
      margin-top: 16px;
      width: 50%;
      text-align: center;
      flex-grow: 1;
      padding: 20px;
      font: 550 14px Roboto, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-93 {
        white-space: initial;
        padding: 8px 20px;
      }
    }
    .school_landing_column-6 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 74%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-6 {
        width: 100%;
        margin-left: 0;

      }
    }

    @media (max-width: 991px) {
      .school_landing_div-94 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .school_landing_div-95 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-95 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column-7 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 33%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .school_landing_column-7 {
        width: 100%;
      }
    }
    .school_landing_div-96 {
      border-radius: 20px;
      background-color: #fff;
      display: flex;
      width: 100%;
      max-width: 314px;
      flex-grow: 1;
      flex-direction: column;
      margin: 0 auto;
      padding: 16px 15px 23px;
    }
    @media (max-width: 991px) {
      .school_landing_div-96 {
        margin-top: 12px;
      }
    }
    .school_landing_div-97 {
      color: #000;
      align-self: center;
      white-space: initial;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-97 {
        white-space: initial;
      }
    }
    .school_landing_div-98 {
      align-self: stretch;
      display: flex;
      margin-top: 23px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 14px;
    }
    .school_landing_div-99 {
      background-color: #e3dfdf;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 5px;
    }
    .school_landing_img-39 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-100 {
      background-color: #e3dfdf;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 5px;
    }
    .school_landing_img-40 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_column-8 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 33%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-8 {
        width: 100%;
        margin-left: 0;
      }
    }
    .school_landing_div-101 {
      border-radius: 20px;
      background-color: #fff;
      display: flex;
      width: 100%;
      max-width: 314px;
      flex-grow: 1;
      flex-direction: column;
      margin: 0 auto;
      padding: 13px 15px 23px;
    }
    @media (max-width: 991px) {
      .school_landing_div-101 {
        margin-top: 12px;
      }
    }
    .school_landing_div-102 {
      color: #000;
      align-self: center;
      white-space: initial;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-102 {
        white-space: initial;
      }
    }
    .school_landing_div-103 {
      align-self: stretch;
      display: flex;
      margin-top: 26px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 8px;
    }
    .school_landing_div-104 {
      background-color: #e3dfdf;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 5px;
    }
    .school_landing_img-41 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_img-42 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_column-9 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 33%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-9 {
        width: 100%;
        margin-left: 0;
      }
    }
    .school_landing_div-105 {
      border-radius: 20px;
      background-color: #fff;
      display: flex;
      width: 100%;
      max-width: 314px;
      flex-grow: 1;
      flex-direction: column;
      margin: 0 auto;
      padding: 13px 15px 23px;
    }
    @media (max-width: 991px) {
      .school_landing_div-105 {
        margin-top: 12px;
      }
    }
    .school_landing_div-106 {
      color: #000;
      align-self: stretch;
      white-space: initial;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-106 {
        white-space: initial;
      }
    }
    .school_landing_div-107 {
      align-self: stretch;
      display: flex;
      margin-top: 29px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 13px;
    }
    .school_landing_img-43 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_img-44 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_div-108 {
      align-self: center;
      margin-top: 22px;
      width: 100%;
      max-width: 1400px;
    }
    @media (max-width: 991px) {
      .school_landing_div-108 {
        max-width: 100%;
      }
    }
    .school_landing_div-109 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-109 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column-10 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 33%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .school_landing_column-10 {
        width: 100%;
      }
    }
    .school_landing_div-110 {
      border-radius: 10px;
      background-color: #fff;
      flex-grow: 1;
      width: 100%;
      margin: 0 auto;
      padding: 10px 8px 1px;
    }
    @media (max-width: 991px) {
      .school_landing_div-110 {
        max-width: 100%;
        margin-top: 22px;
      }
    }
    .school_landing_div-111 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-111 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column-11 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 100%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .school_landing_column-11 {
        width: 100%;
      }
    }
    .school_landing_div-112 {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-112 {
        margin-top: 13px;
      }
    }
    .school_landing_div-113 {
      align-self: start;
      display: flex;
      margin-left: 10px;
      width: 100%;
      max-width: 100%;
      align-items: flex-start;
      gap: 0px;
    }
    .school_landing_img-45 {
      aspect-ratio: 1.29;
      object-fit: contain;
      object-position: center;
      width: 36px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-114 {
      color: #000;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-114 {
        white-space: initial;
      }
    }
    .school_landing_div-115 {
      align-self: start;
      display: flex;
      margin-top: 6px;
      
      max-width: 100%;
      align-items: flex-start;
      gap: 0px;
    }
    .school_landing_div-116 {
      align-self: stretch;
      flex-basis:33%;
      display: flex;
      flex-direction: column;
    }
    .school_landing_img-46 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-117 {
      color: #000;
      text-align: center;
      opacity: 0.8;
      align-self: center;
      font: 400 11px Inter, sans-serif;
    }
    .school_landing_div-118 {
      align-self: stretch;
      display: flex;
      flex-direction: column;
    }
    .school_landing_img-47 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-119 {
      color: #000;
      text-align: center;
      opacity: 0.8;
      align-self: center;
      font: 400 11px Inter, sans-serif;
    }
    .school_landing_column-12 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 33%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-12 {
        width: 100%;
      }
    }
    .school_landing_div-120 {
      display: flex;
      margin-top: 34px;
      flex-grow: 1;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-120 {
        margin-top: 40px;
      }
    }
    .school_landing_img-48 {
      aspect-ratio: 1.21;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-121 {
      color: #000;
      text-align: center;
      opacity: 0.8;
      align-self: center;
      font: 400 9px Inter, sans-serif;
    }
    .school_landing_column-13 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 33%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-13 {
        width: 100%;
        margin-left: 0;
      }
    }
    .school_landing_div-122 {
      border-radius: 10px;
      background-color: #fff;
      flex-grow: 1;
      width: 100%;
      margin: 0 auto;
      padding: 9px 8px 3px;
    }
    @media (max-width: 991px) {
      .school_landing_div-122 {
        max-width: 100%;
        margin-top: 22px;
      }
    }
    .school_landing_div-123 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-123 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column-14 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 33%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .school_landing_column-14 {
        width: 100%;
      }
    }
    .school_landing_div-124 {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      margin-top:35px;
    }
    @media (max-width: 991px) {
      .school_landing_div-124 {
        margin-top: 17px;
      }
    }
    .school_landing_div-125 {
      align-self: stretch;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 5px;
    }
    .school_landing_img-49 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 35px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-126 {
      color: #000;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-126 {
        white-space: initial;
      }
    }
    .school_landing_img-50 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;

    }
    .school_landing_div-127 {
      color: #000;
      text-align: center;
      opacity: 0.8;
      align-self: center;
      font: 400 9px Inter, sans-serif;
    }
    .school_landing_column-15 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 33%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-15 {
        width: 100%;
      }
    }
    .school_landing_div-128 {
      display: flex;
      margin-top: 35px;
      flex-grow: 1;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-128 {
        margin-top: 40px;
      }
    }
    .school_landing_img-51 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-129 {
      color: #000;
      text-align: center;
      opacity: 0.8;
      align-self: center;
      font: 400 9px Inter, sans-serif;
    }
    .school_landing_column-16 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 33%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-16 {
        width: 100%;
      }
    }
    .school_landing_div-130 {
      display: flex;
      margin-top: 35px;
      flex-grow: 1;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-130 {
        margin-top: 40px;
      }
    }
    .school_landing_img-52 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-131 {
      color: #000;
      text-align: center;
      opacity: 0.8;
      align-self: center;
      font: 400 9px Inter, sans-serif;
    }
    .school_landing_column-17 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 33%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-17 {
        width: 100%;
        margin-left: 0;
      }
    }
    .school_landing_div-132 {
      border-radius: 10px;
      background-color: #fff;
      flex-grow: 1;
      width: 100%;
      margin: 0 auto;
      padding: 12px 8px;
    }
    @media (max-width: 991px) {
      .school_landing_div-132 {
        max-width: 100%;
        margin-top: 22px;
      }
    }
    .school_landing_div-133 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-133 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column-18 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 33%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .school_landing_column-18 {
        width: 100%;
      }
    }
    .school_landing_div-134 {
      display: flex;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-134 {
        margin-top: 19px;
      }
    }
    .school_landing_div-135 {
      align-self: center;
      display: flex;
      width: 116px;
      max-width: 100%;
      align-items: flex-start;
      gap: 3px;
    }
    .school_landing_img-53 {
      aspect-ratio: 1.24;
      object-fit: contain;
      object-position: center;
      width: 31px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    .school_landing_div-136 {
      color: #000;
      align-self: start;
      margin-top: 6px;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-136 {
        white-space: initial;
      }
    }
    .school_landing_img-54 {
      aspect-ratio: 1.17;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
      margin-top: 8px;
    }
    .school_landing_div-137 {
      color: #000;
      text-align: center;
      opacity: 0.8;
      align-self: center;
      font: 700 10px Inter, sans-serif;
    }
    .school_landing_column-19 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 33%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-19 {
        width: 100%;
      }
    }
    .school_landing_div-138 {
      display: flex;
      margin-top: 32px;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-138 {
        margin-top: 40px;
      }
    }
    .school_landing_img-55 {
      aspect-ratio: 1.17;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-139 {
      color: #000;
      text-align: center;
      opacity: 0.8;
      align-self: center;
      white-space: nowrap;
      font: 700 10px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-139 {
        white-space: initial;
      }
    }
    .school_landing_column-20 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 33%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-20 {
        width: 100%;
      }
    }
    .school_landing_div-140 {
      display: flex;
      margin-top: 32px;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-140 {
        margin-top: 40px;
      }
    }
    .school_landing_img-56 {
      aspect-ratio: 1.17;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-141 {
      color: #000;
      text-align: center;
      opacity: 0.8;
      align-self: center;
      font: 700 10px Inter, sans-serif;
    }
    .school_landing_div-142 {
      align-self: center;
      margin-top: 22px;
      width: 100%;
      max-width: 1400px;
    }
    @media (max-width: 991px) {
      .school_landing_div-142 {
        max-width: 100%;
      }
    }
    .school_landing_div-143 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-143 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column-21 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 33%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .school_landing_column-21 {
        width: 100%;
      }
    }
    .school_landing_div-144 {
      border-radius: 10px;
      background-color: #fff;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
      padding: 3px 0;
    }
    @media (max-width: 991px) {
      .school_landing_div-144 {
        max-width: 100%;
        margin-top: 22px;
      }
    }
    .school_landing_img-57 {
      aspect-ratio: 1.12;
      object-fit: contain;
      object-position: center;
      width: 46px;
      overflow: hidden;
      align-self: start;
      z-index: 1;
      max-width: 100%;
    }
    .school_landing_div-145 {
      align-self: start;
      z-index: 1;
      display: flex;
      margin-top: -26px;
      width: 452px;
      max-width: 100%;
      padding-left: 54px;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-145 {
        padding-left: 20px;
      }
    }
    .school_landing_div-146 {
      color: #000;
      align-self: end;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-146 {
        white-space: initial;
      }
    }
    .school_landing_div-147 {
      align-self: end;
      display: flex;
      margin-top: 11px;
      width: 282px;
      max-width: 100%;
      align-items: flex-start;
      gap: 2px;
    }
    .school_landing_div-148 {
      align-self: start;
      display: flex;
      flex-direction: column;
    }
    .school_landing_img-58 {
      aspect-ratio: 1.17;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-149 {
      color: #000;
      text-align: center;
      opacity: 0.8;
      align-self: center;
      margin-top: 7px;
      font: 400 9px Inter, sans-serif;
    }
    .school_landing_div-150 {
      align-self: start;
      display: flex;
      margin-top: 4px;
      flex-direction: column;
    }
    .school_landing_img-59 {
      aspect-ratio: 1.17;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-151 {
      color: #000;
      text-align: center;
      opacity: 0.8;
      align-self: center;
      font: 400 9px Inter, sans-serif;
    }
    .school_landing_div-152 {
      align-self: start;
      display: flex;
      margin-top: -143px;
      width: 140px;
      max-width: 100%;
      flex-grow: 1;
      flex-direction: column;
    }
    .school_landing_img-60 {
      aspect-ratio: 1.17;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-153 {
      color: #000;
      text-align: center;
      opacity: 0.8;
      align-self: center;
      margin-top: 5px;
      font: 400 9px Inter, sans-serif;
    }
    .school_landing_column-22 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 33%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-22 {
        width: 100%;
      }
    }
    .school_landing_div-154 {
      border-radius: 10px;
      background-color: #fff;
      flex-grow: 1;
      width: 100%;
      margin: 0 auto;
      padding: 10px 8px 1px 4px;
    }
    @media (max-width: 991px) {
      .school_landing_div-154 {
        max-width: 100%;
        margin-top: 22px;
      }
    }
    .school_landing_div-155 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-155 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column-23 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 68%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .school_landing_column-23 {
        width: 100%;
      }
    }
    .school_landing_div-156 {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-156 {
        margin-top: 18px;
      }
    }
    .school_landing_div-157 {
      align-self: start;
      display: flex;
      margin-left: 12px;
      width: 207px;
      max-width: 100%;
      align-items: flex-start;
      gap: 10px;
    }
    @media (max-width: 991px) {
      .school_landing_div-157 {
        margin-left: 10px;
      }
    }
    .school_landing_img-61 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 30px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-158 {
      color: #000;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-158 {
        white-space: initial;
      }
    }
    .school_landing_div-159 {
      align-self: start;
      display: flex;
      margin-top: 4px;
      width: 292px;
      max-width: 100%;
      align-items: flex-start;
      gap: 12px;
    }
    .school_landing_div-160 {
      align-self: stretch;
      display: flex;
      flex-direction: column;
    }
    .school_landing_img-62 {
      aspect-ratio: 1.17;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-161 {
      color: #000;
      text-align: center;
      opacity: 0.8;
      align-self: center;
      margin-top: 5px;
      font: 400 9px Inter, sans-serif;
    }
    .school_landing_div-162 {
      align-self: stretch;
      display: flex;
      flex-direction: column;
    }
    .school_landing_img-63 {
      aspect-ratio: 1.17;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-163 {
      color: #000;
      text-align: center;
      opacity: 0.8;
      align-self: center;
      margin-top: 5px;
      font: 400 9px Inter, sans-serif;
    }
    .school_landing_column-24 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 32%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-24 {
        width: 100%;
      }
    }
    .school_landing_div-164 {
      display: flex;
      margin-top: 34px;
      flex-grow: 1;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-164 {
        margin-top: 40px;
      }
    }
    .school_landing_img-64 {
      aspect-ratio: 1.17;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-165 {
      color: #000;
      text-align: center;
      opacity: 0.8;
      align-self: center;
      margin-top: 5px;
      font: 400 9px Inter, sans-serif;
    }
    .school_landing_column-25 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 33%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-25 {
        width: 100%;
      }
    }
    .school_landing_div-166 {
      border-radius: 10px;
      background-color: #fff;
      flex-grow: 1;
      width: 100%;
      margin: 0 auto;
      padding: 10px 8px;
    }
    @media (max-width: 991px) {
      .school_landing_div-166 {
        max-width: 100%;
        margin-top: 22px;
      }
    }
    .school_landing_div-167 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-167 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column-26 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 67%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .school_landing_column-26 {
        width: 100%;
      }
    }
    .school_landing_div-168 {
      display: flex;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-168 {
        margin-top: 18px;
      }
    }
    .school_landing_div-169 {
      align-self: stretch;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 10px;
    }
    .school_landing_img-65 {
      aspect-ratio: 1.5;
      object-fit: contain;
      object-position: center;
      width: 36px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    .school_landing_div-170 {
      color: #000;
      align-self: start;
      margin-top: 5px;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-170 {
        white-space: initial;
      }
    }
    .school_landing_div-171 {
      align-self: stretch;
      display: flex;
      margin-top: 10px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 8px;
    }
    .school_landing_div-172 {
      align-self: stretch;
      display: flex;
      flex-direction: column;
    }
    .school_landing_img-66 {
      aspect-ratio: 1.17;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-173 {
      color: #000;
      text-align: center;
      opacity: 0.8;
      align-self: center;
      margin-top: 5px;
      font: 700 10px Inter, sans-serif;
    }
    .school_landing_div-174 {
      align-self: stretch;
      display: flex;
      flex-direction: column;
    }
    .school_landing_img-67 {
      aspect-ratio: 1.17;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-175 {
      color: #000;
      text-align: center;
      opacity: 0.8;
      align-self: center;
      margin-top: 5px;
      font: 700 10px Inter, sans-serif;
    }
    .school_landing_column-27 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 33%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-27 {
        width: 100%;
      }
    }
    .school_landing_div-176 {
      display: flex;
      margin-top: 34px;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-176 {
        margin-top: 40px;
      }
    }
    .school_landing_img-68 {
      aspect-ratio: 1.17;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-177 {
      color: #000;
      text-align: center;
      opacity: 0.8;
      align-self: center;
      margin-top: 5px;
      font: 700 10px Inter, sans-serif;
    }
    .school_landing_div-178 {
      align-self: center;
      margin-top: 40px;
      width: 100%;
      max-width: 1400px;
    }
    @media (max-width: 991px) {
      .school_landing_div-178 {
        max-width: 100%;
      }
    }
    .school_landing_div-179 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-179 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column-28 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 50%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .school_landing_column-28 {
        width: 100%;
      }
    }
    .school_landing_div-180 {
      border-radius: 10px;
      background-color: #fff;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
      padding: 19px 21px;
    }
    @media (max-width: 991px) {
      .school_landing_div-180 {
        max-width: 100%;
        margin-top: 22px;
        padding: 20px 20px;
      }
    }
    .school_landing_div-181 {
      color: #292626;
      align-self: stretch;
      white-space: nowrap;
      font: 700 25px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-181 {
        max-width: 100%;
        white-space: initial;
      }
    }
    .school_landing_div-182 {
      color: #000;
      opacity: 0.8;
      align-self: stretch;
      margin-top: 9px;
      white-space: initial;
      font: 400 14px Roboto, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-182 {
        max-width: 100%;
        white-space: initial;
      }
    }
    .school_landing_div-183 {
      align-self: stretch;
      margin-top: 42px;
    }
    @media (max-width: 991px) {
      .school_landing_div-183 {
        max-width: 100%;
        margin-top: 40px;
      }
    }
    .school_landing_div-184 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-184 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column-29 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 50%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .school_landing_column-29 {
        width: 100%;
      }
    }
    .school_landing_div-185 {
      background-color: #f7f7f7;
      display: flex;
      width: 100%;
      max-width: 317px;
      flex-grow: 1;
      align-items: flex-start;
      justify-content: space-between;
      gap: 7px;
      margin: 0 auto;
      padding: 17px 14px 17px 13px;
    }
    @media (max-width: 991px) {
      .school_landing_div-185 {
        margin-top: 13px;
        align-items: center;
        justify-content:center;
      }
    }
    .school_landing_div-186 {
      align-self: end;
      display: flex;
      flex-direction: column;
    }
    .school_landing_div-187 {
      color: #000;
      display: flex;
      text-align: center;
      align-self: center;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-187 {
        white-space: initial;
      }
    }
    .school_landing_div-188 {
      align-self: end;
      display: flex;
      margin-top: 33px;
      width: 193px;
      max-width: 100%;
      padding-right: 3px;
      align-items: flex-start;
      gap: 10px;
    }
    .school_landing_img-69 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_img-70 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_img-71 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 90px;
      overflow: hidden;
      align-self: end;
      margin-top: 52px;
      max-width: 100%;
    }
    @media (max-width: 991px) {
      .school_landing_img-71 {
        margin-top: 40px;
      }
    }
    .school_landing_column-30 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 50%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-30 {
        width: 100%;
        margin-left: 0;
      }
    }
    .school_landing_div-189 {
      background-color: #f7f7f7;
      display: flex;
      width: 100%;
      max-width: 317px;
      flex-grow: 1;
      flex-direction: column;
      margin: 0 auto;
      padding: 16px 14px 16px 13px;
    }
    @media (max-width: 991px) {
      .school_landing_div-189 {
        margin-top: 13px;
      }
    }
    .school_landing_div-190 {
      color: #000;
      align-self: stretch;
      white-space: initial;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-190 {
        white-space: initial;
      }
    }
    .school_landing_div-191 {
      align-self: stretch;
      display: flex;
      margin-top: 36px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 10px;
    }
    @media (max-width: 991px) {
      .school_landing_div-191 {
        justify-content: center;
      }
    }
    .school_landing_img-72 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_img-73 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_img-74 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_column-31 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 50%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-31 {
        width: 100%;
        margin-left: 0;
      }
    }
    .school_landing_div-192 {
      border-radius: 10px;
      background-color: #fff;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
      padding: 17px 21px;
    }
    @media (max-width: 991px) {
      .school_landing_div-192 {
        max-width: 100%;
        margin-top: 22px;
        padding: 20px 20px;
      }
    }
    .school_landing_div-193 {
      color: #292626;
      align-self: stretch;
      white-space: nowrap;
      font: 700 25px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-193 {
        max-width: 100%;
        white-space: initial;
      }
    }
    .school_landing_div-194 {
      color: #000;
      opacity: 0.8;
      align-self: stretch;
      margin-top: 8px;
      white-space: initial;
      font: 400 14px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-194 {
        max-width: 100%;
      }
    }
    .school_landing_div-195 {
      align-self: stretch;
      margin-top: 32px;
    }
    @media (max-width: 991px) {
      .school_landing_div-195 {
        max-width: 100%;
      }
    }
    .school_landing_div-196 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-196 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column-32 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 50%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .school_landing_column-32 {
        width: 100%;
      }
    }
    .school_landing_div-197 {
      background-color: #f7f7f7;
      display: flex;
      width: 100%;
      max-width: 317px;
      flex-grow: 1;
      align-items: flex-start;
      justify-content: space-between;
      gap: 3px;
      margin: 0 auto;
      padding: 17px 14px 17px 13px;
    }
    @media (max-width: 991px) {
      .school_landing_div-197 {
        margin-top: 13px;
      }
    }
    .school_landing_div-198 {
      align-self: end;
      display: flex;
      flex-direction: column;
    }
    .school_landing_div-199 {
      color: #000;
      align-self: center;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-199 {
        white-space: initial;
      }
    }
    .school_landing_div-200 {
      align-self: end;
      display: flex;
      margin-top: 33px;

      max-width: 100%;
      padding-right: 7px;
      align-items: flex-start;
      gap: 10px;
    }
    .school_landing_img-75 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_img-76 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_img-77 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 90px;
      overflow: hidden;
      align-self: end;
      margin-top: 52px;
      max-width: 100%;
    }
    @media (max-width: 991px) {
      .school_landing_img-77 {
        margin-top: 40px;
      }
    }
    .school_landing_column-33 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 50%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-33 {
        width: 100%;
        margin-left: 0;
      }
    }
    .school_landing_div-201 {
      background-color: #f7f7f7;
      display: flex;
      width: 100%;
      max-width: 317px;
      flex-grow: 1;
      align-items: flex-start;
      justify-content: space-between;
      gap: 3px;
      margin: 0 auto;
      padding: 17px 14px 17px 13px;
    }
    @media (max-width: 991px) {
      .school_landing_div-201 {
        margin-top: 13px;
      }
    }
    .school_landing_div-202 {
      align-self: end;
      display: flex;
      flex-direction: column;
    }
    .school_landing_div-203 {
      color: #000;
      align-self: center;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-203 {
        white-space: initial;
      }
    }
    .school_landing_div-204 {
      align-self: end;
      display: flex;
      margin-top: 33px;
      
      max-width: 100%;
      padding-right: 7px;
      align-items: flex-start;
      gap: 10px;
    }
    .school_landing_img-78 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_img-79 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_img-80 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 90px;
      overflow: hidden;
      align-self: end;
      margin-top: 52px;
      max-width: 100%;
    }
    @media (max-width: 991px) {
      .school_landing_img-80 {
        margin-top: 40px;
      }
    }
    .school_landing_div-205 {
      align-self: center;
      display: flex;
      margin-top: 33px;
      width: 100%;
      max-width: 1371px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 15px;
    }
    @media (max-width: 991px) {
      .school_landing_div-205 {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .school_landing_div-206 {
      color: #292626;
      align-self: start;
      flex-grow: 1;
      flex-basis: auto;
      font: 700 25px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-207 {
      border-radius: 10px;
      border: 1px solid #b7a4a4;
      background-color: #d9d9d9;
      align-self: center;
      display: flex;
      width: 900px;
      height: 7px;
      flex-direction: column;
      margin: auto 0;
    }
    @media (max-width: 991px) {
      .school_landing_div-207 {
        max-width: 100%;
      }
    }
    .school_landing_div-208 {
      color: #292626;
      align-self: start;
      flex-grow: 1;
      flex-basis: auto;
      font: 700 25px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-209 {
      align-self: center;
      display: flex;
      margin-top: 26px;
      width: 100%;
      max-width: 1391px;
      background: white;
      align-items: flex-start;
      gap: 9px;
    }
    @media (max-width: 991px) {
      .school_landing_div-209 {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .school_landing_div-210 {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      align-self: stretch;
      position: relative;
      display: flex;
      aspect-ratio: 1;
      width: 20%;
      padding: 250px 21px 12px 80px;
      background-image: url('https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FUniform%20Background%20Image.png?alt=media&token=af742bc7-8378-4eb6-b5dc-2d386e8066ac');
      background-size:     cover;    
      background-position: center center; 
    }
    @media (max-width: 991px) {
      .school_landing_div-210 {
        padding: 260px 150px 0 50px;
        
        width:100%
      }
    }
    .school_landing_div-211 {
      position: relative;
      color: #000;
      align-self: end;
      white-space: initial;
    
      text-align: center; 
      border-radius: 20px;
      background-color: #fff;
      width: 130px;
      max-width: 100%;
      padding: 5px;
      font: 400 16px Roboto, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-211 {
        white-space: initial;
        padding:5px;
        width:300px;
      }
    }
    .school_landing_div-212 {
      align-self: center;
      display: flex;
      width: 20%;
      flex-direction: column;
      margin: auto 0;
    }
    @media (max-width: 991px) {
      .school_landing_div-212 {
        width:100%;
        padding:10px;
      }
    }
    .school_landing_div-213 {
      align-self: end;
      display: flex;
      width: 253px;
      max-width: 100%;
      align-items: flex-start;
      gap: 6px;
    }
    @media (max-width: 991px) {
      .school_landing_div-213 {
        width:100%;
        align-self: center;
        align-items:center;
        justify-content:center;
      }
    }
    .school_landing_img-81 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 32px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-214 {
      color: #000;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-214 {
        white-space: initial;
      }
    }
    .school_landing_div-215 {
      color: #000;
      opacity: 0.5;
      align-self: end;
      white-space: initial;
      font: 400 16px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-215 {
        width:100%;
        align-self: center;
        text-align:center;
        align-items:center;
        justify-content:center;
      }
    }
    .school_landing_img-82 {
      aspect-ratio: 0.97;
      object-fit: contain;
      object-position: center;
      width: 70px;
      overflow: hidden;
      align-self: end;
      margin-top: 11px;
      max-width: 100%;
    }
    @media (max-width: 991px) {
      .school_landing_img-82 {
        
        align-self: center;
        text-align:center;
        align-items:center;
        justify-content:center;
      }
    }
    .school_landing_div-216 {
      color: #000;
      align-self: end;
      margin-top: 29px;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-216 {
        white-space: initial;
        align-self: center;
        text-align:center;
        align-items:center;
        justify-content:center;
      }
    }
    .school_landing_img-83 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 75px;
      overflow: hidden;
      align-self: end;
      margin-top: 19px;
      max-width: 100%;
      flex-grow: 1;
    }
    @media (max-width: 991px) {
      .school_landing_img-83 {
        
        align-self: center;
        text-align:center;
        align-items:center;
        justify-content:center;
      }
    }
    .school_landing_div-217 {
      background-color: rgba(0, 0, 0, 0.3);
      align-self: stretch;
      width: 1px;
      height: 298px;
    }
    @media (max-width: 991px) {
      .school_landing_div-217 {
        display:none;
      }
    }
    .school_landing_div-218 {
      align-self: center;
      display: flex;
      flex-direction: column;
      margin: auto 0;
      width: 20%;
      padding-right: 15px;
    }
    @media (max-width: 991px) {
      .school_landing_div-218 {
      width:100%;
      padding-right: 0;
      }
    }
    .school_landing_div-219 {
      color: #000;
      align-self: end;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-219 {
        white-space: initial;
        align-self: center;
        text-align:center;
        
      }
    }
    .school_landing_div-220 {
      align-self: end;
      display: flex;
      margin-top: 12px;
      width: 255px;
      max-width: 100%;
      padding-left: 10px;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-220 {
        padding-left: 0;
        width:100%;
        align-self: center;

      }
    }
    .school_landing_div-221 {
      color: #000;
      opacity: 0.5;

      align-self: end;
      font: 400 16px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-221 {
        align-self: center;
      text-align:center;

      }
    }
    .school_landing_img-84 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 80px;
      overflow: hidden;
      align-self: end;
      margin-top: 15px;
      max-width: 100%;
      flex-grow: 1;
    }
    @media (max-width: 991px) {
      .school_landing_img-84 {
        align-self: center;
 

      }
    }
    .school_landing_div-222 {
      color: #000;
      align-self: end;
      margin-top: 26px;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-222 {
        white-space: initial;
        align-self: center;
      }
    }
    .school_landing_img-85 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 75px;
      overflow: hidden;
      align-self: end;
      margin-top: 23px;
      max-width: 100%;
      flex-grow: 1;
    }
    @media (max-width: 991px) {
      .school_landing_img-85 {
   
        align-self: center;
      }
    }
    .school_landing_div-223 {
      background-color: rgba(0, 0, 0, 0.3);
      align-self: stretch;
      width: 1px;
      height: 298px;
    }
    @media (max-width: 991px) {
      .school_landing_div-223 {
        display:none;
      }
    }
    .school_landing_div-224 {
      align-self: center;
      display: flex;
      flex-direction: column;
      width: 418px;
      margin: auto 0;
    }
    .school_landing_div-225 {
      align-self: stretch;
      z-index: 1;
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    .school_landing_div-226 {
      align-self: start;
      display: flex;
      flex-direction: column;
    }
    .school_landing_div-227 {
      color: #000;
      align-self: stretch;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-227 {
        white-space: initial;
      }
    }
    .school_landing_div-228 {
      color: #000;
      opacity: 0.5;
      align-self: stretch;
      margin-top: 12px;
      font: 400 16px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-229 {
      color: #000;
      align-self: start;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-229 {
        white-space: initial;
      }
    }
    .school_landing_div-230 {
      align-self: stretch;
    }
    .school_landing_div-231 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-231 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column-34 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 36%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .school_landing_column-34 {
        width: 100%;
      }
    }
    .school_landing_div-232 {
      color: #000;
      margin: auto 0;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-232 {
        margin-top: 40px;
      }
    }
    .school_landing_column-35 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 26%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-35 {
        width: 100%;
      }
    }
    .school_landing_div-233 {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-233 {
        margin-top: 21px;
      }
    }
    .school_landing_img-86 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 89px;
      overflow: hidden;
      align-self: start;
      margin-left: 10px;
      max-width: 100%;
    }
    .school_landing_img-87 {
      aspect-ratio: 1.18;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: start;
      margin-top: 51px;
      flex-grow: 1;
    }
    @media (max-width: 991px) {
      .school_landing_img-87 {
        margin-top: 40px;
      }
    }
    .school_landing_column-36 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 38%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-36 {
        width: 100%;
      }
    }
    .school_landing_div-234 {
      color: #000;
      white-space: nowrap;
      margin: auto 0;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-234 {
        margin-top: 40px;
        white-space: initial;
      }
    }
    .school_landing_div-235 {
      align-self: end;
      display: flex;
      margin-top: 42px;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-235 {
        margin-top: 40px;
      }
    }
    .school_landing_img-88 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: start;
    }
    .school_landing_img-89 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 86px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
      flex-grow: 1;
      margin: 55px 0 0 18px;
    }
    @media (max-width: 991px) {
      .school_landing_img-89 {
        margin: 40px 0 0 10px;
      }
    }
    .school_landing_div-236 {
      align-self: center;
      display: flex;
      margin-top: 27px;
      width: 100%;
      max-width: 1396px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_div-236 {
        max-width: 100%;
        flex-wrap: wrap;
      }
    }
    .school_landing_div-237 {
      color: #292626;
      font: 700 25px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-238 {
      border-radius: 10px;
      border: 1px solid #b7a4a4;
      background-color: #d9d9d9;
      align-self: center;
      display: flex;
      width: 1188px;
      height: 7px;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: auto;
      margin: auto 0;
    }
    @media (max-width: 991px) {
      .school_landing_div-238 {
        max-width: 100%;
      }
    }
    .school_landing_div-239 {
      align-self: center;
      display: flex;
      margin-top: 25px;
      width: 100%;
      max-width: 1391px;
      align-items: flex-start;
      gap: 10px;
      background: white;
    }
    @media (max-width: 991px) {
      .school_landing_div-239 {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .school_landing_div-240 {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      align-self: stretch;
      position: relative;
      display: flex;
      aspect-ratio: 1;
      padding: 240px 80px 22px 22px;
      background-image: url('https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2FWriting%20Supplies%20Background.png?alt=media&token=c75ab95c-d767-46e7-b861-0e0b3ff0e4dc');
      background-size:     cover;    
      background-position: center center; 

    }
    @media (max-width: 991px) {
      .school_landing_div-240 {
        padding: 220px 20px 0;
        width:100%;
      }
    }


    .school_landing_div-2401 {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      align-self: stretch;
      position: relative;
      display: flex;
      aspect-ratio: 1;
      padding: 240px 80px 22px 22px;
      background-image: url('https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FAdvertisements%2F10.png?alt=media&token=93382b10-593f-402c-a233-36f423d40e98');
      background-size:     cover;    
      background-position: center center; 

    }
    @media (max-width: 991px) {
      .school_landing_div-2401 {
        padding: 220px 20px 0;
        width:100%;
      }
    }
    .school_landing_div-241 {
      position: relative;
      color: #000;
      align-self: start;
      white-space: initial;
      border-radius: 20px;
      text-align: center; 
      background-color: #fff;
      width: 120px;
      max-width: 100%;
      padding: 12px 14px;
      font: 400 16px Roboto, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-241 {
        white-space: nowrap;
      }
    }
    .school_landing_div-242 {
      align-self: center;
      display: flex;
      flex-direction: column;
      margin: auto 0;
    }
    .school_landing_div-243 {
      align-self: stretch;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 6px;
    }
    .school_landing_img-90 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 32px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-244 {
      color: #000;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-244 {
        white-space: initial;
      }
    }
    .school_landing_div-245 {
      align-self: stretch;
      display: flex;
      padding-right: 2px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 0px;
    }
    .school_landing_div-246 {
      color: #000;
      opacity: 0.5;
      align-self: start;
      margin-right: -21px;
      font: 400 16px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_img-91 {
      aspect-ratio: 0.93;
      object-fit: contain;
      object-position: center;
      width: 78px;
      overflow: hidden;
      align-self: start;
      margin-top: 17px;
      max-width: 100%;
    }
    .school_landing_div-247 {
      color: #000;
      align-self: stretch;
      margin-top: 25px;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-247 {
        white-space: initial;
      }
    }
    .school_landing_div-248 {
      align-self: stretch;
      display: flex;
      flex-grow: 1;
      padding-left: 30px;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-248 {
        padding-left: 20px;
      }
    }
    .school_landing_div-249 {
      color: #000;
      opacity: 0.5;
      align-self: end;
      z-index: 1;
      font: 400 16px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_img-92 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 75px;
      overflow: hidden;
      align-self: end;
      margin-top: -12px;
      max-width: 100%;
      flex-grow: 1;
    }
    .school_landing_div-250 {
      background-color: rgba(0, 0, 0, 0.3);
      align-self: stretch;
      width: 1px;
      height: 298px;
    }
    .school_landing_div-251 {
      align-self: center;
      display: flex;
      flex-direction: column;
      margin: auto 0;
    }
    .school_landing_div-252 {
      color: #000;
      align-self: end;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-252 {
        white-space: initial;
      }
    }
    .school_landing_div-253 {
      align-self: end;
      display: flex;
      margin-top: 8px;
      width: 255px;
      max-width: 100%;
      padding-left: 31px;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-253 {
        padding-left: 20px;
      }
    }
    .school_landing_div-254 {
      color: #000;
      opacity: 0.5;
      align-self: end;
      font: 400 16px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_img-93 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 80px;
      overflow: hidden;
      align-self: end;
      margin-top: 12px;
      max-width: 100%;
      flex-grow: 1;
    }
    .school_landing_div-255 {
      color: #000;
      align-self: end;
      margin-top: 25px;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-255 {
        white-space: initial;
      }
    }
    .school_landing_img-94 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 75px;
      overflow: hidden;
      align-self: end;
      margin-top: 19px;
      max-width: 100%;
      flex-grow: 1;
    }
    .school_landing_div-256 {
      background-color: rgba(0, 0, 0, 0.3);
      align-self: stretch;
      width: 1px;
      height: 298px;
    }
    .school_landing_div-257 {
      align-self: start;
      display: flex;
      margin-top: 17px;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-257 {
        max-width: 100%;
      }
    }
    .school_landing_div-258 {
      align-self: end;
      width: 532px;
      max-width: 100%;
    }
    .school_landing_div-259 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-259 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column-37 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 24%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .school_landing_column-37 {
        width: 100%;
      }
    }
    .school_landing_div-260 {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-260 {
        margin-top: 40px;
      }
    }
    .school_landing_div-261 {
      color: #000;
      align-self: stretch;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-261 {
        white-space: initial;
      }
    }
    .school_landing_div-262 {
      color: #000;
      align-self: stretch;
      margin-top: 140px;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-262 {
        margin-top: 40px;
        white-space: initial;
      }
    }
    .school_landing_column-38 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 76%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-38 {
        width: 100%;
      }
    }
    .school_landing_div-263 {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-263 {
        margin-top: 40px;
      }
    }
    .school_landing_div-264 {
      align-self: stretch;
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    .school_landing_img-95 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 89px;
      overflow: hidden;
      align-self: end;
      margin-top: 40px;
      max-width: 100%;
    }
    .school_landing_div-265 {
      align-self: end;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: auto;
    }
    .school_landing_div-266 {
      color: #000;
      align-self: end;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-266 {
        white-space: initial;
      }
    }
    .school_landing_img-96 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 104px;
      overflow: hidden;
      align-self: end;
      margin-top: 7px;
      max-width: 100%;
      flex-grow: 1;
    }
    .school_landing_div-267 {
      color: #000;
      align-self: center;
      margin-top: 29px;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-267 {
        white-space: initial;
      }
    }
    .school_landing_div-268 {
      align-self: end;
      display: flex;
      margin-top: 9px;
      width: 368px;
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    .school_landing_img-97 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      margin-top: 5px;
      flex: 1;
    }
    .school_landing_img-98 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_div-269 {
      align-self: center;
      display: flex;
      margin-top: 27px;
      width: 100%;
      max-width: 1396px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_div-269 {
        max-width: 100%;
        flex-wrap: wrap;
      }
    }
    .school_landing_div-270 {
      color: #292626;
      align-self: start;
      flex-grow: 1;
      flex-basis: auto;
      font: 700 25px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-271 {
      border-radius: 10px;
      border: 1px solid #b7a4a4;
      background-color: #d9d9d9;
      align-self: start;
      display: flex;
      margin-top: 10px;
      width: 1040px;
      height: 7px;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: auto;
    }
    @media (max-width: 991px) {
      .school_landing_div-271 {
        max-width: 100%;
      }
    }
    .school_landing_div-272 {
      align-self: center;
      display: flex;
      margin-top: 30px;
      width: 100%;
      max-width: 1391px;
      align-items: flex-start;
      gap: 4px;
    }
    @media (max-width: 991px) {
      .school_landing_div-272 {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .school_landing_div-273 {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      align-self: stretch;
      position: relative;
      display: flex;
      aspect-ratio: 1.0067114093959733;
      padding: 240px 80px 22px 22px;
    }
    @media (max-width: 991px) {
      .school_landing_div-273 {
        padding: 100px 20px 0;
      }
    }
    .school_landing_div-274 {
      position: relative;
      color: #000;
      align-self: start;
      white-space: nowrap;
      border-radius: 20px;
      background-color: #fff;
      width: 111px;
      max-width: 100%;
      padding: 12px 14px;
      font: 400 16px Roboto, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-274 {
        white-space: initial;
      }
    }
    .school_landing_div-275 {
      align-self: center;
      display: flex;
      flex-direction: column;
      margin: auto 0;
    }
    .school_landing_div-276 {
      align-self: end;
      display: flex;
      width: 251px;
      max-width: 100%;
      align-items: flex-start;
      gap: 6px;
    }
    .school_landing_img-99 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 32px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-277 {
      color: #000;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-277 {
        white-space: initial;
      }
    }
    .school_landing_div-278 {
      color: #000;
      opacity: 0.5;
      align-self: end;
      font: 400 16px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_img-100 {
      aspect-ratio: 0.93;
      object-fit: contain;
      object-position: center;
      width: 78px;
      overflow: hidden;
      align-self: end;
      max-width: 100%;
    }
    .school_landing_div-279 {
      color: #000;
      align-self: end;
      margin-top: 25px;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-279 {
        white-space: initial;
      }
    }
    .school_landing_div-280 {
      align-self: end;
      display: flex;
      width: 251px;
      max-width: 100%;
      flex-grow: 1;
      padding-left: 30px;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-280 {
        padding-left: 20px;
      }
    }
    .school_landing_div-281 {
      color: #000;
      opacity: 0.5;
      align-self: end;
      z-index: 1;
      font: 400 16px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_img-101 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 75px;
      overflow: hidden;
      align-self: end;
      margin-top: -12px;
      max-width: 100%;
      flex-grow: 1;
    }
    .school_landing_div-282 {
      background-color: rgba(0, 0, 0, 0.3);
      align-self: stretch;
      width: 1px;
      height: 298px;
    }
    .school_landing_div-283 {
      align-self: center;
      display: flex;
      flex-direction: column;
      margin: auto 0;
    }
    .school_landing_div-284 {
      color: #000;
      align-self: end;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-284 {
        white-space: initial;
      }
    }
    .school_landing_div-285 {
      align-self: end;
      display: flex;
      margin-top: 8px;
      width: 260px;
      max-width: 100%;
      padding-left: 31px;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-285 {
        padding-left: 20px;
      }
    }
    .school_landing_div-286 {
      color: #000;
      opacity: 0.5;
      align-self: end;
      font: 400 16px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_img-102 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 80px;
      overflow: hidden;
      align-self: end;
      margin-top: 12px;
      max-width: 100%;
      flex-grow: 1;
    }
    .school_landing_div-287 {
      color: #000;
      align-self: end;
      margin-top: 26px;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-287 {
        white-space: initial;
      }
    }
    .school_landing_img-103 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 75px;
      overflow: hidden;
      align-self: end;
      max-width: 100%;
      flex-grow: 1;
      margin: 19px 12px 0 0;
    }
    @media (max-width: 991px) {
      .school_landing_img-103 {
        margin-right: 10px;
      }
    }
    .school_landing_div-288 {
      background-color: rgba(0, 0, 0, 0.3);
      align-self: stretch;
      width: 1px;
      height: 298px;
    }
    .school_landing_div-289 {
      align-self: start;
      display: flex;
      margin-top: 17px;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-289 {
        max-width: 100%;
      }
    }
    .school_landing_div-290 {
      align-self: stretch;
    }
    @media (max-width: 991px) {
      .school_landing_div-290 {
        max-width: 100%;
      }
    }
    .school_landing_div-291 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-291 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column-39 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 31%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .school_landing_column-39 {
        width: 100%;
      }
    }
    .school_landing_div-292 {
      color: #000;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-292 {
        margin-top: 7px;
      }
    }
    .school_landing_column-40 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 20%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-40 {
        width: 100%;
      }
    }
    .school_landing_img-104 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      margin-top: 40px;
      flex-grow: 1;
    }
    .school_landing_column-41 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 49%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-41 {
        width: 100%;
      }
    }
    .school_landing_div-293 {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-293 {
        margin-top: 8px;
      }
    }
    .school_landing_div-294 {
      color: #000;
      align-self: end;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-294 {
        white-space: initial;
      }
    }
    .school_landing_img-105 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 104px;
      overflow: hidden;
      align-self: end;
      margin-top: 7px;
      max-width: 100%;
      flex-grow: 1;
    }
    .school_landing_div-295 {
      align-self: stretch;
      margin-top: 29px;
    }
    @media (max-width: 991px) {
      .school_landing_div-295 {
        max-width: 100%;
      }
    }
    .school_landing_div-296 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-296 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column-42 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 50%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .school_landing_column-42 {
        width: 100%;
      }
    }
    .school_landing_div-297 {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-297 {
        margin-top: 21px;
      }
    }
    .school_landing_div-298 {
      color: #000;
      align-self: end;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-298 {
        white-space: initial;
      }
    }
    .school_landing_img-106 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 92px;
      overflow: hidden;
      align-self: end;
      margin-top: 14px;
      max-width: 100%;
      flex-grow: 1;
    }
    .school_landing_column-43 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 50%;
      margin-left: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_column-43 {
        width: 100%;
      }
    }
    .school_landing_div-299 {
      display: flex;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .school_landing_div-299 {
        margin-top: 21px;
      }
    }
    .school_landing_div-300 {
      color: #000;
      align-self: end;
      white-space: nowrap;
      font: 500 18px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-300 {
        white-space: initial;
      }
    }
    .school_landing_img-107 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 86px;
      overflow: hidden;
      align-self: end;
      margin-top: 9px;
      max-width: 100%;
      flex-grow: 1;
    }
    .school_landing_div-301 {
      align-self: center;
      display: flex;
      margin-top: 17px;
      width: 100%;
      max-width: 1413px;
      align-items: flex-start;
      gap: 6px;
    }
    @media (max-width: 991px) {
      .school_landing_div-301 {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .school_landing_img-108 {
      aspect-ratio: 1.41;
      object-fit: contain;
      object-position: center;
      width: 69px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-302 {
      color: #292626;
      align-self: center;
      flex-grow: 1;
      flex-basis: auto;
      margin: auto 0;
      font: 700 25px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-303 {
      color: #292626;
      opacity: 0.5;
      align-self: center;
      width: 250px;
      margin: auto 0;
      font: 700 22px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-304 {
      border-radius: 10px;
      border: 1px solid #b7a4a4;
      background-color: #d9d9d9;
      align-self: center;
      display: flex;
      width: 776px;
      height: 7px;
      flex-direction: column;
      margin: auto 0;
    }
    @media (max-width: 991px) {
      .school_landing_div-304 {
        max-width: 100%;
      }
    }
    .school_landing_div-305 {
      border-radius: 10px;
      border: 1px solid #b7a4a4;
      background-color: #d9d9d9;
      align-self: center;
      display: flex;
      width: 48px;
      height: 7px;
      flex-direction: column;
      margin: auto 0;
    }
    .school_landing_div-306 {
      align-self: center;
      margin-top: 17px;
      width: 100%;
      max-width: 1387px;
    }
    @media (max-width: 991px) {
      .school_landing_div-306 {
        max-width: 100%;
      }
    }
    .school_landing_div-307 {
      gap: 20px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-307 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column-44 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 50%;
      margin-left: 0px;
      background-image: url('https://firebasestorage.googleapis.com/v0/b/challenge-e5b79.appspot.com/o/Images%2FHome%20Page%20Builder%20Images%2FPhase%202%2Fbackground.svg?alt=media&token=77cff846-ac4c-416c-a9eb-7b3a8e72b63e');
      background-size:     cover;    
      background-position: center center;
    }
    @media (max-width: 991px) {
      .school_landing_column-44 {
        width: 100%;
      }
    }
    .school_landing_div-308 {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      position: relative;
      display: flex;
      min-height: 300px;
      flex-grow: 1;
      padding-top: 35px;
    }
    @media (max-width: 991px) {
      .school_landing_div-308 {
        max-width: 100%;
        margin-top: 9px;
      }
    }
    .school_landing_div-309 {
      position: relative;
      align-self: start;
      display: flex;
      width: 690px;
      max-width: 100%;
      flex-direction: column;
      padding: 0 67px 0 46px;
    }
    @media (max-width: 991px) {
      .school_landing_div-309 {
        padding: 0 20px;
      }
    }
    .school_landing_div-310 {
      color: #fff;
      align-self: start;
      margin-left: 12px;
      white-space: nowrap;
      font: 500 25px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-310 {
        margin-left: 10px;
        white-space: initial;
      }
    }
    .school_landing_div-311 {
      align-self: stretch;
      display: flex;
      margin-top: 35px;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_div-311 {
        max-width: 100%;
        flex-wrap: wrap;
      }
    }
    .school_landing_div-312 {
      color: #fff;
      margin-top: 5px;
      font: 500 22px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-313 {
      color: #fff;
      flex-grow: 1;
      flex-basis: auto;
      font: 500 22px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-314 {
      align-self: end;
      display: flex;
      margin-top: 13px;
      width: 89px;
      max-width: 100%;
      align-items: flex-start;
      gap: 8px;
    }
    .school_landing_div-315 {
      color: #fffefd;
      align-self: start;
      font: 600 16px Roboto, sans-serif;
    }
    .school_landing_img-109 {
      aspect-ratio: 0.56;
      object-fit: contain;
      object-position: center;
      width: 5px;
      transform: rotate(-90deg);
      stroke-width: 1px;
      stroke: rgba(255, 255, 255, 0.6);
      overflow: hidden;
      align-self: start;
      margin-top: 4px;
      max-width: 100%;
    }
    .school_landing_div-316 {
      position: relative;
      align-self: start;
      display: flex;
      width: 303px;
      max-width: 100%;
      flex-grow: 1;
      flex-direction: column;
      margin: 25px 0 0 46px;
    }
    @media (max-width: 991px) {
      .school_landing_div-316 {
        margin-left: 10px;
      }
    }
    .school_landing_div-317 {
      align-self: end;
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    .school_landing_div-318 {
      color: #fff;
      font: 500 22px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-319 {
      color: #fff;
      align-self: start;
      font: 500 22px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-320 {
      border-radius: 50px;
      border: 1px solid #fff;
      align-self: end;
      display: flex;
      margin-top: 20px;
      width: 162px;
      height: 45px;
      flex-direction: column;
    }
    .school_landing_column-45 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 50%;
    
    }
    @media (max-width: 991px) {
      .school_landing_column-45 {
        width: 100%;
        margin-left: 0;
     
      }
    }
    .school_landing_div-321 {
      border: 3px solid #dcdee3;
      background-color: #fff;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
      padding: 26px 80px 19px 30px;
    }
    @media (max-width: 991px) {
      .school_landing_div-321 {
        max-width: 100%;
        margin-top: 8px;
        padding: 10px 20px;
      }
    }
    .school_landing_div-322 {
      color: #292626;
      align-self: start;
      margin-left: 10px;
      white-space: nowrap;
      font: 700 26px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-322 {
        white-space: initial;
      }
    }
    .school_landing_div-323 {
      color: #c6c4cb;
      letter-spacing: 0.9px;
      align-self: start;
      white-space: nowrap;
      border-radius: 5px;

      margin-top: 27px;
      width: 451px;
      max-width: 100%;

      height: 40px;
      font: 500 18px/56% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-323 {
        white-space: initial;
      
      }
    }
    .school_landing_div-324 {
      align-self: start;
      display: flex;
      margin-top: 15px;
      width: 451px;
      max-width: 100%;
      padding-right: 5px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
      height: 40px;
    }
    @media (max-width: 991px) {
      .school_landing_div-324 {
        flex-wrap: wrap;
      }
    }
    .school_landing_div-325 {
      color: #c6c4cb;
      letter-spacing: 0.9px;
      align-self: start;
      white-space: nowrap;
      border-radius: 5px;
      height: 100%;
      width: 150px;
      max-width: 100%;
      font: 500 18px/56% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-325 {
        white-space: initial;
        display:flex;
        flex-direction:row;
        width: 100%;
      }
    }
    .school_landing_div-326 {
      border-radius: 5px;
      border: 1.5px solid #ebe9ed;
      align-self: start;
      display: flex;
      width: 197px;
      max-width: 100%;
      flex-direction: column;
      padding: 10px;
    }
    @media (max-width: 991px) {
      .school_landing_div-326 {
        padding: 0 20px;
      }
    }
    .school_landing_img-110 {
      aspect-ratio: 0.56;
      object-fit: contain;
      object-position: center;
      width: 5px;
      transform: rotate(-90deg);
      stroke-width: 1px;
      stroke: rgba(255, 255, 255, 0.6);
      overflow: hidden;
      align-self: end;
      max-width: 100%;
    }
    .school_landing_div-327 {
        color: #c6c4cb;
        letter-spacing: 0.9px;
        align-self: start;
        white-space: nowrap;
        justify-content: center;
        height: 100%;
        width: 150px;
        max-width: 100%;
    
        font: 500 18px/56% Roboto, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .school_landing_div-327 {
          white-space: initial;
          padding: 0 20px
        }
      }
    .school_landing_div-328 {
      color: #fff;
      letter-spacing: 0.7px;
      align-self: start;
      white-space: nowrap;
      border-radius: 20px;
      background-color: #0175fe;
      margin-top: 32px;
      width: 228px;
      max-width: 100%;
      padding: 15px;
      text-align: center;
      font: 700 14px/71% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-328 {
        white-space: initial;
        padding: 15px 20px;
      }
    }
    .school_landing_div-329 {
      align-self: center;
      display: flex;
      margin-top: 28px;
      width: 100%;
      max-width: 1380px;
      align-items: flex-start;
      gap: 16px;
    }
    @media (max-width: 991px) {
      .school_landing_div-329 {
        max-width: 100%;
        flex-wrap: wrap;
      }
    }
    .school_landing_div-330 {
      color: #292626;
      align-self: stretch;
      flex-grow: 1;
      flex-basis: auto;
      font: 700 25px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-331 {
      border-radius: 10px;
      border: 1px solid #b7a4a4;
      background-color: #d9d9d9;
      align-self: center;
      display: flex;
      width: 955px;
      height: 7px;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: auto;
      margin: auto 0;
    }
    @media (max-width: 991px) {
      .school_landing_div-331 {
        max-width: 100%;
      }
    }
    .school_landing_div-332 {
      align-self: center;
      display: flex;
      margin-top: 13px;
      width: 100%;
      max-width: 1384px;
      align-items: flex-start;
      gap: 17px;
    }
    @media (max-width: 991px) {
      .school_landing_div-332 {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .school_landing_div-333 {
      background-color: #fff;
      display: flex;
      height: 100%;
      flex-direction: column;
      flex: 1;
      padding: 10px;
    }
    @media (max-width: 991px) {
      .school_landing_div-333 {
        padding-right: 20px;
      }
    }
    .school_landing_img-111 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: start;
    
    }
    @media (max-width: 991px) {
      .school_landing_img-111 {
        margin-left: 10px;
      }
    }
    .school_landing_div-334 {
      color: #000;
      align-self: start;
      margin-top: 16px;

      font: 400 14px/139% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-335 {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding:10px;
    }
    .school_landing_img-112 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-336 {
      color: #000;
      align-self: stretch;
      margin-top: 16px;
      font: 400 14px/139% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-337 {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 10px;
    }
    @media (max-width: 991px) {
      .school_landing_div-337 {
        padding-right: 20px;
      }
    }
    .school_landing_img-113 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-338 {
      color: #000;
      align-self: stretch;
      margin-top: 16px;
      font: 400 14px/139% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-339 {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 14px 5px 14px 10px;
    }
    .school_landing_img-114 {
      aspect-ratio: 1.03;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-340 {
      color: #000;
      align-self: stretch;
      font: 400 14px/139% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-341 {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 23px 17px 15px 10px;
    }
    .school_landing_img-115 {
      aspect-ratio: 1.07;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-342 {
      color: #000;
      align-self: stretch;
      margin-top: 10px;
      font: 400 14px/139% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-343 {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 15px 12px 15px 9px;
    }
    .school_landing_img-116 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-344 {
      color: #000;
      align-self: stretch;
      font: 400 14px/139% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-345 {
      align-self: center;
      display: flex;
      margin-top: 27px;
      width: 100%;
      max-width: 1386px;
      align-items: flex-start;
      gap: 18px;
    }
    @media (max-width: 991px) {
      .school_landing_div-345 {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .school_landing_div-346 {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 20px 20px 20px 10px;
    }
    .school_landing_img-117 {
      aspect-ratio: 1.08;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: start;
      margin-left: 16px;
    }
    @media (max-width: 991px) {
      .school_landing_img-117 {
        margin-left: 10px;
      }
    }
    .school_landing_div-347 {
      color: #000;
      align-self: start;
      margin-top: 28px;
      font: 400 14px/144% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-348 {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 6px 10px 20px;
    }
    .school_landing_img-118 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: start;
      margin-left: 11px;
    }
    @media (max-width: 991px) {
      .school_landing_img-118 {
        margin-left: 10px;
      }
    }
    .school_landing_div-349 {
      color: #000;
      align-self: start;
      margin-top: 18px;
      font: 400 14px/139% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-350 {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 12px 0 24px;
    }
    .school_landing_img-119 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 174px;
      overflow: hidden;
      align-self: center;
      max-width: 100%;
    }
    .school_landing_div-351 {
      color: #000;
      align-self: stretch;
      margin-top: 19px;
      font: 400 14px/139% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-352 {
      background-color: #fff;
      display: flex;
      margin-top: 6px;
      flex-direction: column;
      flex: 1;
      padding: 12px 13px 20px 10px;
    }
    .school_landing_img-120 {
      aspect-ratio: 1.11;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-353 {
      color: #000;
      align-self: stretch;
      margin-top: 19px;
      font: 400 14px/139% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-354 {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 22px 12px 22px 10px;
    }
    .school_landing_img-121 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 149px;
      overflow: hidden;
      align-self: end;
      margin-right: 10px;
      max-width: 100%;
    }
    .school_landing_div-355 {
      color: #000;
      align-self: end;
      margin-top: 32px;
      font: 400 14px/139% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-356 {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 6px 15px 20px 11px;
    }
    .school_landing_img-122 {
      aspect-ratio: 1.03;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-357 {
      color: #000;
      align-self: stretch;
      margin-top: 14px;
      font: 400 14px/139% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-358 {
      align-self: center;
      display: flex;
      margin-top: 15px;
      width: 100%;
      max-width: 1403px;
      align-items: flex-start;
      gap: 2px;
    }
    @media (max-width: 991px) {
      .school_landing_div-358 {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .school_landing_div-359 {
      color: #292626;
      font: 700 25px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-360 {
      border-radius: 10px;
      border: 1px solid #b7a4a4;
      background-color: #d9d9d9;
      align-self: center;
      display: flex;
      width: 996px;
      height: 7px;
      flex-direction: column;
      margin: auto 0;
    }
    @media (max-width: 991px) {
      .school_landing_div-360 {
        max-width: 100%;
      }
    }
    .school_landing_div-361 {
      color: #292626;
      align-self: start;
      margin-top: 5px;
      white-space: nowrap;
      font: 700 25px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-361 {
        white-space: initial;
      }
    }
    .school_landing_div-362 {
      align-self: center;
      margin-top: 12px;
      width: 100%;
      justify-content: space-evenly;
      max-width: 1394px;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-362 {
        max-width: 100%;
      }
    }
    .school_landing_div-363 {
      justify-content: space-evenly;
      display: flex;
    }
    @media (max-width: 991px) {
      .school_landing_div-363 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
      }
    }
    .school_landing_column-46 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 20%;
      margin-left: 0px;
    }
    @media (max-width: 991px) {
      .school_landing_column-46 {
        width: 100%;
      }
    }
    .school_landing_div-364 {
      background-color: #fff;
      display: flex;
      width: 100%;
      max-width: 332px;
      flex-direction: column;
      margin: 8px auto 0;
      
      padding-bottom: 10px;
    }
    @media (max-width: 991px) {
      .school_landing_div-364 {
        margin-top: 16px;
      }
    }
    .school_landing_img-123 {
      aspect-ratio: 2.01;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: start;
    }
    .school_landing_div-365 {
      align-self: start;
      display: flex;
      width: 283px;
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
      margin: 29px 0 0 0;
    }
    @media (max-width: 991px) {
      .school_landing_div-365 {
        margin-left: 10px;
      }
    }
    .school_landing_div-366 {
      color: #766d6d;
      font: 400 14px/125% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_img-124 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 33px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    .school_landing_column-47 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 20%;
    
    }
    @media (max-width: 991px) {
      .school_landing_column-47 {
        width: 100%;
        margin-left: 0;
      }
    }

    .school_landing_div-367 {
      background-color: #fff;
      display: flex;
      width: 100%;
      max-width: 332px;
      flex-grow: 1;
      flex-direction: column;
      margin: 8px auto 10px;
      
    }
    @media (max-width: 991px) {
      .school_landing_div-367 {
        margin-top: 24px;
      }
    }
    .school_landing_img-125 {
      aspect-ratio: 2.01;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-368 {
      align-self: center;
      display: flex;
      margin-top: 22px;
      width: 298px;
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    .school_landing_div-369 {
      color: #766d6d;
      font: 400 14px/125% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_img-126 {
      aspect-ratio: 1.12;
      object-fit: contain;
      object-position: center;
      width: 46px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    .school_landing_column-48 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 20%;
   
    }
    @media (max-width: 991px) {
      .school_landing_column-48 {
        width: 100%;
        margin-left: 0;
      }
    }
    .school_landing_div-370 {
      background-color: #fff;
      display: flex;
      width: 100%;
      max-width: 332px;
      flex-direction: column;
      margin: 8px auto 0;
      padding: 0 0 10px;
    }
    @media (max-width: 991px) {
      .school_landing_div-370 {
        margin-top: 23px;
      }
    }
    .school_landing_img-127 {
      aspect-ratio: 2.01;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-371 {
      align-self: center;
      display: flex;
      margin-top: 21px;
      width: 292px;
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    .school_landing_div-372 {
      color: #766d6d;
      font: 400 14px/125% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_img-128 {
      aspect-ratio: 1.25;
      object-fit: contain;
      object-position: center;
      width: 40px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    .school_landing_column-49 {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 20%;
   
    }
    @media (max-width: 991px) {
      .school_landing_column-49 {
        width: 100%;
        margin-left: 0;
      }
    }
    .school_landing_div-373 {
      background-color: #fff;
      display: flex;
      width: 100%;
      max-width: 332px;
      flex-grow: 1;
      flex-direction: column;
      margin: 11px auto 0;
      padding: 3px -1px 22px 1px;
    }
    @media (max-width: 991px) {
      .school_landing_div-373 {
        margin-top: 27px;
      }
    }
    .school_landing_img-129 {
      aspect-ratio: 2.01;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      align-self: stretch;
    }
    .school_landing_div-374 {
      align-self: center;
      display: flex;
      margin-top: 17px;
      width: 301px;
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    .school_landing_div-375 {
      color: #766d6d;
      font: 400 14px/125% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_img-130 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 40px;
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    .school_landing_div-376 {
      align-self: center;
      display: flex;
      width: 100%;
      max-width: 1391px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
      margin: 21px 0 -47px;
    }
    @media (max-width: 991px) {
      .school_landing_div-376 {
        max-width: 100%;
        margin-bottom: 10px;
        flex-wrap: wrap;
      }
    }
    .school_landing_div-377 {
      color: #292626;
      align-self: start;
      width: auto;
      font: 700 25px/102% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-378 {
      border-radius: 10px;
      border: 1px solid #b7a4a4;
      background-color: #d9d9d9;
      align-self: start;
      display: flex;
      margin-top: 4px;
      width: auto;
      height: 2px;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: auto;
    }
    @media (max-width: 991px) {
      .school_landing_div-378 {
        max-width: 100%;
      }
    }
    .school_landing_div-379 {
      align-self: center;
      display: flex;
      margin-top: 20px;
      width: 100%;
      max-width: 1314px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
      padding: 0 20px;
    }
    @media (max-width: 991px) {
      .school_landing_div-379 {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 40px;
      }
    }
    .school_landing_div-380 {
      align-self: start;
      display: flex;
      width: 100px;
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    .school_landing_img-131 {
      aspect-ratio: 1.31;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_div-381 {
      color: #000;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 400 12px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-381 {
        white-space: initial;
      }
    }
    .school_landing_div-382 {
      align-self: stretch;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 18px;
    }
    .school_landing_img-132 {
      aspect-ratio: 1.32;
      object-fit: contain;
      object-position: center;
      width: 49px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-383 {
      color: #000;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 400 12px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-383 {
        white-space: initial;
      }
    }
    .school_landing_div-384 {
      align-self: start;
      display: flex;
      width: 89px;
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    .school_landing_img-133 {
      aspect-ratio: 1.31;
      object-fit: contain;
      object-position: center;
      width: 46px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-385 {
      color: #000;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 400 12px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-385 {
        white-space: initial;
      }
    }
    .school_landing_div-386 {
      align-self: start;
      display: flex;
      width: 104px;
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    .school_landing_img-134 {
      aspect-ratio: 1.31;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_div-387 {
      color: #000;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 400 12px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-387 {
        white-space: initial;
      }
    }
    .school_landing_div-388 {
      align-self: start;
      display: flex;
      width: 114px;
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    .school_landing_img-135 {
      aspect-ratio: 1.31;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_div-389 {
      color: #000;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 400 12px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-389 {
        white-space: initial;
      }
    }
    .school_landing_div-390 {
      align-self: start;
      display: flex;
      width: 271px;
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_div-390 {
        justify-content: center;
      }
    }
    .school_landing_img-136 {
      aspect-ratio: 1.31;
      object-fit: contain;
      object-position: center;
      width: 46px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-391 {
      color: #000;
      align-self: center;
      margin: auto 0;
      font: 400 12px Inter, sans-serif;
    }
    .school_landing_img-137 {
      aspect-ratio: 1.31;
      object-fit: contain;
      object-position: center;
      width: 46px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-392 {
      color: #000;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 400 12px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-392 {
        white-space: initial;
      }
    }
    .school_landing_div-393 {
      align-self: start;
      display: flex;
      width: 102px;
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    .school_landing_img-138 {
      aspect-ratio: 1.31;
      object-fit: contain;
      object-position: center;
      width: 100%;
      overflow: hidden;
      flex: 1;
    }
    .school_landing_div-394 {
      color: #000;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 400 12px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-394 {
        white-space: initial;
      }
    }
    .school_landing_div-395 {
      color: #0b72ac;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 400 12px Inter, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-395 {
        white-space: initial;
      }
    }
    .school_landing_div-396 {
      background-color: #3f2501;
      align-self: center;
      display: flex;
      margin-top: 18px;
      width: 100%;
      max-width: 1490px;
      padding-left: 215px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_div-396 {
        max-width: 100%;
      
        flex-direction:row;
        padding-left: 10px;

      }
    }
    .school_landing_div-397 {
      align-self: center;
      display: flex;
      align-items: flex-start;
      gap: 14px;
      margin: auto 0;
    }
    @media (max-width: 991px) {
      .school_landing_div-397 {
        max-width: 100%;
        white-space: nowrap;
        align-items: center;
        flex-direction:row;
        padding-left: 75px;
        
      }
    }
    .school_landing_img-139 {
      aspect-ratio: 2.75;
      object-fit: contain;
      object-position: center;
      width: 99px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-398 {
      color: #fff;
      align-self: center;
      white-space: nowrap;
      margin: auto 0;
      font: 700 26px Roboto, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-398 {
        white-space: initial;
      }
    }
    .school_landing_div-399 {
      display: flex;
      flex-direction: column;
      fill: #e1a53c;
      overflow: hidden;
      align-self: stretch;
      position: relative;
      display: flex;
      aspect-ratio: 5.95;
      width: 357px;
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 12px;
      padding: 15px 183px 15px 22px;
    }
    @media (max-width: 991px) {
      .school_landing_div-399 {
        padding: 0;
        display:none;
        width:100px;
      }
    }
    .school_landing_div-400 {
      position: relative;
      border-radius: 50%;
      align-self: center;
      display: flex;
      width: 10px;
      height: 10px;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: auto;
      margin: auto 0;
    }
    .school_landing_div-401 {
      position: relative;
      color: #3f2501;
      align-self: stretch;
      white-space: nowrap;
      border-radius: 20px;
      background-color: #fff;
      width: 130px;
      max-width: 100%;
      padding: 9px 26px 9px 28px;
      font: 600 16px Roboto, sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-401 {
        white-space: nowrap;
        padding: 5px;
        align-self: center;
        text-align:center;
      }
    }
    .school_landing_div-402 {
      border: 1px solid #fff;
      background-color: #445268;
      align-self: start;
      display: flex;
      margin-top: 31px;
      width: 100%;
      flex-direction: column;
      padding: 50px 29px 34px 80px;
    }
    @media (max-width: 991px) {
      .school_landing_div-402 {
        max-width: 100%;
        padding: 0 20px;
      }
    }
    .school_landing_div-403 {
      color: #fff;
      letter-spacing: 0.8px;
      align-self: center;
      margin-top: 6px;
      white-space: nowrap;
      font: 400 16px/62.5% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-403 {
        max-width: 100%;
        white-space: initial;
      }
    }
    .school_landing_div-404 {
      align-self: center;
      display: flex;
      margin-top: 16px;
      width: 436px;
      max-width: 100%;
      align-items: flex-start;
      gap: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_div-404 {
        flex-wrap: wrap;
      }
    }
    .school_landing_div-405 {
      align-self: start;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: auto;
      width: 300px;
    }
    .school_landing_div-406 {
      color: #adb1b9;
      letter-spacing: 0.9px;
      align-self: stretch;
      white-space: nowrap;
      border: 1px solid rgba(255, 255, 255, 0.6);
      background-color: #313d51;
      width: 100%;
      padding: 14px 80px 14px 10px;
      font: 400 18px/56% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-406 {
        white-space: initial;
        padding-right: 20px;
      }
    }
    .school_landing_div-407 {
      color: #adb1b9;
      letter-spacing: 0.575px;
      align-self: stretch;
      margin-top: 9px;
      white-space: nowrap;
      font: 400 11.5px/87% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-407 {
        white-space: initial;
      }
    }
    .school_landing_div-408 {
      color: #fff;
      letter-spacing: 0.7px;
      align-self: start;
      white-space: nowrap;
      border: 1px solid rgba(255, 255, 255, 0.6);
      background-color: #313d51;
      width: 116px;
      max-width: 100%;
      padding: 15px 26px 15px 24px;
      font: 400 14px/71% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-408 {
        white-space: initial;
        padding: 0 20px;
      }
    }
    .school_landing_div-409 {
      align-self: center;
      display: flex;
      margin-top: 32px;
      width: 100%;
      max-width: 1351px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_div-409 {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .school_landing_div-410 {
      color: #fff;
      letter-spacing: 0.9px;
      font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-410 {
        text-align:center;
        justify-content: center;
      }
    }
    .school_landing_div-411 {
      color: #fff;
      letter-spacing: 0.9px;
      font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-411 {
        text-align:center;
        justify-content: center;
      }
    }
    .school_landing_div-412 {
      color: #fff;
      letter-spacing: 0.9px;
      align-self: start;
      flex-grow: 1;
      flex-basis: auto;
      font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-412 {
        text-align:center;
        justify-content: center;
      }
    }
    .school_landing_div-413 {
      color: #fff;
      letter-spacing: 0.9px;
      font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-413 {
        text-align:center;
        justify-content: center;
      }
    }
    .school_landing_div-414 {
      color: #fff;
      letter-spacing: 0.9px;
      align-self: start;
      flex-grow: 1;
      flex-basis: auto;
      font: 400 18px/30px Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-414 {
        text-align:center;
        justify-content: center;
      }
    }
    .school_landing_div-415 {
      background-color: #364358;
      align-self: center;
      margin-top: 37px;
      width: 1220px;
      height: 1px;
    }
    @media (max-width: 991px) {
      .school_landing_div-415 {
        max-width: 100%;
      }
    }
    .school_landing_div-416 {
      align-self: center;
      display: flex;
      margin-top: 18px;
      width: 100%;
      max-width: 1166px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_div-416 {
        max-width: 100%;
        flex-wrap: wrap;
      }
    }
    .school_landing_div-417 {
      align-self: start;
      display: flex;
      margin-top: 4px;
      align-items: flex-start;
      gap: 15px;
    }
    @media (max-width: 991px) {
      .school_landing_div-417 {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .school_landing_div-418 {
      color: #fff;
      letter-spacing: 0.9px;
      align-self: center;
      margin: auto 0;
      font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-419 {
      justify-content: space-between;
      align-items: flex-start;
      border-radius: 5px;
      background-color: #999;
      align-self: stretch;
      display: flex;
      width: 190px;
      max-width: 100%;
      gap: 10px;
      padding: 5px 15px 5px 5px;
    }
    .school_landing_img-140 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 35px;
      fill: rgba(0, 0, 0, 0);
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-420 {
      color: #fff;
      letter-spacing: 0.8px;
      font: 700 16px/18px Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-421 {
      justify-content: space-between;
      align-items: flex-start;
      border-radius: 5px;
      background-color: #a4c639;
      align-self: stretch;
      display: flex;
      width: 185px;
      max-width: 100%;
      gap: 5px;
      padding: 5px 15px 5px 5px;
    }
    .school_landing_img-141 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 35px;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-422 {
      color: #fff;
      letter-spacing: 0.8px;
      font: 700 16px/18px Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_div-423 {
      align-self: start;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 4px;
    }
    @media (max-width: 991px) {
      .school_landing_div-423 {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .school_landing_div-424 {
      color: #fff;
      letter-spacing: 0.9px;
      margin: auto 0;
      font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_img-142 {
      aspect-ratio: 0.92;
      object-fit: contain;
      object-position: center;
      width: 55px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-425 {
      color: #fff;
      letter-spacing: 0.9px;
      align-self: center;
      margin: auto 0;
      font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .school_landing_img-143 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 25px;
      fill: rgba(0, 0, 0, 0);
      overflow: hidden;
      align-self: center;
      max-width: 100%;
      margin: auto 0;
    }
    .school_landing_img-144 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 25px;
      fill: rgba(0, 0, 0, 0);
      overflow: hidden;
      align-self: center;
      max-width: 100%;
      margin: auto 0;
    }
    .school_landing_img-145 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 25px;
      fill: rgba(0, 0, 0, 0);
      overflow: hidden;
      align-self: center;
      max-width: 100%;
      margin: auto 0;
    }
    .school_landing_img-146 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 25px;
      fill: rgba(0, 0, 0, 0);
      overflow: hidden;
      align-self: center;
      max-width: 100%;
      margin: auto 0;
    }
    .school_landing_img-147 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 25px;
      fill: rgba(0, 0, 0, 0);
      overflow: hidden;
      align-self: center;
      max-width: 100%;
      margin: auto 0;
    }
    .school_landing_div-426 {
      align-self: center;
      display: flex;
      margin-top: 46px;
      width: 100%;
      max-width: 1057px;
      align-items: flex-start;
      gap: 18px;
    }
    @media (max-width: 991px) {
      .school_landing_div-426 {
        max-width: 100%;
        flex-wrap: wrap;
        margin-top: 40px;
      }
    }
    .school_landing_img-148 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 25px;
      fill: rgba(222, 196, 196, 0);
      overflow: hidden;
      align-self: start;
      max-width: 100%;
    }
    .school_landing_div-427 {
      color: #fff;
      letter-spacing: 0.9px;
      align-self: start;
      white-space: nowrap;
      flex-grow: 1;
      flex-basis: auto;
      font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-427 {
        max-width: 100%;
        white-space: initial;
      }
    }
    .school_landing_div-428 {
      color: #fff;
      letter-spacing: 0.9px;
      align-self: end;
      white-space: nowrap;
      margin: 4px 30px 0 0;
      font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-428 {
        max-width: 100%;
        margin-right: 10px;
        white-space: initial;
      }
    }
    .school_landing_div-429 {
      color: #fff;
      letter-spacing: 0.9px;
      align-self: center;
      margin-left: 52px;
      white-space: nowrap;
      font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-429 {
        max-width: 100%;
        white-space: initial;
      }
    }
    .school_landing_div-430,
    .school_landing_div-431 {
      color: #fff;
      letter-spacing: 0.9px;
      align-self: center;
      font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica, sans-serif;
      white-space: normal; /* Allow text to wrap */
    }
    
    @media (max-width: 991px) {
      .school_landing_div-430,
      .school_landing_div-431 {
        max-width: 100%;
        text-align: center;
        margin-top: 9px; /* Adjust margin-top for school_landing_div-430 */
      }
    
      .school_landing_div-431 {
        margin-top: 16px; /* Adjust margin-top for school_landing_div-431 */
      }
    }
    
    .school_landing_div-432 {
      align-self: center;
      display: flex;
      margin-top: 29px;
      width: 805px;
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
    @media (max-width: 991px) {
      .school_landing_div-432 {
        flex-wrap: wrap;
      }
    }
    .school_landing_div-433 {
      align-self: stretch;
      display: flex;
      flex-grow: 1;
      flex-basis: auto;
      align-items: flex-start;
      justify-content: space-between;
      gap: 9px;
    }
    @media (max-width: 991px) {
      .school_landing_div-433 {
        max-width: 100%;
        flex-wrap: wrap;
      }
    }
    .school_landing_img-149 {
      aspect-ratio: 0.93;
      object-fit: contain;
      object-position: center;
      width: 40px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-434 {
      color: #fff;
      letter-spacing: 0.9px;
      align-self: center;
      white-space: nowrap;
      flex-grow: 1;
      flex-basis: auto;
      margin: auto 0;
      font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-434 {
        white-space: initial;
      }
    }
    .school_landing_div-435 {
      align-self: center;
      display: flex;
      flex-grow: 1;
      flex-basis: auto;
      align-items: flex-start;
      gap: 12px;
      margin: auto 0;
    }
    .school_landing_img-150 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 38px;
      overflow: hidden;
      align-self: stretch;
      max-width: 100%;
    }
    .school_landing_div-436 {
      color: #fff;
      letter-spacing: 0.9px;
      align-self: center;
      white-space: nowrap;
      flex-grow: 1;
      flex-basis: auto;
      margin: auto 0;
      font: 400 18px/167% Roboto, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .school_landing_div-436 {
        white-space: initial;
      }
    }
  